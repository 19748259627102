import { Col, Row, Space, Typography } from 'antd';
import themeToken from 'infrastructure/theme/tokens';
import { useEffect, useState } from 'react';
import { axiosApiInstance } from 'infrastructure/utils/api';
import { toFixedFunc } from 'infrastructure/utils/toFixedFunc';
import { StyledCard } from 'infrastructure/theme/components/Card';

const { Title, Text } = Typography;
const TradeUpdates = ({ symbol }: { symbol: string }) => {
  const [trades, setTrades] = useState({
    lastTrade: {
      price: 0,
      changePricePercent: 0,
      count: 0,
    },
    metrics: {
      bestBid: 0,
      bestOffer: 0,
      week52ChangePercent: 0,
      week52High: 0,
      week52Low: 0,
      bestBidVolume: 0,
      bestOfferVolume: 0,
    },
  });

  useEffect(() => {
    axiosApiInstance.get(`companies/${symbol}/trades`).then(
      (res: {
        data: {
          lastTrade: { price: number; changePricePercent: number; count: number };
          metrics: {
            bestBid: number;
            bestOffer: number;
            week52ChangePercent: number;
            week52High: number;
            week52Low: number;
            bestBidVolume: number;
            bestOfferVolume: number;
          };
        };
      }) => {
        setTrades(res.data);
      },
    );
  }, []);

  return (
    <StyledCard>
      <Title
        style={{
          fontSize: '18px',
          color: themeToken.black,
          fontWeight: '700',
          marginBottom: '16px',
        }}
      >
        Trade Updates
      </Title>

      <Title
        style={{
          fontSize: '13px',
          color: themeToken.secondary_dark_blue_400,
          fontWeight: '700',
        }}
      >
        Last Trade
      </Title>
      <Row justify="space-between" gutter={[16, 16]} style={{ width: '100%' }}>
        <Col span={8}>
          <Space direction="vertical">
            <Text
              style={{
                fontSize: '12px',
                color: themeToken.gray,
                fontWeight: '500',
              }}
            >
              Price
            </Text>

            <Text
              style={{
                fontSize: '13px',
                color: themeToken.secondary_dark_blue_900,
                fontWeight: '700',
              }}
            >
              {toFixedFunc(trades.lastTrade.price, 2)}
            </Text>
          </Space>
        </Col>

        <Col span={8}>
          <Space direction="vertical">
            <Text
              style={{
                fontSize: '12px',
                color: themeToken.gray,
                fontWeight: '500',
              }}
            >
              % Change
            </Text>

            <Text
              style={{
                fontSize: '13px',
                color: themeToken.secondary_dark_blue_900,
                fontWeight: '700',
              }}
            >
              {toFixedFunc(trades.lastTrade.changePricePercent, 2)}
            </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space direction="vertical">
            <Text
              style={{
                fontSize: '12px',
                color: themeToken.gray,
                fontWeight: '500',
              }}
            >
              Volume Traded
            </Text>

            <Text
              style={{
                fontSize: '13px',
                color: themeToken.secondary_dark_blue_900,
                fontWeight: '700',
              }}
            >
              {toFixedFunc(trades.lastTrade.count, 2)}
            </Text>
          </Space>
        </Col>
      </Row>

      <hr style={{ border: '1px solid #EDF2F7', marginTop: '16px' }} />

      <Row gutter={16} justify="space-between">
        <Col span={12}>
          <Title
            style={{
              fontSize: '13px',
              color: themeToken.secondary_dark_blue_400,
              fontWeight: '700',
            }}
          >
            Best Bid
          </Title>

          <Row justify="start">
            <Space direction="vertical">
              <Text
                style={{
                  fontSize: '12px',
                  color: themeToken.gray,
                  fontWeight: '500',
                }}
              >
                Price
              </Text>

              <Text
                style={{
                  fontSize: '13px',
                  color: themeToken.secondary_dark_blue_900,
                  fontWeight: '700',
                }}
              >
                {toFixedFunc(trades.metrics.bestBid, 2)}
              </Text>
            </Space>

            <Space direction="vertical" style={{ marginLeft: '36px' }}>
              <Text
                style={{
                  fontSize: '12px',
                  color: themeToken.gray,
                  fontWeight: '500',
                }}
              >
                Volume
              </Text>

              <Text
                style={{
                  fontSize: '13px',
                  color: themeToken.secondary_dark_blue_900,
                  fontWeight: '700',
                }}
              >
                {toFixedFunc(trades.metrics.bestBidVolume, 2)}
              </Text>
            </Space>
          </Row>
        </Col>

        <Col span={12}>
          <Title
            style={{
              fontSize: '13px',
              color: themeToken.secondary_dark_blue_400,
              fontWeight: '700',
            }}
          >
            Best Offer
          </Title>

          <Row justify="start">
            <Space direction="vertical">
              <Text
                style={{
                  fontSize: '12px',
                  color: themeToken.gray,
                  fontWeight: '500',
                }}
              >
                Price
              </Text>

              <Text
                style={{
                  fontSize: '13px',
                  color: themeToken.secondary_dark_blue_900,
                  fontWeight: '700',
                }}
              >
                {toFixedFunc(trades.metrics.bestOffer, 2)}
              </Text>
            </Space>

            <Space direction="vertical" style={{ marginLeft: '36px' }}>
              <Text
                style={{
                  fontSize: '12px',
                  color: themeToken.gray,
                  fontWeight: '500',
                }}
              >
                Volume
              </Text>

              <Text
                style={{
                  fontSize: '13px',
                  color: themeToken.secondary_dark_blue_900,
                  fontWeight: '700',
                }}
              >
                {toFixedFunc(trades.metrics.bestOfferVolume, 2)}
              </Text>
            </Space>
          </Row>
        </Col>
      </Row>

      <hr style={{ border: '1px solid #EDF2F7', marginTop: '16px' }} />

      <Title
        style={{
          fontSize: '13px',
          color: themeToken.secondary_dark_blue_400,
          fontWeight: '700',
        }}
      >
        52 WEEK
      </Title>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={8}>
          <Space direction="vertical">
            <Text
              style={{
                fontSize: '12px',
                color: themeToken.gray,
                fontWeight: '500',
              }}
            >
              High
            </Text>

            <Text
              style={{
                fontSize: '13px',
                color: themeToken.success_400,
                fontWeight: '700',
              }}
            >
              {toFixedFunc(trades.metrics.week52High, 2)}
            </Text>
          </Space>
        </Col>

        <Col span={8}>
          <Space direction="vertical">
            <Text
              style={{
                fontSize: '12px',
                color: themeToken.gray,
                fontWeight: '500',
              }}
            >
              Low
            </Text>

            <Text
              style={{
                fontSize: '13px',
                color: themeToken.error_400,
                fontWeight: '700',
              }}
            >
              {toFixedFunc(trades.metrics.week52Low, 2)}
            </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space direction="vertical">
            <Text
              style={{
                fontSize: '12px',
                color: themeToken.gray,
                fontWeight: '500',
              }}
            >
              Change%
            </Text>

            <Text
              style={{
                fontSize: '13px',
                color: themeToken.secondary_dark_blue_900,
                fontWeight: '700',
              }}
            >
              {toFixedFunc(trades.metrics.week52ChangePercent, 2)}%
            </Text>
          </Space>
        </Col>
      </Row>
    </StyledCard>
  );
};

export default TradeUpdates;

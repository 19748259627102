import { Checkbox, Form as AntDForm, Typography } from 'antd';
import { showNotification, NotificationStatus } from 'infrastructure/helpers/showNotifications';
import { axiosApiInstance } from 'infrastructure/utils/api';
import themeToken from 'infrastructure/theme/tokens';
import { useNavigate } from 'react-router-dom';
import { StyledButton } from 'infrastructure/theme/components/Button';
import { StyledInput, StyledInputPassword } from 'infrastructure/theme/components/Input';
import { useMutation } from '@tanstack/react-query';
import storage from 'infrastructure/utils/storage';

const { Text } = Typography;

const Form = () => {
  const navigate = useNavigate();
  const mutation = useMutation({
    mutationFn: (formData: { email: string; password: string }) => {
      return axiosApiInstance.post('/auth/sign-in', formData);
    },
    onSuccess: (data: { data: { accessToken: string } }) => {
      storage.setToken(data.data.accessToken);
      showNotification(NotificationStatus.Success, 'Done', 'you loged in Successfully');
      navigate('/dashboard');
    },
  });

  const onFinish = (values: { email: string; password: string }) => {
    const data = {
      email: values.email,
      password: values.password,
    };
    mutation.mutate(data);
  };

  return (
    <AntDForm
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="off"
      style={{ marginTop: '24px ', width: '100%' }}
    >
      <AntDForm.Item
        style={{ marginBottom: '0px' }}
        name="email"
        rules={[
          {
            required: true,
            type: 'email',
          },
        ]}
      >
        <StyledInput placeholder="Email" />
      </AntDForm.Item>

      <AntDForm.Item
        style={{ marginTop: '10px' }}
        name="password"
        rules={[
          { required: true },
          {
            type: 'string',
            min: 10,
            message: 'password must be longer than or equal to 10 characters',
          },
        ]}
      >
        <StyledInputPassword placeholder="Password" />
      </AntDForm.Item>

      <AntDForm.Item>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <AntDForm.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </AntDForm.Item>

          <a className="login-form-forgot" href="">
            <Text> Forget Password </Text>
          </a>
        </div>
      </AntDForm.Item>

      <AntDForm.Item>
        <StyledButton type="primary" size="large" htmlType="submit" className="login-form-button">
          Login
        </StyledButton>
      </AntDForm.Item>

      <AntDForm.Item>
        <div
          style={{
            width: '100%',
            marginTop: '156px',
            textAlign: 'center',
          }}
        >
          <Text
            style={{
              fontSize: '15px',
              fontWeight: '500',
            }}
          >
            Have no Account?{' '}
          </Text>
          <a
            href="/register"
            style={{
              fontSize: '15px',
              fontWeight: '700',
              color: themeToken.secondary_500,
            }}
          >
            Register now!
          </a>
        </div>
      </AntDForm.Item>
    </AntDForm>
  );
};

export default Form;

import { fetchGetRequests } from 'infrastructure/helpers/fetchServerRequests';
import { create } from 'zustand';

type IProps = {
  evaluations: {
    docs: Array<{ id: string; timestamp: string; stockPrice: number }>;
  };
  evaluationDate: string[];
  getEvaluations: (symbol?: string) => Promise<void>;
  setEvaluationDate: (dateRange: string[]) => Promise<void>;
};
export const useEvaluationsStore = create<IProps>((set) => ({
  evaluations: {
    docs: [
      {
        id: '',
        timestamp: '',
        stockPrice: 0,
      },
    ],
  },
  evaluationDate: [''],
  getEvaluations: async (symbol?: string) => {
    const response = await fetchGetRequests(`companies/${symbol}/evaluation`);
    set({
      evaluations: response.response,
    });
  },
  setEvaluationDate: async (dateRange: string[]) => {
    set({
      evaluationDate: dateRange,
    });
  },
}));

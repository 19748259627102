import { useStatementOfIncomeStore } from 'infrastructure/store/statementOfIncome';
import { StyledSelect } from 'infrastructure/theme/components/Select';
import { useEffect, useState } from 'react';
import { styled } from 'styled-components';

const StyledTable = styled.table`
  width: 100%;
  text-align: left;
  display: block;
`;

const StyledTh = styled.th`
  font-size: 13px;
  font-weight: 500;
  color: #858a9b;
`;

const StyledFixedTd = styled.td`
  font-size: 13px;
  font-weight: 500;
  color: #31255c;
  border-bottom: 1px solid #f2f4f7;
  padding: 10px 0px 10px 0px;
`;

const StyledTd = styled.td`
  font-size: 13px;
  font-weight: 700;
  color: #080022;
  border-bottom: 1px solid #f2f4f7;
  padding: 10px 0px 10px 0px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const StatementOfIncome = ({ symbol }: { symbol: string }) => {
  const [statementOfIncome, getStatementOfIncome] = useStatementOfIncomeStore((state) => [
    state.statementOfIncome,
    state.getStatementOfIncome,
  ]);

  const [value, setValue] = useState('annual');

  useEffect(() => {
    getStatementOfIncome(symbol);
  }, []);

  const handleOnChange = (key: string) => {
    setValue(key);
  };

  return (
    <Wrapper>
      <StyledSelect
        defaultValue="Annually"
        options={[
          { value: 'annual', label: 'Anuual' },
          { value: 'quarter', label: 'Quarter' },
        ]}
        onChange={handleOnChange}
        style={{ marginBottom: '30px' }}
        bordered={false}
      />

      {value === 'annual' ? (
        <StyledTable style={{ display: 'table' }}>
          <tr>
            <th>Balance Sheet</th>
            {statementOfIncome?.annualReports?.map((item: { fiscalDateEnding: string; _id: string }) => {
              return <StyledTh key={item._id}>{item.fiscalDateEnding.split('T00:00:00.000Z')}</StyledTh>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>grossProfit</StyledFixedTd>
            {statementOfIncome?.annualReports?.map((item: { grossProfit: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.grossProfit}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>totalRevenue</StyledFixedTd>
            {statementOfIncome?.annualReports?.map((item: { totalRevenue: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.totalRevenue}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>costOfRevenue</StyledFixedTd>
            {statementOfIncome?.annualReports?.map((item: { costOfRevenue: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.costOfRevenue}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>costofGoodsAndServicesSold</StyledFixedTd>
            {statementOfIncome?.annualReports?.map((item: { costofGoodsAndServicesSold: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.costofGoodsAndServicesSold}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>operatingIncome</StyledFixedTd>
            {statementOfIncome?.annualReports?.map((item: { operatingIncome: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.operatingIncome}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>sellingGeneralAndAdministrative</StyledFixedTd>
            {statementOfIncome?.annualReports?.map((item: { sellingGeneralAndAdministrative: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.sellingGeneralAndAdministrative}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>researchAndDevelopment</StyledFixedTd>
            {statementOfIncome?.annualReports?.map((item: { researchAndDevelopment: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.researchAndDevelopment}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>operatingExpenses</StyledFixedTd>
            {statementOfIncome?.annualReports?.map((item: { operatingExpenses: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.operatingExpenses}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>investmentIncomeNet</StyledFixedTd>
            {statementOfIncome?.annualReports?.map((item: { investmentIncomeNet: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.investmentIncomeNet}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>netInterestIncome</StyledFixedTd>
            {statementOfIncome?.annualReports?.map((item: { netInterestIncome: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.netInterestIncome}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>interestIncome</StyledFixedTd>
            {statementOfIncome?.annualReports?.map((item: { interestIncome: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.interestIncome}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>interestExpense</StyledFixedTd>
            {statementOfIncome?.annualReports?.map((item: { interestExpense: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.interestExpense}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>nonInterestIncome</StyledFixedTd>
            {statementOfIncome?.annualReports?.map((item: { nonInterestIncome: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.nonInterestIncome}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>otherNonOperatingIncome</StyledFixedTd>
            {statementOfIncome?.annualReports?.map((item: { otherNonOperatingIncome: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.otherNonOperatingIncome}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>depreciation</StyledFixedTd>
            {statementOfIncome?.annualReports?.map((item: { depreciation: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.depreciation}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>depreciationAndAmortization</StyledFixedTd>
            {statementOfIncome?.annualReports?.map((item: { depreciationAndAmortization: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.depreciationAndAmortization}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>incomeBeforeTax</StyledFixedTd>
            {statementOfIncome?.annualReports?.map((item: { incomeBeforeTax: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.incomeBeforeTax}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>incomeTaxExpense</StyledFixedTd>
            {statementOfIncome?.annualReports?.map((item: { incomeTaxExpense: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.incomeTaxExpense}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>interestAndDebtExpense</StyledFixedTd>
            {statementOfIncome?.annualReports?.map((item: { interestAndDebtExpense: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.interestAndDebtExpense}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>netIncomeFromContinuingOperations</StyledFixedTd>
            {statementOfIncome?.annualReports?.map(
              (item: { netIncomeFromContinuingOperations: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.netIncomeFromContinuingOperations}</StyledTd>;
              },
            )}
          </tr>

          <tr>
            <StyledFixedTd>comprehensiveIncomeNetOfTax</StyledFixedTd>
            {statementOfIncome?.annualReports?.map((item: { comprehensiveIncomeNetOfTax: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.comprehensiveIncomeNetOfTax}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>ebit</StyledFixedTd>
            {statementOfIncome?.annualReports?.map((item: { ebit: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.ebit}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>ebitda</StyledFixedTd>
            {statementOfIncome?.annualReports?.map((item: { ebitda: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.ebitda}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>netIncome</StyledFixedTd>
            {statementOfIncome?.annualReports?.map((item: { netIncome: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.netIncome}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>reportedCurrency</StyledFixedTd>
            {statementOfIncome?.annualReports?.map((item: { reportedCurrency: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.reportedCurrency}</StyledTd>;
            })}
          </tr>
        </StyledTable>
      ) : (
        <StyledTable style={{ display: 'table' }}>
          <tr>
            <th>Balance Sheet</th>
            {statementOfIncome?.quarterlyReports?.slice(0, 5).map((item: { fiscalDateEnding: string; _id: string }) => {
              return <StyledTh key={item._id}>{item.fiscalDateEnding.split('T00:00:00.000Z')}</StyledTh>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>grossProfit</StyledFixedTd>
            {statementOfIncome?.quarterlyReports?.slice(0, 5).map((item: { grossProfit: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.grossProfit}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>totalRevenue</StyledFixedTd>
            {statementOfIncome?.quarterlyReports?.slice(0, 5).map((item: { totalRevenue: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.totalRevenue}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>costOfRevenue</StyledFixedTd>
            {statementOfIncome?.quarterlyReports?.slice(0, 5).map((item: { costOfRevenue: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.costOfRevenue}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>costofGoodsAndServicesSold</StyledFixedTd>
            {statementOfIncome?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { costofGoodsAndServicesSold: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.costofGoodsAndServicesSold}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>operatingIncome</StyledFixedTd>
            {statementOfIncome?.quarterlyReports?.slice(0, 5).map((item: { operatingIncome: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.operatingIncome}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>sellingGeneralAndAdministrative</StyledFixedTd>
            {statementOfIncome?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { sellingGeneralAndAdministrative: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.sellingGeneralAndAdministrative}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>researchAndDevelopment</StyledFixedTd>
            {statementOfIncome?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { researchAndDevelopment: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.researchAndDevelopment}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>operatingExpenses</StyledFixedTd>
            {statementOfIncome?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { operatingExpenses: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.operatingExpenses}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>investmentIncomeNet</StyledFixedTd>
            {statementOfIncome?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { investmentIncomeNet: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.investmentIncomeNet}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>netInterestIncome</StyledFixedTd>
            {statementOfIncome?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { netInterestIncome: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.netInterestIncome}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>interestIncome</StyledFixedTd>
            {statementOfIncome?.quarterlyReports?.slice(0, 5).map((item: { interestIncome: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.interestIncome}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>interestExpense</StyledFixedTd>
            {statementOfIncome?.quarterlyReports?.slice(0, 5).map((item: { interestExpense: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.interestExpense}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>nonInterestIncome</StyledFixedTd>
            {statementOfIncome?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { nonInterestIncome: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.nonInterestIncome}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>otherNonOperatingIncome</StyledFixedTd>
            {statementOfIncome?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { otherNonOperatingIncome: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.otherNonOperatingIncome}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>depreciation</StyledFixedTd>
            {statementOfIncome?.quarterlyReports?.slice(0, 5).map((item: { depreciation: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.depreciation}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>depreciationAndAmortization</StyledFixedTd>
            {statementOfIncome?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { depreciationAndAmortization: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.depreciationAndAmortization}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>incomeBeforeTax</StyledFixedTd>
            {statementOfIncome?.quarterlyReports?.slice(0, 5).map((item: { incomeBeforeTax: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.incomeBeforeTax}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>incomeTaxExpense</StyledFixedTd>
            {statementOfIncome?.quarterlyReports?.slice(0, 5).map((item: { incomeTaxExpense: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.incomeTaxExpense}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>interestAndDebtExpense</StyledFixedTd>
            {statementOfIncome?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { interestAndDebtExpense: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.interestAndDebtExpense}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>netIncomeFromContinuingOperations</StyledFixedTd>
            {statementOfIncome?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { netIncomeFromContinuingOperations: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.netIncomeFromContinuingOperations}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>comprehensiveIncomeNetOfTax</StyledFixedTd>
            {statementOfIncome?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { comprehensiveIncomeNetOfTax: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.comprehensiveIncomeNetOfTax}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>ebit</StyledFixedTd>
            {statementOfIncome?.quarterlyReports?.slice(0, 5).map((item: { ebit: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.ebit}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>ebitda</StyledFixedTd>
            {statementOfIncome?.quarterlyReports?.slice(0, 5).map((item: { ebitda: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.ebitda}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>netIncome</StyledFixedTd>
            {statementOfIncome?.quarterlyReports?.slice(0, 5).map((item: { netIncome: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.netIncome}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>reportedCurrency</StyledFixedTd>
            {statementOfIncome?.quarterlyReports?.slice(0, 5).map((item: { reportedCurrency: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.reportedCurrency}</StyledTd>;
            })}
          </tr>
        </StyledTable>
      )}
    </Wrapper>
  );
};

export default StatementOfIncome;

import { StyledText } from './style';
import { TimeRangePickerProps } from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { StyledRangePicker } from 'infrastructure/theme/components/RangePicker';

dayjs.extend(quarterOfYear);
dayjs.extend(weekday);
dayjs.extend(localeData);

const RangePicker = ({ onChange }: { onChange?: (dates: null | (Dayjs | null)[], dateStrings: string[]) => void }) => {
  const rangePresets: TimeRangePickerProps['presets'] = [
    {
      label: (
        <StyledText weight={700} style={{ margin: '25px 0px 25px 0px' }}>
          Filter by
        </StyledText>
      ),
      value: () => [dayjs(), dayjs()],
    },
    {
      label: <StyledText>This Week</StyledText>,
      value: [dayjs().startOf('week'), dayjs().endOf('week')],
    },
    {
      label: <StyledText>This Month</StyledText>,
      value: [dayjs().startOf('month'), dayjs().endOf('month')],
    },
    {
      label: <StyledText> This Quarter</StyledText>,
      value: [dayjs().startOf('quarter'), dayjs().endOf('quarter')],
    },
    {
      label: <StyledText> This Year</StyledText>,
      value: [dayjs().startOf('year'), dayjs().endOf('year')],
    },

    {
      label: <StyledText> Last Week </StyledText>,
      value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
      label: <StyledText>Last 30 Day </StyledText>,
      value: [dayjs().add(-30, 'd'), dayjs()],
    },
    {
      label: <StyledText> Last 2 Months </StyledText>,
      value: [dayjs().add(-2, 'month'), dayjs()],
    },
    {
      label: <StyledText> Last 3 Months</StyledText>,
      value: [dayjs().add(-3, 'month'), dayjs()],
    },
    {
      label: <StyledText> Last Quarter </StyledText>,
      value: [dayjs().add(-4, 'month'), dayjs()],
    },
    {
      label: <StyledText> Last 12 Months </StyledText>,
      value: [dayjs().add(-12, 'month'), dayjs()],
    },
  ];

  return <StyledRangePicker onChange={onChange} presets={rangePresets} />;
};

export default RangePicker;

import { Col, Row, Image, Typography } from 'antd';
import leftSideLogo from 'assets/svg/login photo.svg';
import Form from './form';
import Logo from 'assets/svg/logoDark';
import themeToken from 'infrastructure/theme/tokens';
import { Navigate } from 'react-router-dom';
import storage from 'infrastructure/utils/storage';

const { Title, Text } = Typography;

const Login = () => {
  const isAuthed = !!storage.getToken();

  if (isAuthed) {
    return <Navigate to="/dashboard" replace />;
  }
  return (
    <div className="login">
      <Row gutter={24}>
        <Col span={12}>
          <Image src={leftSideLogo} preview={false} style={{ height: '100vh', objectFit: 'cover' }} />
        </Col>
        <Col push={1} span={6}>
          <div style={{ marginTop: '170px' }}>
            <Logo />
            <Title
              style={{
                fontSize: '36px',
                fontWeight: '700',
                color: themeToken.black,
                margin: '37px 0px 0px',
              }}
            >
              Welcome Back!
            </Title>
            <Text
              style={{
                fontSize: '24px',
                fontWeight: '400',
                color: themeToken.gray,
                margin: '0px',
              }}
            >
              Enter the below data to login
            </Text>
            <Form />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;

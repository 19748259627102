import Cookies from 'js-cookie';

const storage = {
  getToken: () => {
    return Cookies.get('access_token');
  },
  setToken: (token: string) => {
    return Cookies.set('access_token', token);
  },
  clearToken: () => {
    return Cookies.remove('access_token');
  },
};

export default storage;

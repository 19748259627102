import { useMemo } from 'react';
import Stipper from 'common/Stipper';
import WishlistIcon from 'assets/svg/wishlistIcon.svg';
import { Typography, Image } from 'antd';
import CompanyCards from 'components/CompanyCards';
import SearchIcon from 'assets/svg/search-normal';
import { useIsActiveStore } from 'infrastructure/store/isActiveStore';
import { useNavigate } from 'react-router-dom';
import themeToken from 'infrastructure/theme/tokens';
import { StyledButton } from 'infrastructure/theme/components/Button';
import { StyledSearchInput } from 'infrastructure/theme/components/Input';
const { Text } = Typography;

const Market = () => {
  const navigate = useNavigate();
  const isActive = useIsActiveStore((state) => state.isActive);
  const computeButtonElement = useMemo(() => {
    const isShown = Object.keys(isActive).filter((key) => isActive[key]).length > 1;
    if (isShown) {
      return (
        <StyledButton type="primary" onClick={() => navigate('/')} banner size="small">
          Go, Next!
        </StyledButton>
      );
    }
  }, [isActive, navigate]);

  return (
    <>
      <Stipper
        description={
          <span
            style={{
              fontSize: '13px',
              color: 'rgba(255, 255, 255, 0.39) ',
              fontWeight: 500,
            }}
          >
            to Complete this step and go next
          </span>
        }
        text={
          <Text
            style={{
              fontSize: '21px',
              color: themeToken.white,
              fontWeight: 700,
              marginTop: '33px',
            }}
          >
            Choose at lest 2 Organization
          </Text>
        }
        image={
          <Image
            preview={false}
            src={WishlistIcon}
            width={160}
            style={{
              position: 'absolute',
              top: '-41px',
              left: '0px',
            }}
          />
        }
        button={computeButtonElement}
        textStyle={154}
      />
      <div style={{ margin: '24px 0px 70px 0px' }}>
        <CompanyCards
          title="Explore Company"
          description="Add Companis You Wish to  Trading in. you can be up-to- date with your list."
        >
          <StyledSearchInput
            placeholder="Search Category, name etc."
            style={{ width: '404px' }}
            suffix={<SearchIcon />}
          />
        </CompanyCards>
      </div>
    </>
  );
};

export default Market;

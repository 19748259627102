import { Modal } from 'antd';
import themeToken from '../tokens';
import styled from 'styled-components';

const defaultTheme = {
  titleFontSize: 21,
  titleColor: themeToken.secondary_dark_blue_900,
};

const globalTheme = {
  borderRadiusLG: themeToken.borderRadiusXl,
  boxShadow: ' 0px 4px 8px 0px rgba(49, 37, 92, 0.1)',
};

export const modalTheme = {
  ...defaultTheme,
  ...globalTheme,
};

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    width: 512px;
    padding: 36px;
  }

  .ant-modal-title {
    font-weight: 700;
    font-size: 21px;
  }

  .ant-modal-footer {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .ant-btn-primary {
      &:hover {
        background: ${themeToken.colorPrimary} !important;
      }
    }
    button {
      width: 212px;
      height: 50px;
    }
  }
`;

import { Avatar, Space, Layout as AntDLayout, Typography, Badge } from 'antd';
import SearchIcon from 'assets/svg/searchIcon';
import AvatarIcon from 'assets/svg/avatarIcon';
import NotificationIcon from 'assets/svg/notification';
import themeToken from 'infrastructure/theme/tokens';
import { useParams } from 'react-router-dom';

const AuthenticatedHeader = ({ title, width }: { title: string; width?: string }) => {
  const { Title, Text } = Typography;

  const { symbol } = useParams();
  const { Header } = AntDLayout;

  return (
    <Header
      style={{
        background: '#F6F8F9',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        width: width ? width : '98%',
        padding: '0px 50px 0px 0px',
        margin: '36px 0px 24px 24px',
      }}
    >
      <Title style={{ color: themeToken.black, fontSize: '27px', fontWeight: 700 }}>
        {title === '/market' ? (
          'The Market'
        ) : title === `/market/${symbol}` ? (
          'Company Profile '
        ) : title === '/wallet' ? (
          'Wallet'
        ) : title === '/watchlist' ? (
          'Your Watch list'
        ) : title === '/orders' ? (
          'My Orders'
        ) : title === '/stocks' ? (
          'My Stocks'
        ) : title === '/profile' ? (
          'Profile'
        ) : title === '/settings' ? (
          'Settings'
        ) : title === '/dashboard' ? (
          <>
            <span
              style={{
                color: themeToken.primary_600,
                fontSize: '27px',
                fontWeight: 700,
              }}
            >
              Hello
            </span>
            <span style={{ color: '#000000', fontSize: '27px', fontWeight: 500 }}> , RasMal </span>
          </>
        ) : (
          title
        )}
      </Title>

      <Space style={{ lineHeight: 0, alignSelf: 'flex-end' }}>
        <SearchIcon style={{ marginRight: '16px' }} />
        <Badge dot={true} size="small">
          <NotificationIcon />
        </Badge>
        <Avatar shape="circle" size={32} icon={<AvatarIcon />} style={{ marginLeft: '16px' }} />
        <Text
          style={{ fontSize: '17px', fontWeight: '700', marginLeft: '8px' }}
          color={themeToken.secondary_dark_blue_900}
        >
          Rasmal
        </Text>
      </Space>
    </Header>
  );
};

export default AuthenticatedHeader;

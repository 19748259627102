import { Image, Row, Space, Typography } from 'antd';
import themeToken from 'infrastructure/theme/tokens';
import ownedSharesSvg from 'assets/svg/ownedShares.svg';
import sharesCost from 'assets/svg/sharesCost.svg';
import revenue from 'assets/svg/revenue.svg';
import { useStocksStore } from 'infrastructure/store/stocksStore';
import { useEffect } from 'react';
import { toFixedFunc } from 'infrastructure/utils/toFixedFunc';
import { StyledCard } from 'infrastructure/theme/components/Card';

const { Title, Text } = Typography;

const MyPosition = ({ symbol }: { symbol: string }) => {
  const [stocks, getStocks] = useStocksStore((state) => [state.stocks, state.getStocks]);

  useEffect(() => {
    getStocks();
  }, []);

  const myPosition =
    stocks.docs.length > 0
      ? stocks.docs.find((doc: { company: { symbol: string } }) => doc.company.symbol === symbol)
      : { sharesCount: 0, shareCost: 0, unrealizedReturn: 0 };

  return (
    <StyledCard>
      <Title
        style={{
          fontSize: '18px',
          color: themeToken.black,
          fontWeight: '700',
          marginBottom: '16px',
        }}
      >
        My Position
      </Title>

      <Row gutter={[16, 16]} justify="space-between">
        <Space>
          <Image src={ownedSharesSvg} preview={false} />
          <div>
            <Title
              style={{
                fontSize: '13px',
                color: themeToken.gray,
                fontWeight: '500',
              }}
            >
              # owned shares
            </Title>
            <Text
              style={{
                fontSize: '15px',
                color: themeToken.secondary_dark_blue_900,
                fontWeight: '700',
              }}
            >
              {myPosition ? myPosition.sharesCount : 0}
            </Text>
          </div>
        </Space>

        <Space>
          <Image src={sharesCost} preview={false} />
          <div>
            <Title
              style={{
                fontSize: '13px',
                color: themeToken.gray,
                fontWeight: '500',
              }}
            >
              Shares Cost
            </Title>
            <Text
              style={{
                fontSize: '15px',
                color: themeToken.secondary_dark_blue_900,
                fontWeight: '700',
              }}
            >
              {myPosition ? myPosition.shareCost : 0} SAR
            </Text>
          </div>
        </Space>

        <Space>
          <Image src={revenue} preview={false} />
          <div>
            <Title
              style={{
                fontSize: '13px',
                color: themeToken.gray,
                fontWeight: '500',
              }}
            >
              Unrealized Gain/Loss
            </Title>
            <Text
              style={{
                fontSize: '15px',
                color: themeToken.secondary_dark_blue_900,
                fontWeight: '700',
              }}
            >
              {myPosition ? toFixedFunc(myPosition.unrealizedReturn, 2) : 0} SAR
            </Text>
          </div>
        </Space>
      </Row>
    </StyledCard>
  );
};

export default MyPosition;

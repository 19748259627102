import { Space } from 'antd';
import { StyledCard } from 'infrastructure/theme/components/Card';

type Props = {
  title?: React.ReactNode;
  text: React.ReactNode;
  description?: React.ReactNode;
  button?: React.ReactNode;
  textStyle?: number;
  image?: React.ReactElement;
};

const Stipper = ({ title, text, description, button, textStyle, image }: Props) => {
  return (
    <StyledCard className="stipper">
      <Space
        style={{
          alignItems: 'flex-start',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div>
          {image}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              position: 'absolute',
              left: textStyle,
              top: '10px',
            }}
          >
            {title}
            {text}
            {description}
          </div>
        </div>
        {button}
      </Space>
    </StyledCard>
  );
};

export default Stipper;

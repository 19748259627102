import { fetchGetRequests } from 'infrastructure/helpers/fetchServerRequests';
import { create } from 'zustand';

type IProps = {
  balanceSheets: {
    annualReports: [];
    quarterlyReports: [];
  };
  getBalanceSheet: (symbol: string) => Promise<void>;
};
export const useBalanceSheetStore = create<IProps>((set) => ({
  balanceSheets: {
    annualReports: [],
    quarterlyReports: [],
  },
  getBalanceSheet: async (symbol: string) => {
    const response = await fetchGetRequests(`balance-sheets/${symbol}`);
    set({
      balanceSheets: response.response,
    });
  },
}));

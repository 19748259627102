import styled from 'styled-components';
import { Table, Image, Typography } from 'antd';
import LineChart from 'components/Charts/LineChart';
import { Link } from 'react-router-dom';
import { useStocksStore } from 'infrastructure/store/stocksStore';
import { useEffect } from 'react';
import themeToken from 'infrastructure/theme/tokens';
import { toFixedFunc } from 'infrastructure/utils/toFixedFunc';
import RangePicker from 'common/RangePicker';
import { StyledCard } from 'infrastructure/theme/components/Card';

const { Title, Text } = Typography;

const StyledTable = styled(Table)`
  width: 100%;
  .ant-table-thead {
    .ant-table-cell {
      color: #858a9b;
      font-size: 13px;
      font-weight: 500;
      background-color: unset;
      border-bottom: unset;
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .ant-table-cell {
    color: #080022;
    font-size: 13px;
    font-weight: 700;
  }

  .ant-table-row {
    .ant-table-cell {
      border-bottom: unset;

      padding-left: 0px;
    }
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 30px;
  width: 100%;
`;

const StocksOverView = () => {
  const [stocks, getStocks] = useStocksStore((state) => [state.stocks, state.getStocks]);

  useEffect(() => {
    getStocks();
  }, []);

  const columns = [
    {
      title: 'Brand Logo',
      key: '1',
      render: (record: { company: { imageUrl: string } }) => (
        <Image src={record.company.imageUrl} preview={false} style={{ width: '37px', height: '38px' }} />
      ),
    },
    {
      title: 'Company Name',
      key: '2',
      width: '10%',
      render: (record: { company: { name: string; symbol: string } }) => (
        <Text
          style={{
            fontSize: '15px',
            fontWeight: 700,
            color: themeToken.secondary_dark_blue_900,
          }}
        >
          <Link to={`/market/${record.company.symbol}`}>{record.company.name}</Link>
        </Text>
      ),
    },
    {
      title: '# Ownded Shares',
      dataIndex: 'sharesCount',
      key: '3',
    },
    {
      title: 'current price/ share',
      key: '4',
      render: (record: { company: { sharePrice: number } }) => (
        <Text
          style={{
            fontSize: '15px',
            fontWeight: 700,
            color: themeToken.secondary_dark_blue_900,
          }}
        >
          {toFixedFunc(record.company.sharePrice, 3)}
        </Text>
      ),
    },
    {
      title: 'Cost/ share',
      dataIndex: 'shareCost',
      key: '5',
    },
    {
      title: 'Unrealized Gain/Loss',
      key: '6',
      render: (record: { company: { sharePrice: number }; shareCost: number; sharesCount: number }) => (
        <Text
          style={{
            fontSize: '15px',
            fontWeight: 700,
            color: themeToken.secondary_dark_blue_900,
          }}
        >
          {toFixedFunc((record.company.sharePrice - record.shareCost) * record.sharesCount, 3)}
        </Text>
      ),
    },
    {
      title: ' ',
      key: '7',
      width: '10%',
      render: (record: { evaluation: { stockPrice: number; timestamp: string }[] }) => {
        const data: number[] = [];
        const labels: string[] = [];

        record.evaluation.map((item: { stockPrice: number; timestamp: string }) => {
          const timestamp = Date.parse(item.timestamp);
          const date = new Date(timestamp);
          const validDate = date.toLocaleString('en-US');
          labels.push(validDate.split(',')[0]);
          data.push(item.stockPrice);
        });

        return (
          <div style={{ width: '100%' }}>
            <LineChart
              data={{
                labels,
                datasets: [
                  {
                    fill: true,
                    data,
                    borderColor: '#64C6FD',
                    backgroundColor: '#ECF8FF',
                  },
                ],
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <StyledCard>
      <StyledWrapper>
        <Title
          style={{
            fontSize: '17px',
            fontWeight: 700,
            color: themeToken.secondary_dark_blue_900,
          }}
        >
          Stocks Overview
        </Title>

        <RangePicker />
      </StyledWrapper>
      <StyledTable
        columns={columns}
        dataSource={stocks?.docs?.map((el: { activeCount: number; sharesCost: number }, i) => ({
          key: i,
          ...el,
        }))}
        pagination={false}
        bordered={false}
      />
    </StyledCard>
  );
};
export default StocksOverView;

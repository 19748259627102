/** @format */

import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ data }: { data: ChartData<'doughnut', number[], unknown> }) => {
  return <Doughnut data={data} />;
};

export default DoughnutChart;

import { DatePicker } from 'antd';
import themeToken from '../tokens';
import styled from 'styled-components';

const { RangePicker } = DatePicker;

const defaultTheme = {
  paddingInline: themeToken.paddingSM,
  activeBorderColor: themeToken.colorPrimary,
  hoverBorderColor: themeToken.colorPrimary,
};

const globalTheme = {
  borderRadius: themeToken.borderRadiusLG,
  colorLinkHover: themeToken.colorPrimary,
  controlHeight: themeToken.controlHeightSM,
  lineWidth: themeToken.lineWidth,
  lineType: themeToken.lineType,
  fontSize: themeToken.fontSizeSM,
  colorText: themeToken.gray,
};

export const rangePickerTheme = {
  ...defaultTheme,
  ...globalTheme,
};

export const StyledRangePicker = styled(RangePicker)`
  align-self: flex-end;
  background-color: #f9fafb;

  .ant-picker-input {
    input {
      color: #858a9b !important;
      font-size: 12px;
    }
  }

  &.ant-picker-dropdown {
    .ant-picker-presets {
      margin: 25px 0px;
      background: red;
    }
  }

  &:hover {
    border-color: ${themeToken.colorPrimary};
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  // border-radius: 8px;
  height: 48px;
  font-weight: 500;
  // font-size: 13px;
  // border: 1px solid #ebebeb;
  background: inherit;
  &:hover {
    border-color: ${themeToken.colorPrimary};
  }
`;

import { Col, Row, Image, ImageProps, Typography } from 'antd';
import Stipper from 'common/Stipper';
import EvaluationsCard from 'components/EvaluationsCard';
import ExploreCompanyCard from 'components/CompanyCards';
import FinancialCard from 'components/FinnancialCard';
import StocksCard from 'components/StocksCard';
import StocksOverview from 'components/StocksOverviewCard';
import WatchlistCard from 'components/WatchlistCard';
import { useNavigate } from 'react-router-dom';
import { useWishlistStore } from 'infrastructure/store/wishlistStore';
import { useWalletBalanceStore } from 'infrastructure/store/walletBalance';
import { useEffect, useMemo, useState } from 'react';
import themeToken from 'infrastructure/theme/tokens';
import Arrow from 'assets/svg/chevron-down';
import { useStocksStore } from 'infrastructure/store/stocksStore';
import { StyledButton } from 'infrastructure/theme/components/Button';

const { Title, Text } = Typography;

const Dashboard = () => {
  const navigate = useNavigate();
  const data = useWishlistStore((state) => state.data);
  const [walletBalance, getWalletBalance] = useWalletBalanceStore((state) => [
    state.walletBalance,
    state.getWalletBalance,
  ]);
  const [getStocks] = useStocksStore((state: { getStocks: () => Promise<void> }) => [state.getStocks]);

  const computeImageProps: ImageProps = useMemo(() => {
    if (walletBalance?.balance > 0) {
      return {
        src: require('../../assets/png/congratulation.png'),
        style: { position: 'absolute', top: '-30px', left: '1050px' },
        width: 293,
      };
    }
    if (data?.length !== 0) {
      return {
        src: require('../../assets/png/wallet.png'),
        style: { position: 'absolute', top: '-48px', left: '0px' },
        width: 160,
      };
    }
    return {
      src: require('../../assets/png/step1.png'),
      style: { position: 'absolute', top: '-44px', left: '0px' },
      width: 160,
    };
  }, [walletBalance?.balance, data?.length]);

  const computeTextProps = useMemo(() => {
    if (walletBalance?.balance > 0) {
      return 'well done, Congratulation';
    }
    if (data?.length !== 0) {
      return 'Last Step to Start Trading!';
    }
    return 'Start Your Trade Account With Fav Organizations';
  }, [walletBalance?.balance, data?.length]);

  const computeDescriptionProps = useMemo(() => {
    if (walletBalance?.balance > 0) {
      return 'You now as an Investor in our Platform';
    }
    if (data?.length !== 0) {
      return 'In order to start trading in Your Organization you added, please Deposit amount to your wallet';
    }
    return 'you Have to add Companies to your wish list. The Organizations you Want to Trade in';
  }, [walletBalance?.balance, data?.length]);

  const computeTitleProps = useMemo(() => {
    if (walletBalance?.balance > 0) {
      return undefined;
    }
    if (data?.length !== 0) {
      return 'Step 2 / 2';
    }
    return 'Step 1 / 2';
  }, [data?.length, walletBalance]);

  const computeButtonElement = useMemo(() => {
    if (walletBalance?.balance > 0) {
      return undefined;
    }
    if (data?.length !== 0) {
      return (
        <StyledButton type="primary" onClick={() => navigate('/wallet')} banner size="small">
          Review my wallet
        </StyledButton>
      );
    }
    return (
      <StyledButton type="primary" onClick={() => navigate('/market')} banner size="small">
        Add Your Wish list
      </StyledButton>
    );
  }, [data?.length, walletBalance, navigate]);

  const [isAlertVisible, setIsAlertVisible] = useState(true);

  useEffect(() => {
    getWalletBalance();
    getStocks();
  }, [getWalletBalance, getStocks]);

  useEffect(() => {
    if (data?.length > 2 || walletBalance?.balance > 0) {
      setIsAlertVisible(false);
    }
  }, [data?.length, walletBalance?.balance]);

  return (
    <>
      {isAlertVisible && (
        <Stipper
          textStyle={walletBalance?.balance > 0 ? 50 : 154}
          image={<Image preview={false} {...computeImageProps} />}
          text={
            <Text
              style={{
                fontSize: '21px',
                color: themeToken.white,
                fontWeight: 700,
              }}
            >
              {computeTextProps}
            </Text>
          }
          description={
            <span
              style={{
                fontSize: '13px',
                color: 'rgba(255, 255, 255, 0.39) ',
                fontWeight: 500,
              }}
            >
              {computeDescriptionProps}
            </span>
          }
          title={
            <Title
              style={{
                fontSize: '16px',
                color: themeToken.white,
                fontWeight: 700,
              }}
            >
              {computeTitleProps}
            </Title>
          }
          button={computeButtonElement}
        />
      )}
      <Row justify="space-around" gutter={[24, 24]} style={{ marginTop: '30px' }}>
        <Col span={16}>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <FinancialCard />
            </Col>
            <Col span={12}>
              <StocksCard />
            </Col>
          </Row>
          <Row>
            <EvaluationsCard />
          </Row>
        </Col>
        <Col span={8}>
          <WatchlistCard />
        </Col>
      </Row>
      <Row>
        <StocksOverview />
      </Row>
      <Row>
        <ExploreCompanyCard title="Explore Company">
          <StyledButton type="text" onClick={() => navigate('/market')} banner size="small" icon=" View All">
            <Arrow />
          </StyledButton>
        </ExploreCompanyCard>
      </Row>
    </>
  );
};

export default Dashboard;

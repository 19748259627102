import storage from 'infrastructure/utils/storage';
import Dashboard from 'pages/Dashboard';
import Market from 'pages/Market';
import Orders from 'pages/Orders';
import Profile from 'pages/Profile';
import Settings from 'pages/Settings';
import Stocks from 'pages/Stocks';
import Wallet from 'pages/Wallet';
import Watchlist from 'pages/Watchlist';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoutes } from 'routes/protected';

const RootRoutes = () => {
  const user = !!storage.getToken();

  return (
    <Routes>
      <Route
        path="dashboard"
        element={
          <ProtectedRoutes user={user}>
            <Dashboard />
          </ProtectedRoutes>
        }
      />

      <Route
        path="market"
        element={
          <ProtectedRoutes user={user}>
            <Market />
          </ProtectedRoutes>
        }
      />
      <Route
        path="wallet"
        element={
          <ProtectedRoutes user={user}>
            <Wallet />
          </ProtectedRoutes>
        }
      />

      <Route
        path="watchlist"
        element={
          <ProtectedRoutes user={user}>
            <Watchlist />
          </ProtectedRoutes>
        }
      />
      <Route
        path="orders"
        element={
          <ProtectedRoutes user={user}>
            <Orders />
          </ProtectedRoutes>
        }
      />

      <Route
        path="stocks"
        element={
          <ProtectedRoutes user={user}>
            <Stocks />
          </ProtectedRoutes>
        }
      />

      <Route
        path="profile"
        element={
          <ProtectedRoutes user={user}>
            <Profile />
          </ProtectedRoutes>
        }
      />

      <Route
        path="settings"
        element={
          <ProtectedRoutes user={user}>
            <Settings />
          </ProtectedRoutes>
        }
      />
    </Routes>
  );
};

export default RootRoutes;

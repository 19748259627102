import { Row, TabsProps, Typography } from 'antd';
import EvaluationChart from './chartTab';
import themeToken from 'infrastructure/theme/tokens';
import RangePicker from 'common/RangePicker';
import { useStocksStore } from 'infrastructure/store/stocksStore';
import { useEvaluationsStore } from 'infrastructure/store/evaluationsStore';
import { useLocation, useParams } from 'react-router-dom';
import type { Dayjs } from 'dayjs';
import { StyledCard } from 'infrastructure/theme/components/Card';
import { StyledTabs } from 'infrastructure/theme/components/Tabs';

const { Title } = Typography;

const EvaluationsCard = () => {
  const location = useLocation();
  const { symbol } = useParams();

  const [stocks] = useStocksStore((state) => [state.stocks]);
  const [getEvaluations, setEvaluationDate] = useEvaluationsStore((state) => [
    state.getEvaluations,
    state.setEvaluationDate,
  ]);

  const handleSetEvaluationDate = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    if (dates) {
      setEvaluationDate(dateStrings);
    }
  };

  const items: TabsProps['items'] = stocks?.docs?.map((el: { symbol: string; company: { name: string } }) => ({
    key: el.symbol,
    label: el?.company?.name,
    children: <EvaluationChart symbol={el?.symbol} />,
  }));

  const updateEvaluation = (value: string) => {
    console.log('🚀 ~ updateEvaluation ~ value:', value);
    getEvaluations(value);
  };

  return (
    <StyledCard style={{ height: '530px' }}>
      <Row
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Title
          style={{
            color: themeToken.secondary_dark_blue_900,
            fontWeight: 700,
            fontSize: '17px',
          }}
        >
          Overall Evaluation
        </Title>
        <RangePicker onChange={handleSetEvaluationDate} />
      </Row>
      {location.pathname === '/dashboard' ? (
        <StyledTabs items={items} onChange={updateEvaluation} />
      ) : (
        <EvaluationChart symbol={symbol} />
      )}
    </StyledCard>
  );
};

export default EvaluationsCard;

import { Col, Row, Space, Table, Typography, Badge } from 'antd';
import { ColumnsType } from 'antd/es/table';
import DoughnutChart from 'components/Charts/Doughnut';
import { StyledCard } from 'infrastructure/theme/components/Card';
import themeToken from 'infrastructure/theme/tokens';
import { styled } from 'styled-components';
const { Title } = Typography;

const items = [
  {
    name: 'Founders',
    color: themeToken.primary_500,
  },
  {
    name: 'ESPO',
    color: '#797979',
  },
  {
    name: 'Options Outstanding',
    color: themeToken.success_500,
  },
  {
    name: 'Options Available to Grant',
    color: themeToken.error_500,
  },
  {
    name: 'Series A Shares',
    color: themeToken.secondary_dark_blue_500,
  },
  {
    name: 'Series B Shares',
    color: themeToken.secondary_500,
  },
];

interface DataType {
  key?: string;
  type?: string;
  shares?: string;
  fd?: string;
  shareholder?: string;
  prefix?: string;
  equityType?: string;
  className?: string;
  totalClassShares?: string;
  ownerShip?: string;
}

const StyledTable = styled(Table)`
  .ant-table thead th {
    background-color: unset;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    color: #858a9b;
  }
`;

const data: DataType[] = [
  {
    key: '1',
    type: 'Founders',
    shares: '10,000',
    fd: '20.0%',
  },
  {
    key: '2',
    type: 'Series A Shares',
    shares: '10,000',
    fd: '20.0%',
  },
  {
    key: '3',
    type: 'Series B Shares',
    shares: '10,000',
    fd: '20.0%',
  },
];

const capTableData: DataType[] = [
  {
    key: '1',
    shareholder: 'Leora',
    prefix: 'Cs-1',
    equityType: 'common',
    className: 'Founnders',
    totalClassShares: '10.000',
    shares: '400',
    ownerShip: '20.0%',
  },
  {
    key: '2',
    shareholder: 'Collin',
    prefix: 'Cs-1',
    equityType: 'common',
    className: 'Founnders',
    totalClassShares: '10.000',
    shares: '400',
    ownerShip: '20.0%',
  },
  {
    key: '3',
    shareholder: 'Bo',
    prefix: 'Cs-1',
    equityType: 'common',
    className: 'Founnders',
    totalClassShares: '10.000',
    shares: '400',
    ownerShip: '20.0%',
  },
];
const ShareholdingInfo = () => {
  const columns: ColumnsType<DataType> = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type: string) => <Badge color={themeToken.primary_500} text={type} />,
    },
    {
      title: 'Shares',
      dataIndex: 'shares',
      key: 'shares',
    },
    {
      title: 'Owner Ship (FD) %',
      dataIndex: 'fd',
      key: 'fd',
    },
  ];

  const capTableColumns: ColumnsType<DataType> = [
    {
      title: 'Shareholder',
      dataIndex: 'shareholder',
      key: 'shareholder',
      render: (shareholder: string) => shareholder,
    },
    {
      title: 'Prefix',
      dataIndex: 'prefix',
      key: 'prefix',
    },
    {
      title: 'Equity Type',
      dataIndex: 'equityType',
      key: 'equityType',
    },
    {
      title: 'Class Name',
      dataIndex: 'className',
      key: 'className',
    },
    {
      title: 'Total Class Shares',
      dataIndex: 'totalClassShares',
      key: 'totalClassShares',
    },
    {
      title: 'Shares',
      dataIndex: 'shares',
      key: 'shares',
    },
    {
      title: 'OwnerShip %',
      dataIndex: 'ownerShip',
      key: 'ownerShip',
    },
  ];

  return (
    <>
      <Row
        justify="space-between"
        gutter={[24, 24]}
        style={{
          border: '1px solid #F2F4F7',
          borderRadius: '16px',
          padding: '24px',
          width: '685px',
          margin: '0 auto',
        }}
      >
        <Col
          span={12}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Title style={{ fontSize: '13px', fontWeight: 700, color: '#1A202C' }}>Owner Ship % by Share Class</Title>
          <div style={{ width: '40%' }}>
            <DoughnutChart
              data={{
                datasets: [
                  {
                    data: [10, 5, 15, 3, 2],
                    backgroundColor: [
                      themeToken.secondary_500,
                      themeToken.primary_500,
                      themeToken.error_500,
                      themeToken.success_500,
                      '#797979',
                    ],
                    rotation: 270,
                  },
                ],
              }}
            />
          </div>
        </Col>

        <Col
          span={12}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Title style={{ fontSize: '13px', fontWeight: 700, color: '#1A202C' }}>Owner Ship % by Share Holders</Title>
          <div style={{ width: '40%' }}>
            <DoughnutChart
              data={{
                datasets: [
                  {
                    data: [10, 5, 15, 3, 2],
                    backgroundColor: [
                      themeToken.secondary_500,
                      themeToken.primary_500,
                      themeToken.error_500,
                      themeToken.success_500,
                      themeToken.secondary_dark_blue_500,
                    ],
                    rotation: 270,
                  },
                ],
              }}
            />
          </div>
        </Col>

        <Space
          style={{
            marginTop: '30px',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          {items.map((item) => (
            <Badge key={item.color} color={item.color} text={item.name} />
          ))}
        </Space>
      </Row>
      <StyledCard>
        <Title
          style={{
            fontSize: '17px',
            fontWeight: 700,
            color: '#1A202C',
            marginBottom: '30px',
          }}
        >
          Cap Table Overview
        </Title>
        <StyledTable columns={columns} dataSource={data} pagination={false} />
      </StyledCard>

      <StyledCard>
        <Title
          style={{
            fontSize: '17px',
            fontWeight: 700,
            color: '#1A202C',
            marginBottom: '30px',
          }}
        >
          Details Cap Table
        </Title>
        <StyledTable columns={capTableColumns} dataSource={capTableData} pagination={false} />
      </StyledCard>
    </>
  );
};
export default ShareholdingInfo;

import { Col, Row, Space, Typography } from 'antd';
import CompanyCard from 'common/CompanyCard';
import { useIsActiveStore } from 'infrastructure/store/isActiveStore';
import { axiosApiInstance } from 'infrastructure/utils/api';
import themeToken from 'infrastructure/theme/tokens';
import { ReactElement, useEffect, useState } from 'react';
import { toFixedFunc } from 'infrastructure/utils/toFixedFunc';
import { StyledCard } from 'infrastructure/theme/components/Card';

const { Title } = Typography;

type Props = {
  title: string;
  description?: string;
  children: ReactElement;
  symbol: string;
};

type IDocs = {
  _id: string;
  name: string;
  imageUrl: string;
  description: string;
  category: string;
  symbol: string;
  isFollowed: boolean;
  portfolio: {
    sharePrice: number | string;
    revenuePrecent: number;
  };
};

const RelatedCompanies = ({ symbol, title, children }: Props) => {
  const updateIsActive = useIsActiveStore((state) => state.updateIsActive);

  const [companies, setCompanies] = useState<IDocs[]>([]);

  useEffect(() => {
    axiosApiInstance
      .get(`companies/${symbol}/related?limit=4`)
      .then((res: { data: { docs: IDocs[] } }) => {
        setCompanies(res.data.docs);
      })
      .catch(() => {});
  }, []);

  const handleAddCompanyToWishlist = (e: { stopPropagation: () => void }, companyId: string) => {
    e.stopPropagation();
    updateIsActive(companyId);

    axiosApiInstance
      .post(`watchlist/${companyId}`)
      .then(() => {})
      .catch(() => {});
  };

  return (
    <StyledCard>
      <Row
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: '28px',
        }}
      >
        <Space direction="vertical" align="start">
          <Title
            style={{
              color: themeToken.secondary_dark_blue_900,
              fontWeight: 700,
              fontSize: '17px',
              margin: '0px',
            }}
          >
            {title}
          </Title>
        </Space>
        <Space>{children}</Space>
      </Row>
      <Row justify="start" gutter={[16, 16]}>
        {companies?.length > 0 &&
          companies?.map((item: IDocs) => (
            <Col span={8} key={item._id}>
              <CompanyCard
                companyName={item.name}
                category={item.category}
                revenuePrecent={item.portfolio.revenuePrecent}
                sharePrice={toFixedFunc(item.portfolio.sharePrice, 2)}
                description={item.description}
                onClick={(e) => handleAddCompanyToWishlist(e, item._id)}
                id={item._id}
                isFollowed={item.isFollowed}
                icon={item.imageUrl}
              />
            </Col>
          ))}
      </Row>
    </StyledCard>
  );
};

export default RelatedCompanies;

import { SVGProps } from 'react';
const MoreIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#858A9B"
      d="M9.333 3.333C9.333 2.6 8.733 2 8 2c-.734 0-1.334.6-1.334 1.333 0 .734.6 1.334 1.334 1.334.733 0 1.333-.6 1.333-1.334ZM9.333 12.667c0-.734-.6-1.334-1.333-1.334-.734 0-1.334.6-1.334 1.334C6.666 13.4 7.266 14 8 14c.733 0 1.333-.6 1.333-1.333ZM9.333 8c0-.733-.6-1.333-1.333-1.333-.734 0-1.334.6-1.334 1.333S7.266 9.333 8 9.333c.733 0 1.333-.6 1.333-1.333Z"
    />
  </svg>
);
export default MoreIcon;

import { Col, Row, Image, Typography } from 'antd';
import Stipper from 'common/Stipper';
import OverallBalances from 'components/wallet/overallBalances';
import TotalBalance from 'components/wallet/totalBalance';
import Transactions from 'components/wallet/transactions';
import { useWalletBalanceStore } from 'infrastructure/store/walletBalance';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import themeToken from 'infrastructure/theme/tokens';
import { StyledButton } from 'infrastructure/theme/components/Button';
const { Text } = Typography;

const Wallet = () => {
  const navigate = useNavigate();

  const [walletBalance, getWalletBalance] = useWalletBalanceStore((state) => [
    state.walletBalance,
    state.getWalletBalance,
  ]);

  useEffect(() => {
    getWalletBalance();
  }, [getWalletBalance]);

  const computeButtonElement = useMemo(() => {
    if (walletBalance?.balance) {
      return (
        <StyledButton type="primary" onClick={() => navigate('/')} banner size="small">
          Start now
        </StyledButton>
      );
    }
  }, [walletBalance?.balance]);
  return (
    <>
      <Stipper
        text={
          <Text
            style={{
              fontSize: '21px',
              color: themeToken.white,
              fontWeight: 700,
              marginTop: '33px',
            }}
          >
            Add Balance to Your Wallet
          </Text>
        }
        description={
          <span
            style={{
              fontSize: '13px',
              color: 'rgba(255, 255, 255, 0.39) ',
              fontWeight: 500,
            }}
          >
            After Add Balance You Can Trade in your added Wish list Organization
          </span>
        }
        image={
          <Image
            preview={false}
            src={require('../../assets/png/waleetImage.png')}
            width={160}
            style={{ position: 'absolute', top: '-38px', left: '0px' }}
          />
        }
        button={computeButtonElement}
        textStyle={154}
      />

      <Row gutter={[24, 24]} style={{ marginTop: '29px' }}>
        <Col span={8}>
          <TotalBalance balance={walletBalance.balance} activeBalance={walletBalance.activeBalance} />
        </Col>

        <Col span={16}>
          <OverallBalances />
        </Col>
      </Row>

      <Row style={{ marginTop: '24px' }}>
        <Transactions />
      </Row>
    </>
  );
};

export default Wallet;

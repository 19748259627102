import { SVGProps, memo } from 'react';
const LineChart = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={136} height={33} fill="none" {...props}>
    <path
      stroke="#D1D5DA"
      strokeLinecap="round"
      strokeWidth={2}
      d="M1 25.424c2.786 0 21.838-8.164 24.731-8.164 2.572 0 18.306 14.735 20.77 14.735 2.465-.614 18.949-32.19 20.985-30.96 1.285.777 3.803 7.99 4.875 8.81.643.492 3.857.995 5.679 2.46.857.69 8.075 19.476 9.573 19.69 2.249.323 9.314-16.477 10.493-16.887 1.5-.522 9.312 7.45 11.348 7.45 2.25 0 21.731-21.322 24.731-21.322"
    />
  </svg>
);
const Memo = memo(LineChart);
export default Memo;

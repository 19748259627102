/** @format */

import { fetchGetRequests } from 'infrastructure/helpers/fetchServerRequests';
import { create } from 'zustand';

type IProps = {
  stocks: {
    docs: [];
    companiesCount: number;
    sharesCost: number;
    sharesCount: number;
    totalMarketPrice: number;
    unrealizedReturn: number;
  };
  getStocks: () => Promise<void>;
};
export const useStocksStore = create<IProps>((set) => ({
  stocks: {
    docs: [],
    companiesCount: 0,
    sharesCost: 0,
    sharesCount: 0,
    totalMarketPrice: 0,
    unrealizedReturn: 0,
  },
  getStocks: async () => {
    const response = await fetchGetRequests('stocks');

    set({
      stocks: response.response,
    });
  },
}));

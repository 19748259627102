/** @format */

import { Menu } from 'antd';
import themeToken from 'infrastructure/theme/tokens';
import styled from 'styled-components';
import { Layout as AntDLayout } from 'antd';

const { Sider } = AntDLayout;

export const StyledMenu = styled(Menu)`
  .ant-menu-item-group-title {
    padding: 0px 0px 24px 48px;
    font-size: 12px;
    font-weight: 700;
    color: #31255c;
  }

  [title~='___________'] {
    padding: 0px 0px 16px 48px;
  }

  .ant-menu-item {
    padding-left: 44px;
  }

  .ant-menu-item-group-list {
    .ant-menu-item {
      padding-left: 44px !important;
    }
  }
  .ant-menu-item-selected {
    background-color: ${'rgba(255, 255, 255, 0.1)'};
    color: ${themeToken.primary_500};
    border-left: 4px solid;
    border-radius: 0px 8px 8px 0px;
    border-image: url('../../assets/svg/border.svg');
    :hover {
      color: ${themeToken.primary_500} !important;
    }
    svg {
      path {
        stroke: ${themeToken.primary_500};
      }
    }
    span {
      color: ${themeToken.primary_500};
    }
  }
  :active {
    background-color: unset !important ;
  }

  .ant-menu-item-group-title {
    color: ${themeToken.secondary_dark_blue_400};
  }

  .ant-menu-title-content {
    color: ${themeToken.white};
  }
`;

export const StyledSider = styled(Sider)`
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background: ${themeToken.secondary_dark_blue_900};
  border-radius: 0px 16px 0px 0px;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    background: ${themeToken.secondary_dark_blue_900};
  }
  &.ant-layout-sider {
    background: ${themeToken.secondary_dark_blue_900};
  }
`;

import { Select } from 'antd';
import themeToken from '../tokens';
import styled from 'styled-components';

const defaultTheme = {
  optionActiveBg: themeToken.colorBgTextHover,
};

const globalTheme = {
  fontSize: themeToken.fontSizeSM,
  borderRadius: themeToken.borderRadius,
  colorText: themeToken.colorTextPlaceholder,
};

export const selectTheme = {
  ...defaultTheme,
  ...globalTheme,
};

export const StyledSelect = styled(Select)`
  background-color: ${themeToken.light_gray};
  .ant-select-selection-item {
    font-size: 12px;
    color: ${themeToken.colorTextPlaceholder};
    font-weight: 600;
  }
`;

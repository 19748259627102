import {  fetchGetRequests } from 'infrastructure/helpers/fetchServerRequests';
import { axiosApiInstance } from 'infrastructure/utils/api';
import {create} from 'zustand';

type Props = {
   walletBalance: {
    balance: number;
    activeBalance:number
   },
   getWalletBalance: () => Promise<void>;
   updateBalance:(type: string, balanceValue: number) => Promise<void>;
}

export const useWalletBalanceStore = create<Props>((set) => ({
 walletBalance:{
    balance: 0,
    activeBalance:0
 },
 getWalletBalance:async () =>{
    const response = await fetchGetRequests('wallet');       
    set({
        walletBalance:response.response,
    })
},
    updateBalance: async(type: string,balanceValue:number ) => {
     const response:{data:{balance:number,activeBalance:number}} = await axiosApiInstance
        .post('wallet', {
          amount: balanceValue,
          direction: type,
        });        
        set({ walletBalance:response.data})
    },
}));

import { Col, Row, Table, Tag, Typography } from 'antd';
import { axiosApiInstance } from 'infrastructure/utils/api';
import themeToken from 'infrastructure/theme/tokens';
import { toFixedFunc } from 'infrastructure/utils/toFixedFunc';
import { useEffect, useState } from 'react';
import { styled } from 'styled-components';
const { Title, Text } = Typography;

const StyledTable = styled(Table)`
  .ant-table-thead {
    .ant-table-cell {
      color: #858a9b;
      font-size: 13px;
      font-weight: 500;
      background-color: unset;
      border-bottom: unset;
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .ant-table-cell {
    color: #080022;
    font-size: 13px;
    font-weight: 700;
  }

  .ant-table-row {
    .ant-table-cell {
      border-bottom: unset;

      padding-left: 0px;
    }
  }
`;

const PriceDepthChartTable = ({ sharePrice, symbol }: { sharePrice: number; symbol: string }) => {
  const [bidData, setBidData] = useState<{ price: number; quanyity: number }[]>([]);
  const [askData, setAskData] = useState<{ price: number; quanyity: number }[]>([]);

  useEffect(() => {
    axiosApiInstance.get(`companies/${symbol}/price-depth`).then((res: { data: [] }) => {
      res.data.map((item: { _id: string; prices: Array<{ price: number; quanyity: number }> }) => {
        return item._id === 'BUY' ? setBidData(item.prices) : setAskData(item.prices);
      });
    });
  }, []);

  const bidColumns = [
    {
      title: 'Qty',
      dataIndex: 'quanyity',
      key: 'qty',
    },
    {
      title: 'Bid',
      dataIndex: 'price',
      key: 'bid',
      render: (price: number) => <Tag color="green">{toFixedFunc(price, 2)}</Tag>,
    },
  ];

  const askColumns = [
    {
      title: 'Ask',
      dataIndex: 'price',
      key: 'ask',
      render: (price: number) => <Tag color="red">{toFixedFunc(price, 2)}</Tag>,
    },
    {
      title: 'Qty',
      dataIndex: 'quanyity',
      key: 'qty2',
    },
  ];

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <StyledTable
          columns={bidColumns}
          dataSource={bidData}
          pagination={false}
          bordered={false}
          style={{ width: '50%' }}
        />

        <StyledTable
          columns={askColumns}
          dataSource={askData}
          pagination={false}
          bordered={false}
          style={{ width: '50%' }}
        />
      </div>
      <Row justify="space-between">
        <Col span={12}>
          <Title
            style={{
              fontSize: '15px',
              fontWeight: 500,
              color: themeToken.gray,
            }}
          >
            Shares Price
          </Title>
          <Text
            style={{
              fontSize: '15px',
              fontWeight: 700,
              color: themeToken.black,
            }}
          >
            {toFixedFunc(sharePrice, 2)} SAR
          </Text>
        </Col>
        <Col span={12}>
          <Title
            style={{
              fontSize: '15px',
              fontWeight: 500,
              color: themeToken.gray,
            }}
          >
            Status Today
          </Title>
          <Tag color="success" bordered={false}>
            +5.7%
          </Tag>
        </Col>
      </Row>
    </>
  );
};

export default PriceDepthChartTable;

import { Input, InputNumber } from 'antd';
import themeToken from '../tokens';
import styled from 'styled-components';

const { Search } = Input;

const defaultTheme = {
  paddingBlock: themeToken.paddingLG,
  paddingInline: themeToken.paddingM,
};

const globalTheme = {
  borderRadius: themeToken.borderRadiusLG,
  colorBorder: themeToken.inputColorBorder,
  colorError: themeToken.error_600,
  colorText: themeToken.colorTextPlaceholder,
  controlHeight: themeToken.controlHeightM,
};

export const inputTheme = {
  ...defaultTheme,
  ...globalTheme,
};

export const StyledInput = styled(Input)`
  height: 48px;

  &:hover {
    border-color: ${themeToken.colorPrimary};
  }

  &:focus {
    color: ${themeToken.secondary_dark_blue_900};
  }
`;

export const StyledInputPassword = styled(Input.Password)`
  height: 48px;
  &:hover {
    border-color: ${themeToken.colorPrimary};
  }
`;

export const StyledSearchInput = styled(Search)`
  .ant-input-affix-wrapper {
    background: ${themeToken.neutral_dark};
    border-start-end-radius: 8px !important;
    border-end-end-radius: 8px !important;
    border-color: ${themeToken.neutral_dark};
    height: 48px;

    &:hover {
      border-color: ${themeToken.neutral_dark};
    }

    &:focus-within {
      border-color: ${themeToken.neutral_dark};
    }
  }

  .ant-input {
    background: ${themeToken.neutral_dark};
  }

  .ant-input-group-addon {
    display: none;
  }
`;

export const StyledInputNumber = styled(InputNumber)`
  width: 100%;

  .ant-input-number-input {
    height: 48px;
  }

  .ant-input-number {
    border-right: none;
    font-size: 15px;
    font-weight: 500;

    &:focus {
      font-size: 17px;
    }
  }
  .ant-input-number-group-addon {
    background: unset;
  }
`;

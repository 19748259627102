import React, { useEffect } from 'react';
import { Col, Image, Row, Space, Typography } from 'antd';
import themeToken from 'infrastructure/theme/tokens';
import { useWishlistStore } from 'infrastructure/store/wishlistStore';
import wishlistImg from 'assets/svg/wishlist.svg';
import { StyledCard } from 'infrastructure/theme/components/Card';

const { Title, Text, Link } = Typography;

const WatchlistCard = () => {
  const [data, watchlist] = useWishlistStore((state) => [state.data, state.watchlist]);

  useEffect(() => {
    watchlist();
  }, []);

  return (
    <StyledCard className="wallet" style={{ height: '700px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Title
          style={{
            color: themeToken.secondary_dark_blue_900,
            fontWeight: 700,
            fontSize: '17px',
          }}
        >
          Watch List
        </Title>
      </div>
      {data?.length ? (
        data?.map(
          (
            item: {
              company: {
                _id: string;
                name: string;
                portfolio: {
                  sharePrice: number;
                };
                imageUrl?: string;
              };
            },
            i: number,
          ) => (
            <React.Fragment key={i}>
              <Row
                gutter={[16, 16]}
                justify="space-between"
                align="middle"
                style={{ marginTop: '25px' }}
                key={item.company._id}
              >
                <Col>
                  <Image src={item.company.imageUrl} preview={false} style={{ width: '37px', height: '38px' }} />
                  <Space direction="vertical" align="start">
                    <Text
                      style={{
                        fontSize: '13px',
                        fontWeight: 700,
                        color: themeToken.secondary_dark_blue_900,
                        marginLeft: '9px',
                      }}
                    >
                      {item.company.name}
                    </Text>
                    <Text
                      style={{
                        fontSize: '12px',
                        fontWeight: 500,
                        color: '#858A9B',
                        marginLeft: '9px',
                      }}
                    >
                      12 Jan, 10:56 pm
                    </Text>
                  </Space>
                </Col>
                <Col>
                  <Space direction="vertical" align="start">
                    <Text
                      style={{
                        fontSize: '13px',
                        fontWeight: 700,
                        color: themeToken.secondary_dark_blue_900,
                      }}
                    >
                      {Math.round(item.company.portfolio.sharePrice)}
                    </Text>
                    <Text
                      style={{
                        fontSize: '12px',
                        fontWeight: 700,
                        color: '#ED5A74',
                      }}
                    >
                      {' '}
                      -12.6
                    </Text>
                  </Space>
                </Col>
              </Row>
              <hr
                style={{
                  width: '162px',
                  border: '1px  solid #EDF2F7',
                  marginTop: '10px',
                }}
              />
            </React.Fragment>
          ),
        )
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '101px',
            alignItems: 'center',
          }}
        >
          <Image src={wishlistImg} preview={false} />
          <Text
            style={{
              fontWeight: 500,
              fontSize: '13px',
              color: themeToken.secondary_dark_blue_900,
              marginTop: '20px',
            }}
          >
            No Companies added to your wish list{' '}
          </Text>
          <Link
            style={{
              fontWeight: 500,
              fontSize: '13px',
              color: themeToken.primary_500,
              marginTop: '20px',
            }}
            href="/market"
          >
            {'Explore Market >'}
          </Link>
        </div>
      )}
    </StyledCard>
  );
};

export default WatchlistCard;

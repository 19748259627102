import { Progress, Typography } from 'antd';
import balanceBox from 'assets/png/balanceBox.png';
import themeToken from 'infrastructure/theme/tokens';
import { useState } from 'react';
import { useWalletBalanceStore } from 'infrastructure/store/walletBalance';
import { toFixedFunc } from 'infrastructure/utils/toFixedFunc';
import { StyledButton } from 'infrastructure/theme/components/Button';
import { StyledCard } from 'infrastructure/theme/components/Card';
import { StyledInputNumber } from 'infrastructure/theme/components/Input';
import { StyledModal } from 'infrastructure/theme/components/Modal';
const { Title, Text } = Typography;

const TotalBalance = ({ balance, activeBalance }: { balance: number; activeBalance: number }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [balanceValue, setBalanceValue] = useState(0);
  const updateBalance = useWalletBalanceStore((state) => state.updateBalance);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showWithdrawModal = () => {
    setIsWithdrawModalOpen(true);
  };

  const handleWithdrawCancel = () => {
    setIsWithdrawModalOpen(false);
  };

  return (
    <StyledCard
      className="wallet"
      style={{
        backgroundImage: `url(${balanceBox})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right bottom',
      }}
    >
      <div
        style={{
          alignItems: 'start',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Title
          style={{
            fontSize: '17px',
            fontWeight: 700,
            color: themeToken.secondary_dark_blue_900,
            margin: '0px',
          }}
        >
          Total Balance
        </Title>
        <Text
          style={{
            fontSize: '27px',
            fontWeight: 700,
            color: themeToken.secondary_dark_blue_900,
            marginTop: '30px',
          }}
        >
          {balance && balance !== 0 ? toFixedFunc(balance, 2) + 'SAR' : '00.0 SAR'}
        </Text>
        <Text
          style={{
            fontSize: '15px',
            fontWeight: 500,
            color: themeToken.secondary_dark_blue_900,
            marginTop: '24px',
          }}
        >
          {activeBalance && activeBalance !== 0 ? toFixedFunc(activeBalance, 2) + 'SAR' : '00.0 SAR'}{' '}
          <Text
            style={{
              fontSize: '15px',
              fontWeight: 500,
              color: themeToken.gray,
            }}
          >
            Available Balance
          </Text>
        </Text>
        <div style={{ width: '50%', marginTop: '16px' }}>
          {balance !== 0 ? (
            <Progress
              percent={100}
              size="small"
              showInfo={false}
              strokeColor={{
                '0%': themeToken.secondary_500,
                '100%': themeToken.primary_500,
              }}
            />
          ) : (
            <Progress percent={0} size="small" showInfo={false} />
          )}
        </div>

        <div
          style={{
            marginTop: '60px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <StyledButton type="primary" onClick={showModal} size="small">
            Add Balance
          </StyledButton>

          <StyledModal
            title="Add Balance to Your Wallet"
            open={isModalOpen}
            closable={false}
            onOk={() => {
              updateBalance('DEPOSIT', balanceValue);
              setIsModalOpen(false);
            }}
            onCancel={handleCancel}
          >
            <>
              <Text
                style={{
                  fontSize: '15px',
                  fontWeight: 500,
                  color: themeToken.gray,
                  margin: '0px',
                }}
              >
                You are about Transfer the Balance to Your Bank Account
              </Text>
              <StyledInputNumber
                placeholder="Your Balance"
                addonAfter="SAR"
                style={{ marginTop: '36px' }}
                onChange={(value: number | null) => setBalanceValue(value!)}
                controls={false}
              />
            </>
          </StyledModal>

          <StyledButton
            type="default"
            onClick={showWithdrawModal}
            style={{
              marginTop: '16px',
              color: balance !== 0 ? themeToken.secondary_500 : '#D1D5DA',
              borderColor: themeToken.secondary_500,
            }}
            size="small"
            disabled={balance !== 0 ? false : true}
          >
            Withdraw
          </StyledButton>

          <StyledModal
            title="Withdraw Balance"
            open={isWithdrawModalOpen}
            closable={false}
            onOk={() => {
              updateBalance('WITHDRAW', balanceValue);
              setIsWithdrawModalOpen(false);
            }}
            onCancel={handleWithdrawCancel}
          >
            <>
              <Text
                style={{
                  fontSize: '15px',
                  fontWeight: 500,
                  color: themeToken.gray,
                  margin: '0px',
                }}
              >
                You are about Transfer the Balance to Your Bank Account
              </Text>
              <StyledInputNumber
                placeholder="Your Balance"
                addonAfter="SAR"
                style={{ marginTop: '36px' }}
                onChange={(value: number | null) => setBalanceValue(value!)}
                controls={false}
              />
            </>
          </StyledModal>
        </div>
      </div>
    </StyledCard>
  );
};

export default TotalBalance;

import { Tabs } from 'antd';
import themeToken from '../tokens';
import styled from 'styled-components';

const defaultTheme = {};

const globalTheme = {
  colorText: themeToken.colorPrimary,
};

export const tabsTheme = {
  ...defaultTheme,
  ...globalTheme,
};

export const StyledTabs = styled(Tabs)`
  &.ant-tabs {
    padding: 8px;
    width: 100%;

    &.ant-tabs-top .ant-tabs-card {
      .ant-tabs-tab.ant-tabs-tab-active {
        font-size: 14px;
        font-weight: 700;
        background-color: ${themeToken.colorPrimary};

        .ant-tabs-tab-btn {
          color: ${themeToken.white};
        }
      }
      .ant-tabs-tab-btn {
        color: ${themeToken.gray};
      }
    }
  }

  .ant-tabs-tab-btn {
    font-size: 13px;
    font-weight: 700;
    color: ${themeToken.secondary_dark_blue_900};
  }

  .ant-tabs-tab {
    padding-bottom: 16px;
  }

  .ant-tabs-nav {
    margin-bottom: 30px;
  }
`;

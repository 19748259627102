import { fetchDeleteRequests, fetchGetRequests } from 'infrastructure/helpers/fetchServerRequests';
import { showNotification, NotificationStatus } from 'infrastructure/helpers/showNotifications';
import { create } from 'zustand';

type IProps = {
  data: {
    company: {
      name: string;
      _id: string;
      description: string;
      portfolio: { sharePrice: number };
    };
  }[];
  current: number;
  watchlist: () => void;
  deleteWatchlist:(companyId: string) => void
};

export const useWishlistStore = create<IProps>((set) => ({
  data: [],
  current: 0,
  watchlist: () => {
    fetchGetRequests('watchlist')
      .then((response) => {
        set({
          data: response.response,
          current: response.response,
        });
      })
      .catch(() => {});
  },

  deleteWatchlist: (companyId: string) => {
    fetchDeleteRequests(`watchlist/${companyId}`)
      .then((response: { data: [] }) => {
        showNotification(NotificationStatus.Success, 'Done', 'the Company Unfollowed  Successfully');
        set({
          data: response.data,
        });
      })
      .catch(() => {});
  },
}));

import { Checkbox, Form as AntDForm } from 'antd';
import themeToken from 'infrastructure/theme/tokens';
import { Link, useNavigate } from 'react-router-dom';
import { NotificationStatus, showNotification } from 'infrastructure/helpers/showNotifications';
import { axiosApiInstance } from 'infrastructure/utils/api';
import { StyledButton } from 'infrastructure/theme/components/Button';
import { StyledInput, StyledInputPassword } from 'infrastructure/theme/components/Input';
import { useMutation } from '@tanstack/react-query';
import { StyledDatePicker } from 'infrastructure/theme/components/RangePicker';

type FormDataProps = {
  email: string;
  password: string;
  phone?: number;
  nationality: string;
  birth_date?: string;
  national_id?: string;
  phoneNumber?: number;
  dateOfBirth?: string;
  nationalId?: string;
  firstName: string;
  lastName: string;
};
const Form = () => {
  const navigate = useNavigate();
  const mutation = useMutation({
    mutationFn: (formData: FormDataProps) => {
      return axiosApiInstance.post('/auth/sign-up', formData);
    },
    onSuccess: () => {
      showNotification(NotificationStatus.Success, 'Done', 'you signed up Successfully');
      navigate('/login');
    },
    onError: (error: { response: { data: { message: string } } }) => {
      console.log({ error });
      showNotification(NotificationStatus.Error, 'Somtheing Wrong', error.response.data.message);
    },
  });

  const onFinish = (values: FormDataProps) => {
    const data: FormDataProps = {
      email: values.email,
      password: values.password,
      phoneNumber: values.phone,
      nationality: values.nationality,
      dateOfBirth: values.birth_date,
      nationalId: values.national_id,
      firstName: '',
      lastName: '',
    };
    mutation.mutate(data);
  };

  return (
    <AntDForm
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      autoComplete="off"
      style={{ marginTop: '24px ', width: '100%' }}
      onFinish={onFinish}
    >
      <AntDForm.Item
        style={{ marginBottom: '10px' }}
        name="email"
        rules={[
          {
            required: true,
            type: 'email',
          },
        ]}
      >
        <StyledInput placeholder="Email" />
      </AntDForm.Item>

      <AntDForm.Item
        style={{ marginBottom: '10px' }}
        name="phone"
        rules={[
          {
            required: true,
            message: 'Please input your phone number!',
          },
          {
            pattern: new RegExp(/^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/),
            message: 'The Phone Nmuber should be a valid saudi number ',
          },
        ]}
      >
        <StyledInput placeholder="Phone Number" />
      </AntDForm.Item>

      <AntDForm.Item
        style={{ marginBottom: '10px' }}
        name="national_id"
        rules={[
          {
            required: true,
            message: 'Please input your National Id!',
          },
          {
            pattern: new RegExp(/^[0-9]+$/),
            message: 'The National Id should be Numbers Only ',
          },
          {
            len: 14,
          },
        ]}
      >
        <StyledInput placeholder="National Id" />
      </AntDForm.Item>

      <AntDForm.Item
        style={{ marginBottom: '10px' }}
        name="nationality"
        rules={[
          {
            required: true,
            message: 'Please input your Nationality!',
          },
        ]}
      >
        <StyledInput placeholder="Nationality" />
      </AntDForm.Item>

      <AntDForm.Item name="birth_date" style={{ marginBottom: '10px' }}>
        <StyledDatePicker placeholder="Date of Birth" format={'YYYY-MM-DD'} />
      </AntDForm.Item>

      <AntDForm.Item
        style={{ marginBottom: '10px' }}
        name="password"
        rules={[
          { required: true },
          {
            type: 'string',
            min: 10,
            message: 'password must be longer than or equal to 10 characters',
          },
        ]}
      >
        <StyledInputPassword placeholder="Password" />
      </AntDForm.Item>

      <AntDForm.Item
        style={{ marginBottom: '10px' }}
        name="confirm_password"
        dependencies={['password']}
        rules={[
          { required: true, message: 'Please confirm your password!' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}
      >
        <StyledInputPassword placeholder="Confirm Password" />
      </AntDForm.Item>

      <AntDForm.Item>
        <AntDForm.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>
            Accept All{' '}
            <Link to="#" style={{ color: themeToken.secondary_500 }}>
              Terms and Policy{' '}
            </Link>{' '}
            for Rasmal.
          </Checkbox>
        </AntDForm.Item>
      </AntDForm.Item>

      <AntDForm.Item>
        <StyledButton type="primary" size="large" htmlType="submit" className="login-form-button">
          Next
        </StyledButton>
      </AntDForm.Item>
    </AntDForm>
  );
};

export default Form;

import { axiosApiInstance } from 'infrastructure/utils/api';
import { create } from 'zustand';

type ICompanies = {
  imageUrl: string;
  name: string;
  category: string;
  portfolio: { revenuePrecent: number; sharePrice: number };
  description: string;
  _id: string;
  isFollowed: boolean;
  symbol: string;
};

type IProps = {
  companies: Array<ICompanies>;
  paginationInfo: {
    totalDocs: number;
  };
  limit: number;
  loading: boolean;
  companiesList: () => void;
};

export const useCompaniesStore = create<IProps>((set) => ({
  companies: [
    {
      imageUrl: '',
      name: '',
      category: '',
      portfolio: { revenuePrecent: 0, sharePrice: 0 },
      _id: '',
      description: '',
      isFollowed: false,
      symbol: '',
    },
  ],
  paginationInfo: { totalDocs: 0 },
  limit: 1000000,
  loading: true,
  companiesList: () => {
    axiosApiInstance(`companies?limit=12`)
      .then(
        (response: {
          data: {
            docs: ICompanies[];
          };
        }) => {
          set({
            companies: response.data.docs,
            loading: false,
          });
        },
      )
      .catch(() => {});
  },
}));

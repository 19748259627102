import { Col, Row, Typography } from 'antd';
import themeToken from 'infrastructure/theme/tokens';
import LineChart from 'assets/svg/line';
import { useStocksStore } from 'infrastructure/store/stocksStore';
import { StyledCard } from 'infrastructure/theme/components/Card';

const { Title, Text } = Typography;

const StocksCard = () => {
  const [stocks] = useStocksStore((state) => [state.stocks]);

  return (
    <StyledCard className="wallet">
      <Row justify="space-between">
        <Col
          span={14}
          style={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Title
            style={{
              color: themeToken.secondary_dark_blue_900,
              fontWeight: 700,
              fontSize: '17px',
              textAlign: 'left',
              margin: '0px',
            }}
          >
            Portfolio valuation
          </Title>
          <Text
            style={{
              fontWeight: 700,
              color: themeToken.secondary_dark_blue_900,
              textAlign: 'left',
              marginTop: '24px',
            }}
          >
            {stocks?.totalMarketPrice} SAR
          </Text>
        </Col>

        <Col span={10}>
          <div style={{ marginTop: '54px' }}>
            <LineChart />
            <Text
              style={{
                color: themeToken.secondary_dark_blue_900,
                fontSize: '13px',
                fontWeight: 500,
              }}
            >
              No data Yet to Show
            </Text>
          </div>
        </Col>
      </Row>
    </StyledCard>
  );
};

export default StocksCard;

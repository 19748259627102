import { fetchGetRequests } from 'infrastructure/helpers/fetchServerRequests';
import { MomentInput } from 'moment';
import { create } from 'zustand';


type Iprops = {
  profile:{
    firstName:string;
    email:string;
    phoneNumber:number;
    dateOfBirth:MomentInput;
    role:string;
    nationality:string;
    nationalId:string;
  };
  getProfile: () => Promise<void>
}
export const useProfileStore = create<Iprops>((set) => ({
  profile: {
    firstName:'',
    email:'',
    phoneNumber:0,
    dateOfBirth:'',
    role:'',
    nationality:'',
    nationalId:''
  },
  getProfile: async () => {
    const response = await fetchGetRequests('auth/profile');
    set({
      profile: response.response,
    });
  },
}));

import { Badge, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useOrdersStore } from 'infrastructure/store/ordersStore';
import themeToken from 'infrastructure/theme/tokens';
import { useEffect } from 'react';

interface DataType {
  key: string;
  shares: string;
  cost: string;
  date: string;
  status: string;
  direction: string;
}

const TransactionsHistory = ({ symbol }: { symbol: string }) => {
  const [orders, getOrders] = useOrdersStore((state) => [state.orders, state.getOrders]);

  useEffect(() => {
    getOrders(0, symbol);
  }, []);

  const columns: ColumnsType<DataType> = [
    {
      title: '# Shares',
      dataIndex: 'sharesCount',
      key: 'shares',
      render: (text: string) => text,
    },
    {
      title: 'Price',
      dataIndex: 'sharePrice',
      key: 'Price',
    },
    {
      title: 'Date/Time',
      dataIndex: 'createdAt',
      key: 'date',
    },
    {
      title: 'Operation',
      key: 'Operation',
      dataIndex: 'direction',
      render: (_, { direction }) =>
        direction === 'BUY' ? (
          <Tag
            style={{
              backgroundColor: themeToken.success_50,
              color: themeToken.success_500,
              borderColor: themeToken.success_50,
            }}
            key={direction}
          >
            {direction}
          </Tag>
        ) : (
          <Tag
            style={{
              backgroundColor: themeToken.secondary_50,
              color: themeToken.secondary_500,
              borderColor: themeToken.secondary_50,
            }}
            key={direction}
          >
            {direction}
          </Tag>
        ),
    },
    {
      title: 'Status ',
      key: 'status',
      dataIndex: 'status',
      render: (_, { status }) =>
        status === 'COMPLETED' ? (
          <Badge color={themeToken.success_500} text={status} style={{ color: themeToken.success_500 }} />
        ) : (
          <Badge color={themeToken.primary_500} text={status} style={{ color: themeToken.primary_500 }} />
        ),
    },
  ];

  return <Table columns={columns} dataSource={orders} pagination={false} />;
};

export default TransactionsHistory;

import {  fetchGetRequests } from 'infrastructure/helpers/fetchServerRequests';
import {create} from 'zustand';


type IProps = {
    statementOfIncome:{
        annualReports:[];
        quarterlyReports:[]
    };
     getStatementOfIncome: (symbol: string) => Promise<void>
}
export const useStatementOfIncomeStore = create<IProps>((set) => ({
 statementOfIncome:{
    annualReports:[],
    quarterlyReports:[]
 },
 getStatementOfIncome:async (symbol:string) =>{
    const response = await fetchGetRequests(`statements-of-income/${symbol}`);   
    set({
        statementOfIncome:response.response
    })
    }
}));

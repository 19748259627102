import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={205} height={41} fill="none" {...props}>
    <path
      fill="#120152"
      d="M63.49 31.967V8.257h10.793c4.849 0 7.273 2.12 7.273 6.359 0 2.867-1.888 5.241-5.662 7.123L83.415 32H77.87l-7.223-10.344v-2.01c4.206-.719 6.31-2.352 6.31-4.898 0-1.748-.986-2.623-2.956-2.623h-6.027v19.842H63.49ZM87.949 32h-4.516L93.51 8.256h4.733L108.537 32h-4.732l-2.707-6.476h-7.886l1.51-3.818h4.782l-3.735-8.917L87.949 32Zm23.08-.996v-3.985c2.38.885 5.069 1.328 8.069 1.328 3.642 0 5.463-1.218 5.463-3.653 0-1.77-1.118-2.656-3.354-2.656h-3.719c-4.86 0-7.29-2.214-7.29-6.642 0-4.87 3.46-7.306 10.378-7.306 2.657 0 5.175.388 7.555 1.163v3.985c-2.38-.886-4.898-1.329-7.555-1.329-3.985 0-5.978 1.162-5.978 3.487 0 1.771.963 2.657 2.89 2.657h3.719c5.169 0 7.754 2.214 7.754 6.641 0 4.982-3.288 7.472-9.863 7.472-3 0-5.689-.387-8.069-1.162Zm21.668.996V8.256h4.566l8.053 18.016 7.887-18.016h4.4V32h-4.068V15.462L147.091 32h-3.752l-6.575-16.538V32h-4.068Zm32.328 0h-4.517l10.079-23.744h4.732L185.612 32h-4.732l-2.706-6.476h-7.887l1.511-3.818h4.782l-3.736-8.917L165.024 32Zm27.894-23.744v19.925h9.431V32h-13.831V8.256h4.4Z"
    />
    <path
      fill="#3DB8FC"
      d="m37.23 7.112-16.01 23.12a.708.708 0 0 1-1.128.048L.96 7.16A.708.708 0 0 1 1.506 6h10.322c.223 0 .433.105.567.284l6.458 8.625c.292.39.88.376 1.154-.027l5.814-8.571A.708.708 0 0 1 26.407 6h10.24c.572 0 .909.642.583 1.112Z"
    />
    <path
      fill="#8A2BE1"
      d="m14.59 31.762 15.122-21.277a1.417 1.417 0 0 1 2.235-.096l18.07 21.277c.782.92.128 2.334-1.08 2.334h-8.742c-.44 0-.855-.205-1.123-.553l-5.853-7.616a1.417 1.417 0 0 0-2.286.053l-5.227 7.509c-.265.38-.699.607-1.163.607h-8.798c-1.15 0-1.821-1.3-1.155-2.238Z"
    />
  </svg>
);
export default SvgComponent;

import { Col, Row, Space, Typography } from 'antd';
import DoughnutChart from 'components/Charts/Doughnut';
import { useStocksStore } from 'infrastructure/store/stocksStore';
import { useWalletBalanceStore } from 'infrastructure/store/walletBalance';
import { StyledCard } from 'infrastructure/theme/components/Card';
import themeToken from 'infrastructure/theme/tokens';
import { toFixedFunc } from 'infrastructure/utils/toFixedFunc';
import { styled } from 'styled-components';

const { Title, Text } = Typography;

const StyledDots = styled.span`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: ${(props) => props.color};
  display: block;
`;

const FinancialCard = () => {
  const [stocks] = useStocksStore((state) => [state.stocks]);
  const [walletBalance] = useWalletBalanceStore((state) => [state.walletBalance, state.getWalletBalance]);

  return (
    <StyledCard className="wallet">
      <Row justify="space-between">
        <Col span={14}>
          <Title
            style={{
              color: themeToken.secondary_dark_blue_900,
              fontWeight: 700,
              fontSize: '17px',
              textAlign: 'left',
              margin: '0px',
            }}
          >
            Financial position
          </Title>
          <Space style={{ marginTop: '24px', width: '95%' }}>
            <StyledDots color={themeToken.primary_500} />
            <Text>Owned units </Text>
            <Text
              style={{
                fontWeight: 400,
                fontSize: '12px',
                textAlign: 'left',
                color: themeToken.secondary_dark_blue_900,
              }}
            >
              {/* {toFixedFunc((stocks?.totalMarketPrice / (stocks?.totalMarketPrice + walletBalance?.balance)) * 100, 2)} */}
              {toFixedFunc(stocks?.totalMarketPrice / (walletBalance?.balance + stocks?.totalMarketPrice) * 100  , 2) } %
            </Text>
          </Space>
          <Space style={{ width: '95%' }}>
            <StyledDots color={themeToken.secondary_500} />
            <Text>Cash </Text>
            <Text
              style={{
                fontWeight: 400,
                fontSize: '12px',
                color: themeToken.secondary_dark_blue_900,
              }}
            >
              {/* {walletBalance?.balance > 0
                ? toFixedFunc((walletBalance?.balance / (stocks?.totalMarketPrice + walletBalance?.balance)) * 100, 2)
                : '0%'} */}
                {toFixedFunc(walletBalance?.balance / (walletBalance?.balance + stocks?.totalMarketPrice) * 100 , 2)} %
            </Text>
          </Space>
          <Space style={{ width: '95%' }}>
            <StyledDots color={themeToken.black} />
            <Text>Total </Text>
            <Text
              style={{
                fontWeight: 400,
                fontSize: '12px',
                color: themeToken.secondary_dark_blue_900,
              }}
            >
              {/* {walletBalance?.balance > 0 ? toFixedFunc(stocks?.totalMarketPrice + walletBalance?.balance, 2) : ' 00,0'} */}
              {walletBalance?.balance + stocks?.totalMarketPrice}
            </Text>
            SAR
          </Space>
        </Col>

        <Col span={8} style={{ alignSelf: 'center' }}>
          <div>
            <DoughnutChart
              data={{
                datasets: [
                  {
                    data: [stocks?.totalMarketPrice, stocks?.totalMarketPrice + walletBalance?.balance],
                    backgroundColor: [themeToken.secondary_500, themeToken.primary_500],
                    rotation: 270,
                  },
                ],
              }}
            />
          </div>
        </Col>
      </Row>
    </StyledCard>
  );
};

export default FinancialCard;

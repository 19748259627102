import { Table, Typography, Image, Tag, Dropdown, MenuProps, Form, PaginationProps, Badge } from 'antd';
import { ColumnsType } from 'antd/es/table';
import themeToken from 'infrastructure/theme/tokens';
import { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useOrdersStore } from 'infrastructure/store/ordersStore';
import { toFixedFunc } from 'infrastructure/utils/toFixedFunc';
import EyeIcon from 'assets/svg/eye';
import EditIcon from 'assets/svg/edit';
import CancelIcon from 'assets/svg/cancel';
import MoreIcon from 'assets/svg/more';
import { axiosApiInstance } from 'infrastructure/utils/api';
import { showNotification, NotificationStatus } from 'infrastructure/helpers/showNotifications';
import styled from 'styled-components';
import { StyledButton } from 'infrastructure/theme/components/Button';
import { StyledCard } from 'infrastructure/theme/components/Card';
import { StyledInputNumber } from 'infrastructure/theme/components/Input';
import { StyledModal } from 'infrastructure/theme/components/Modal';
import { StyledSelect } from 'infrastructure/theme/components/Select';

const { Title, Text } = Typography;

interface DataType {
  _id: string;
  icon: ReactElement;
  companyName: string;
  sharesCount: string;
  sharePrice: string;
  date: string;
  direction: string;
  status: string;
}

const StyledTable = styled(Table)`
  width: 100%;
  .ant-table-thead {
    .ant-table-cell {
      color: #858a9b;
      font-size: 13px;
      font-weight: 500;
      background-color: unset;
      border-bottom: unset;
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .ant-table-cell {
    color: #080022;
    font-size: 13px;
    font-weight: 700;
  }

  .ant-table-row {
    .ant-table-cell {
      border-bottom: unset;

      padding-left: 0px;
    }
  }
`;

const Orders = () => {
  const [form] = Form.useForm();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [recordData, setRecordData] = useState<{ _id: string; sharesCount: number; type: string; sharePrice: number }>({
    _id: '',
    sharesCount: 0,
    type: '',
    sharePrice: 0,
  });
  const [page, setPage] = useState(1);
  const [sharesCountToBuy, setSharesCount] = useState(0);
  const [price, setPrice] = useState(0);

  const [orders, ordersPage, totalDocs, getOrders] = useOrdersStore((state) => [
    state.orders,
    state.ordersPage,
    state.totalDocs,
    state.getOrders,
  ]);

  useEffect(() => {
    getOrders(page);
  }, [getOrders, page]);

  const [isCancelModalVisible, setIsCancelModalVisible] = useState<{
    [id: string]: boolean;
  }>({
    id: false,
  });

  const [recordId, setRecordId] = useState('');

  const items = [
    {
      key: '1',
      label: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <EyeIcon />
          <Text
            style={{
              fontSize: '13px',
              fontWeight: '500',
              color: themeToken.secondary_dark_blue_400,
              marginLeft: '8px',
            }}
          >
            View Details
          </Text>
        </div>
      ),
    },
  ];

  const handleMenuClick = (record: { _id: string; sharesCount: number; type: string; sharePrice: number }) => {
    setRecordId(record._id);
    setRecordData(record);
  };

  const handleCancelOrder = () => {
    axiosApiInstance
      .patch(`/orders/${recordId}/cancel`)
      .then(() => {
        setIsCancelModalVisible({ [recordId]: false });
        showNotification(NotificationStatus.Success, 'Done', 'Your Order Cancelled Successfully');
      })
      .catch((error: { message: { [x: number]: number } }) => {
        showNotification(NotificationStatus.Error, 'Error', error.message[0]);
      });
  };

  const handlePagination: PaginationProps['onChange'] = (value: number) => {
    setPage(value);
  };

  const pendingItems: MenuProps['items'] = [
    {
      key: '2',
      label: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
          onClick={() => setIsEditModalVisible(true)}
        >
          <EditIcon />
          <Text
            style={{
              fontSize: '13px',
              fontWeight: '500',
              color: themeToken.secondary_dark_blue_400,
              marginLeft: '8px',
            }}
          >
            Edit order
          </Text>
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            onClick={() => setIsCancelModalVisible({ [recordId]: true })}
          >
            <CancelIcon />
            <Text
              style={{
                fontSize: '13px',
                fontWeight: '500',
                color: themeToken.secondary_dark_blue_400,
                marginLeft: '8px',
              }}
            >
              Cancel order
            </Text>
          </div>
        </>
      ),
    },
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: 'Logo',
      key: '1',
      width: '10%',
      render: (record: { company: { imageUrl: string } }) => (
        <Image src={record.company.imageUrl} preview={false} style={{ width: '37px', height: '38px' }} />
      ),
    },
    {
      title: 'Company Name',
      key: '2',
      width: '20%',
      render: (record: { company: { name: string; symbol: string } }) => (
        <Text
          style={{
            fontSize: '15px',
            fontWeight: 700,
            color: themeToken.secondary_dark_blue_900,
          }}
        >
          <Link to={`/market/${record.company.symbol}`}>{record.company.name}</Link>
        </Text>
      ),
    },
    {
      title: '# Shares',
      dataIndex: 'sharesCount',
      key: '3',
      width: '10%',
      render: (sharesCount: string) => (
        <Text
          style={{
            fontSize: '13px',
            fontWeight: 500,
            color: themeToken.secondary_dark_blue_400,
          }}
        >
          {sharesCount} Stocks
        </Text>
      ),
    },
    {
      title: 'Cost',
      dataIndex: 'sharePrice',
      key: '4',
      width: '10%',
      render: (sharePrice: number) => toFixedFunc(sharePrice, 2),
    },
    {
      title: 'Date/Time',
      dataIndex: 'timestamp',
      key: '5',
      width: '10%',
      render: (timestamp: string) => (
        <Text
          style={{
            fontSize: '13px',
            fontWeight: 500,
            color: themeToken.secondary_dark_blue_400,
          }}
        >
          {timestamp.split('T')[0]}
        </Text>
      ),
    },
    {
      title: 'Transaction type',
      dataIndex: 'direction',
      key: '6',
      width: '10%',
      render: (direction: string) => (
        <Tag
          bordered={false}
          color={direction === 'BUY' ? themeToken.success_50 : themeToken.secondary_50}
          style={{
            width: '112px',
            padding: '8px',
            textAlign: 'center',
          }}
        >
          <Text
            style={{
              color: direction === 'BUY' ? themeToken.success_600 : themeToken.secondary_600,
              width: '112px',
              padding: '8px',
            }}
          >
            {' '}
            {direction}
          </Text>
        </Tag>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: '7',
      width: '10%',
      render: (status: string) => (
        <Badge
          text={status}
          color={
            status === 'DONE' ? themeToken.success_600 : status === 'PENDING' ? themeToken.gray : themeToken.primary_600
          }
          style={{
            fontSize: '13px',
            fontWeight: 500,
            color: '#858A9B',
          }}
        />
      ),
    },
    {
      title: ' ',
      key: '8',
      width: '10%',
      render: (record: { status: string; _id: string; sharesCount: number; type: string; sharePrice: number }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text
            style={{
              fontSize: '13px',
              fontWeight: '500',
              color: themeToken.gray,
            }}
          >
            View More
          </Text>
          <Dropdown
            menu={record.status === 'PENDING' ? { items: pendingItems } : { items }}
            arrow
            onOpenChange={() => handleMenuClick(record)}
            trigger={['click']}
          >
            <StyledButton className="dropdown-button">
              <MoreIcon />
            </StyledButton>
          </Dropdown>
        </div>
      ),
    },
  ];

  const handleBuyOrder = () => {
    const data = {
      type: recordData.type,
      sharesCount: sharesCountToBuy,
      sharePrice: price || recordData.sharePrice,
    };
    axiosApiInstance
      .put(`orders/${recordData._id}`, data)
      .then((res: { data: { message: string } }) => {
        showNotification(NotificationStatus.Success, 'Done', res?.data.message);
        setIsEditModalVisible(false);
      })
      .catch((err: { response: { data: { error: string; message: string } } }) => {
        setIsEditModalVisible(false);
        showNotification(NotificationStatus.Error, err.response?.data.error, err.response?.data.message);
      });
  };

  return (
    <StyledCard>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          marginBottom: '30px',
          width: '100%',
        }}
      >
        <Title
          style={{
            fontSize: '17px',
            fontWeight: 700,
            color: themeToken.secondary_dark_blue_900,
          }}
        >
          All Orders History
        </Title>

        <StyledSelect
          options={[
            { label: 'type', value: 'type' },
            { label: 'name', value: 'name' },
          ]}
          defaultValue="Filter By"
          bordered={false}
        />
      </div>

      <StyledTable
        rowKey={(record: { _id: string }) => record._id}
        columns={columns}
        dataSource={orders}
        bordered={false}
        pagination={{
          total: totalDocs,
          size: 'small',
          defaultCurrent: ordersPage | page,
          onChange: handlePagination,
        }}
      />

      <StyledModal
        title="Cancel Order"
        open={isCancelModalVisible[recordId]}
        closable={false}
        onOk={handleCancelOrder}
        onCancel={() => setIsCancelModalVisible({ [recordId]: false })}
      >
        <Text
          style={{
            fontSize: '15px',
            fontWeight: 500,
            color: themeToken.gray,
            margin: '0px',
          }}
        >
          Are You Sure You want to delete your Order?
        </Text>
      </StyledModal>

      <StyledModal
        title="Edit Order"
        open={isEditModalVisible}
        closable={false}
        footer={
          <>
            <StyledButton block onClick={() => setIsEditModalVisible(false)} size="large">
              Cancel
            </StyledButton>

            <StyledButton
              type="primary"
              block
              onClick={() => {
                form
                  .validateFields()
                  .then(() => {
                    handleBuyOrder();
                    form.resetFields();
                  })
                  .catch((info) => {
                    console.log('Validate Failed:', info);
                  });
              }}
              size="large"
            >
              Edit
            </StyledButton>
          </>
        }
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            price: recordData?.sharePrice,
            stocks: recordData?.sharesCount,
          }}
        >
          <Text
            style={{
              fontSize: '15px',
              fontWeight: 500,
              color: themeToken.gray,
              lineHeight: 1.5,
            }}
          >
            You can change your Order request or delete it from your Profile
          </Text>

          <Text
            style={{
              fontSize: '14px',
              fontWeight: 700,
              color: themeToken.black,
            }}
          >
            Choose the price option
          </Text>

          <Form.Item
            name="type"
            rules={[
              {
                required: true,
                message: 'Please select the order type ',
              },
            ]}
            style={{
              marginTop: '8px',
              marginBottom: '8px',
            }}
          >
            <StyledSelect
              defaultValue={recordData?.type === 'MARKET' ? 'Market' : 'Limit'}
              options={[
                { label: 'Market', value: 'MARKET' },
                { label: 'Limit', value: 'STOP' },
              ]}
              style={{
                width: '100%',
                height: '48px',
              }}
              bordered={true}
            />
          </Form.Item>

          <Form.Item name="price" style={{ width: '100%', marginBottom: '16px' }}>
            <StyledInputNumber
              placeholder="Price"
              onChange={(value: number | null) => setPrice(value!)}
              controls={false}
            />
          </Form.Item>

          <Text
            style={{
              fontSize: '14px',
              fontWeight: 700,
              color: themeToken.black,
            }}
          >
            Stocks quantity
          </Text>
          <Form.Item
            name="stocks"
            rules={[
              {
                required: true,
                message: 'Please input the stocks number ',
              },
            ]}
            style={{ marginTop: '8px' }}
          >
            <StyledInputNumber
              placeholder="Stocks"
              min={1}
              addonAfter={
                <StyledButton
                  type="primary"
                  onClick={() => setSharesCount(recordData?.sharesCount)}
                  className="secondary"
                >
                  Max
                </StyledButton>
              }
              controls={false}
              onChange={(value: number | null) => setSharesCount(value!)}
            />
          </Form.Item>
        </Form>
      </StyledModal>
    </StyledCard>
  );
};
export default Orders;

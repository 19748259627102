import { Typography, Image, Radio, Switch } from 'antd';
import themeToken from 'infrastructure/theme/tokens';
import ksaFlag from 'assets/svg/🦆 icon _Saudi Arabia_.svg';
import ukFlag from 'assets/svg/Frame 7.svg';
import { StyledButton } from 'infrastructure/theme/components/Button';
import { StyledCard } from 'infrastructure/theme/components/Card';

const { Title, Text } = Typography;

const Settings = () => {
  return (
    <>
      <StyledCard>
        <Title
          style={{
            fontSize: '17px',
            fontWeight: 700,
            color: themeToken.secondary_dark_blue_900,
            textAlign: 'left',
          }}
        >
          Account Languae
        </Title>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'start',
            marginTop: '40px',
            width: '100%',
          }}
        >
          <div>
            <Image src={ksaFlag} preview={false} style={{ paddingRight: '8px' }} />
            <Text
              style={{
                fontSize: '15px',
                fontWeight: 700,
                color: themeToken.secondary_dark_blue_400,
              }}
            >
              Arabic/ KSA
            </Text>
          </div>

          <Radio checked />
        </div>
        <hr style={{ border: '1px solid #F2F4F7', marginTop: '24px' }} />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'start',
            marginTop: '40px',
            width: '100%',
          }}
        >
          <div>
            <Image src={ukFlag} preview={false} style={{ paddingRight: '8px' }} />
            <Text
              style={{
                fontSize: '15px',
                fontWeight: 700,
                color: themeToken.secondary_dark_blue_400,
              }}
            >
              English/ UA
            </Text>
          </div>
          <Radio />
        </div>
      </StyledCard>

      <StyledCard>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '80%',
              textAlign: 'left',
            }}
          >
            <Text
              style={{
                fontSize: '15px',
                fontWeight: 700,
                color: themeToken.secondary_dark_blue_400,
              }}
            >
              Account Notification
            </Text>

            <Text
              style={{
                fontSize: '13px',
                fontWeight: 500,
                color: themeToken.gray,
              }}
            >
              Et inventore amet. Doloremque omnis et totam provident esse commodi rerum in. Saepe et veniam quae quia
              quidem cum ullam consectetur illo. Voluptate id ut. Fugit autem harum facere ut quia.
            </Text>
          </div>
          <Switch checked />
        </div>
      </StyledCard>

      <StyledCard>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '80%',
              textAlign: 'left',
            }}
          >
            <Text
              style={{
                fontSize: '15px',
                fontWeight: 700,
                color: themeToken.secondary_dark_blue_400,
              }}
            >
              Remove my Account
            </Text>

            <Text
              style={{
                fontSize: '13px',
                fontWeight: 500,
                color: themeToken.gray,
              }}
            >
              Et inventore amet. Doloremque omnis et totam provident esse commodi rerum in. Saepe et veniam quae quia
              quidem cum ullam consectetur illo. Voluptate id ut. Fugit autem harum facere ut quia.
            </Text>
          </div>
          <StyledButton size="small" danger>
            {' Delete Account >'}
          </StyledButton>
        </div>
      </StyledCard>
    </>
  );
};

export default Settings;

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ChartData,
  Point,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);
const options = {
  responsive: true,
  layout: {
    padding: 5,
  },
  plugins: {
    legend: {
      position: 'top' as const,
      display: false,
    },
  },
};

const LineChart = ({ data }: { data: ChartData<'line', (number | Point | null)[], unknown> }) => {
  return <Line data={data} options={options} />;
};

export default LineChart;

import { SVGProps } from 'react';
const EditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      stroke="#D1D5DA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M7.333 1.333H6C2.666 1.333 1.333 2.667 1.333 6v4c0 3.334 1.333 4.667 4.667 4.667h4c3.333 0 4.666-1.333 4.666-4.667V8.667"
    />
    <path
      stroke="#D1D5DA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M10.693 2.014 5.44 7.267c-.2.2-.4.593-.44.88l-.287 2.006c-.106.727.407 1.234 1.134 1.134L7.853 11c.28-.04.674-.24.88-.44l5.254-5.253c.906-.907 1.333-1.96 0-3.294-1.334-1.333-2.387-.906-3.294 0Z"
    />
    <path
      stroke="#D1D5DA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M9.94 2.767a4.763 4.763 0 0 0 3.294 3.293"
    />
  </svg>
);
export default EditIcon;

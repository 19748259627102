import { SVGProps, memo } from 'react';
const OrdersIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={23} fill="none" {...props}>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M21 5v2.42C21 9 20 10 18.42 10H15V3.01C15 1.9 15.91 1 17.02 1c1.09.01 2.09.45 2.81 1.17C20.55 2.9 21 3.9 21 5Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M1 6v14c0 .83.94 1.3 1.6.8l1.71-1.28c.4-.3.96-.26 1.32.1l1.66 1.67c.39.39 1.03.39 1.42 0l1.68-1.68c.35-.35.91-.39 1.3-.09l1.71 1.28c.66.49 1.6.02 1.6-.8V3c0-1.1.9-2 2-2H5C2 1 1 2.79 1 5v1Z"
    />
    <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M5.25 9h5.5" />
  </svg>
);
const Memo = memo(OrdersIcon);
export default Memo;

/** @format */

import { SVGProps, memo } from 'react';
const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={11} height={8} fill="none" {...props}>
    <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.2} d="m9.8 1-6 6-3-3" />
  </svg>
);
const Memo = memo(CheckIcon);
export default Memo;

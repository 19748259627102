import styled from 'styled-components';
import themeToken from '../tokens';
import { Card } from 'antd';

const defaultTheme = {
  colorBgContainer: themeToken.colorBgContainer,
  borderRadiusLG: themeToken.borderRadiusXl,
};

export const cardTheme = {
  ...defaultTheme,
};

export const StyledCard = styled(Card)<{ className?: string }>`
  margin: ${(props) => (props.className === 'wallet' ? '0px' : '25px 0px 25px 0px')};
  text-align: left;
  width: 100%;
  &.stipper {
    background-image: linear-gradient(89.22deg, #3db8fc -44.42%, #8a2be1 97.89%);
    margin-top: 27px;
    height: 123px;
    position: relative;
    display: block;
    visiability: visible;
  }
  &.ant-card {
    border-radius: 16px;
  }
  .ant-card-body {
    padding: ${(props) => (props.className === 'stipper' ? '0px' : '16px')};
    display: flex;
    flex-direction: ${(props) => (props.className === 'stipper' ? 'row' : 'column')};
    justify-content: ${(props) => (props.className === 'stipper' ? 'space-between' : 'flex-start')};
    align-items: ${(props) => props.className === 'companyCards' && 'flex-start'};
    height: ${(props) => props.className === 'companyCards' && '400px'};
  }

  .ant-card-grid {
    border-radius: 16px;
    padding: 17px;
    width: 100%;
    margin-top: 17px;
  }
`;

import Login from 'pages/Auth/Login';
import Register from 'pages/Auth/Register';
import CompanyProfile from 'pages/Market/profile/companyProfile';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Root from 'routes/rootRoutes';

const router = createBrowserRouter([
  { path: '/', Component: Login },
  { path: '/login', Component: Login },
  { path: '/register', Component: Register },
  { path: '/market/:symbol', Component: CompanyProfile },
  { path: '*', Component: Root },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;

import LineChart from 'components/Charts/LineChart';
import { useEvaluationsStore } from 'infrastructure/store/evaluationsStore';
import { useEffect } from 'react';
// import moment from 'moment';

const EvaluationChart = ({ symbol }: { symbol?: string }) => {
  const [evaluations, getEvaluations] = useEvaluationsStore((state) => [state.evaluations, state.getEvaluations]);

  const data: number[] = [];
  const labels: string[] = [];

  evaluations.docs.forEach((item: { stockPrice: number; timestamp: string }) => {
    data.push(item.stockPrice);

    const timestamp = Date.parse(item.timestamp);
    const date = new Date(timestamp);
    const validDate = date.toLocaleString('en-US');

    labels.push(validDate.split(',')[0]);
  });

  useEffect(() => {
    getEvaluations(symbol);
  }, [symbol]);

  return (
    <div>
      <LineChart
        data={{
          labels: labels,
          datasets: [
            {
              fill: true,
              data,
              borderColor: '#A155E7',
              backgroundColor: '#F3EAFC',
            },
          ],
        }}
      />
    </div>
  );
};

export default EvaluationChart;

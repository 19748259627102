export default {
  fontSizeHeading1: 38,
  fontSizeHeading2: 30,
  fontSizeHeading3: 24,
  fontSizeHeading4: 20,
  fontSizeHeading5: 16,
  fontSizeM: 15,
  fontSizeSM: 13,
  fontSizeXSM: 10,
  fontSizeXXSM: 8,
};

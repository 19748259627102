export default {
  black: '#252525',
  white: '#ffffff',
  colorPrimary: '#8A2BE1',
  colorLink: '#080022',
  colorLinkHover: '#080022',
  colorLinkActive: '#080022',
  primary_50: '#f3eafc',
  primary_100: '#dbbdf6',
  primary_200: '#c99df1',
  primary_300: '#b171eb',
  primary_400: '#a155e7',
  primary_500: '#8a2be1',
  primary_600: '#7e27cd',
  primary_700: '#621fa0',
  primary_800: '#4c187c',
  primary_900: '#3a125f',
  secondary_50: '#ECF8FF',
  secondary_100: '#c3e9fe',
  secondary_200: '#a6defe',
  secondary_300: '#7dcffd',
  secondary_400: '#64c6fd',
  secondary_500: '#3db8fc',
  secondary_600: '#38a7e5',
  secondary_700: '#2b83b3',
  secondary_800: '#22658b',
  secondary_900: '#1a4d6a',
  secondary_dark_blue_400: '#31255C',
  secondary_dark_blue_500: '#120152',
  secondary_dark_blue_600: '#10014b',
  secondary_dark_blue_700: '#0d013a',
  secondary_dark_blue_800: '#0a012d',
  secondary_dark_blue_900: '#080022',
  success_50: '#ebf7f1',
  success_100: '#c2e7d4',
  success_200: '#a5dcbf',
  success_300: '#7ccba1',
  success_400: '#62c18f',
  success_500: '#3bb273',
  success_600: '#36a269',
  success_700: '#2a7e52',
  success_800: '#20623f',
  success_900: '#194b30',
  error_50: '#fdeaee',
  error_100: '#f8bfc9',
  error_200: '#f4a0af',
  error_300: '#f0758a',
  error_400: '#ed5a74',
  error_500: '#e83151',
  error_600: '#d32d4a',
  error_700: '#a5233a',
  error_800: '#801b2d',
  error_900: '#611522',
  neutral_dark: '#F6F8F9',
  gray: '#858A9B',
  light_gray: '#F9FAFB',
  colorTextPlaceholder: '#797979',
  colorTextDisabled: '#D1D5DA',
  inputColorBorder: '#EBEBEB',
};

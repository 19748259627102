import React from 'react';
import { useEffect, useState } from 'react';
import { Col, Image, Row, Space, Typography } from 'antd';
import SearchIcon from 'assets/svg/searchIcon';
import { useWishlistStore } from 'infrastructure/store/wishlistStore';
import markIcon from 'assets/svg/archive-tick.svg';
import themeToken from 'infrastructure/theme/tokens';
import ExploreCompanyCard from 'components/CompanyCards';
import { useNavigate } from 'react-router-dom';
import { StyledButton } from 'infrastructure/theme/components/Button';
import { StyledCard } from 'infrastructure/theme/components/Card';
import { StyledSearchInput } from 'infrastructure/theme/components/Input';
import { StyledModal } from 'infrastructure/theme/components/Modal';

const { Title, Text } = Typography;

const Watchlist = () => {
  const [isModalVisible, setIsModalVisible] = useState<{ [id: string]: boolean }>({ id: false });
  const navigate = useNavigate();

  const [data, watchlist, deleteWatchlist] = useWishlistStore((state) => [
    state.data,
    state.watchlist,
    state.deleteWatchlist,
  ]);

  useEffect(() => {
    watchlist();
  }, []);

  const handleShowModal = (id: string) => {
    setIsModalVisible({ [id]: true });
  };

  const handleCancelModal = (id: string) => {
    setIsModalVisible({ [id]: false });
  };

  return (
    <>
      <Row
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: '28px',
          marginTop: '29px',
        }}
        gutter={16}
      >
        <StyledCard className="wallet">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Title style={{ fontSize: '18px', fontWeight: 700, color: '#1A202C' }}>Your Watch list</Title>
            <StyledSearchInput
              placeholder="Search Category, name etc."
              style={{ width: '404px' }}
              suffix={<SearchIcon />}
            />
          </div>

          <div
            style={{
              height: '200px',
              overflowY: 'scroll',
              display: 'flex',
              flexDirection: 'column',
              marginTop: '62px',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {data?.length > 0 &&
              data?.map(
                (item: {
                  company: {
                    name: string;
                    _id: string;
                    description: string;
                    portfolio: { sharePrice: number };
                  };
                }) => (
                  <React.Fragment key={item.company._id}>
                    <Row justify="space-between" align="middle" style={{ width: '100%' }}>
                      <Col>
                        <Space direction="vertical" align="start">
                          <Text
                            style={{
                              fontSize: '13px',
                              fontWeight: 700,
                              color: themeToken.secondary_dark_blue_900,
                            }}
                          >
                            {item.company.name}
                          </Text>
                          <Text
                            style={{
                              fontSize: '12px',
                              fontWeight: 500,
                              color: '#858A9B',
                            }}
                          >
                            {item.company.description.length >= 100 && item.company.description.slice(0, 100) + '...'}
                          </Text>
                          <span>
                            SharePrice:
                            <Text
                              style={{
                                fontSize: '15px',
                                fontWeight: 700,
                                color: '#252525',
                              }}
                            >
                              {item.company.portfolio.sharePrice} SAR
                            </Text>
                          </span>
                        </Space>
                      </Col>
                      <Col>
                        <Space
                          direction="horizontal"
                          align="baseline"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleShowModal(item.company._id)}
                        >
                          <Text
                            style={{
                              fontSize: '13px',
                              fontWeight: 500,
                              color: themeToken.primary_500,
                            }}
                          >
                            Delete
                          </Text>
                          <Image preview={false} src={markIcon} />
                        </Space>
                      </Col>
                      <StyledModal
                        title="Unfollow Company"
                        open={isModalVisible[item.company._id]}
                        closable={false}
                        onOk={() => {
                          deleteWatchlist(item.company._id);
                          setIsModalVisible({ [item.company._id]: false });
                        }}
                        onCancel={() => handleCancelModal(item.company._id)}
                      >
                        <Text
                          style={{
                            fontSize: '15px',
                            fontWeight: 500,
                            color: themeToken.gray,
                            margin: '0px',
                          }}
                        >
                          Are You Sure You Want UNfollow THis Company
                        </Text>
                      </StyledModal>
                    </Row>
                    <hr />
                    <hr />
                  </React.Fragment>
                ),
              )}
          </div>
        </StyledCard>
      </Row>
      <Row>
        <ExploreCompanyCard title="Explore Company">
          <StyledButton type="text" onClick={() => navigate('/market')} size="small">
            {'  View all >'}
          </StyledButton>
        </ExploreCompanyCard>
      </Row>
    </>
  );
};

export default Watchlist;

import { Col, Image, Row, TabsProps, Typography } from 'antd';
import themeToken from 'infrastructure/theme/tokens';
import { styled } from 'styled-components';
import gridIcon from 'assets/svg/gridIcon.svg';
import chartIcon from 'assets/svg/chartIcon.svg';
import PriceDepthChartTable from './priceDepthChartTable';
import Chart from './chartTab';
import { useCallback, useState } from 'react';
import BuyAndSellModalsAndForms from './BuyAndSellModalsNdForms';
import { StyledButton } from 'infrastructure/theme/components/Button';
import { StyledCard } from 'infrastructure/theme/components/Card';
import { StyledTabs } from 'infrastructure/theme/components/Tabs';

const { Title } = Typography;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PriceDepthChart = ({ symbol, sharePrice, image }: { symbol: string; sharePrice: number; image: string }) => {
  const [isBuyModalVisible, setIsBuyModalVisible] = useState(false);
  const [isSellModalVisible, setIsSellModalVisible] = useState(false);

  const showModal = (type: string) => {
    if (type === 'buy') {
      setIsBuyModalVisible(true);
    } else {
      setIsSellModalVisible(true);
    }
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <Image src={gridIcon} preview={false} />,
      children: <PriceDepthChartTable sharePrice={sharePrice} symbol={symbol} />,
    },
    {
      key: '2',
      label: <Image src={chartIcon} preview={false} />,
      children: <Chart />,
    },
  ];

  return (
    <StyledCard>
      <Wrapper>
        <Title
          style={{
            fontSize: '17px',
            fontWeight: 700,
            color: themeToken.black,
          }}
        >
          Price Depth Chart
        </Title>

        <StyledTabs items={items} tabPosition="top" />
        <Row justify="space-between">
          <Col span={12} style={{ alignSelf: 'flex-end' }}>
            <StyledButton type="primary" onClick={() => showModal('buy')} size="large" style={{ width: '94%' }}>
              Buy
            </StyledButton>
          </Col>
          <Col span={12} style={{ alignSelf: 'flex-end' }}>
            <StyledButton type="primary" onClick={() => showModal('sell')} size="large" className="secondary">
              Sell
            </StyledButton>
          </Col>
        </Row>

        <BuyAndSellModalsAndForms
          symbol={symbol}
          sharePrice={sharePrice}
          image={image}
          isBuyModalVisible={isBuyModalVisible}
          isSellModalVisible={isSellModalVisible}
          setIsBuyModalVisible={useCallback(setIsBuyModalVisible, [setIsBuyModalVisible])}
          setIsSellModalVisible={useCallback(setIsSellModalVisible, [setIsSellModalVisible])}
        />
      </Wrapper>
    </StyledCard>
  );
};
export default PriceDepthChart;

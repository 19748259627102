import React, { useEffect, useState } from 'react';
import { Layout as AntDLayout, MenuProps, Typography } from 'antd';
import MarketIcon from 'assets/svg/building';
import DashboardIcon from 'assets/svg/category';
import WalletIcon from 'assets/svg/empty-wallet';
import StocksIcon from 'assets/svg/stocks';
import OrdersIcon from 'assets/svg/orders';
import Logo from 'assets/svg/logo';
import WhishlistIcon from 'assets/svg/archive-tick';
import ProfileIcon from 'assets/svg/profile';
import SettingsIcon from 'assets/svg/settings';
import LogoutIcon from 'assets/svg/logout';
import themeToken from 'infrastructure/theme/tokens';
import { StyledMenu, StyledSider } from './style';
import AuthenticatedHeader from 'common/AuthenticatedHeader';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import storage from 'infrastructure/utils/storage';

const { Content, Header } = AntDLayout;
const { Text } = Typography;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuProps['items'] = [
  getItem(
    'MENU',
    'grp1',
    null,
    [
      getItem(<NavLink to="/dashboard">Dashboard</NavLink>, '/dashboard', <DashboardIcon />),
      getItem(<NavLink to="/market">The Market</NavLink>, '/market', <MarketIcon />),
      getItem(<NavLink to="/wallet">Wallet</NavLink>, '/wallet', <WalletIcon />),
      getItem(<NavLink to="/stocks">My Stocks</NavLink>, 'My Stocks', <StocksIcon />),
      getItem(<NavLink to="/orders">My Orders</NavLink>, 'My Orders', <OrdersIcon />),
    ],
    'group',
  ),

  { type: 'divider' },

  getItem('___________', 'grp2', null, [], 'group'),

  getItem(
    'OTHER',
    'grp3',
    null,
    [
      getItem(<NavLink to="/profile">Profile</NavLink>, '/profile', <ProfileIcon />),
      getItem(<NavLink to="/watchlist">Watchlist</NavLink>, '/watchlist', <WhishlistIcon />),
      getItem(<NavLink to="/settings">Settings</NavLink>, '/settings', <SettingsIcon />),
    ],
    'group',
  ),
];

const Layout = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [current, setCurrent] = useState(location.pathname);

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, []);

  const handleMenuClick = ({ key }: { key: string }) => {
    setCurrent(key);
  };

  return (
    <AntDLayout hasSider>
      <StyledSider
        breakpoint="lg"
        collapsedWidth="0"
        width={270}
        style={{
          // overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          // left: 0,
          // top: 0,
          // bottom: 0,
        }}
      >
        <div className="demo-logo-vertical" style={{ alignSelf: 'center' }}>
          <Logo />
        </div>
        <StyledMenu
          mode="inline"
          defaultSelectedKeys={['1']}
          items={items}
          style={{
            background: themeToken.secondary_dark_blue_900,
            paddingTop: '32px',
            width: '270px',
            fontSize: '17px',
          }}
          onClick={handleMenuClick}
          selectedKeys={[current]}
        />
        <div
          className="demo-logo-vertical"
          style={{
            paddingLeft: '47px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '5px',
            cursor: 'pointer',
          }}
          onClick={() => {
            storage.clearToken();
            navigate('/login');
          }}
        >
          <LogoutIcon />
          <Text style={{ color: ' #FFFFFF', fontSize: '17px', marginLeft: '10px' }}>Logout</Text>
        </div>
      </StyledSider>
      <AntDLayout className="site-layout" style={{ marginLeft: 270 }}>
        <Header style={{ padding: 0, background: '#F6F8F9' }}>
          <AuthenticatedHeader title={current} />
        </Header>

        <Content
          style={{
            padding: '24px 50px 0 24px',
            overflow: 'initial',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              background: '#F6F8F9',
            }}
          >
            {children}
          </div>
        </Content>
      </AntDLayout>
    </AntDLayout>
  );
};

export default Layout;

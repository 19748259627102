import { Typography } from 'antd';
import themeToken from 'infrastructure/theme/tokens';
import { styled } from 'styled-components';

const { Text } = Typography;

type Props = {
  weight?: number;
};
export const StyledText = styled(Text)<Props>`
  font-size: 13px;
  font-weight: ${(props) => (props.weight ? 700 : 500)};
  color: ${themeToken.black};
  &:hover {
    color: #8a2be1;
  }
`;

import { SVGProps } from 'react';
const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={162} height={33} fill="none" {...props}>
    <path
      fill="#fff"
      d="M47.62 25.973V6.89h8.688c3.902 0 5.853 1.706 5.853 5.119 0 2.307-1.519 4.218-4.557 5.733L63.658 26h-4.464l-5.813-8.326v-1.617c3.385-.579 5.078-1.893 5.078-3.942 0-1.408-.793-2.112-2.379-2.112h-4.85v15.97h-3.61ZM67.307 26h-3.635l8.112-19.11h3.809L83.878 26h-3.81l-2.177-5.212h-6.348l1.216-3.074h3.849L73.6 10.538 67.306 26Zm18.576-.802v-3.207c1.916.712 4.08 1.069 6.495 1.069 2.931 0 4.397-.98 4.397-2.94 0-1.426-.9-2.139-2.7-2.139h-2.993c-3.911 0-5.867-1.781-5.867-5.345 0-3.92 2.784-5.88 8.353-5.88 2.138 0 4.165.311 6.08.935V10.9c-1.915-.713-3.942-1.07-6.08-1.07-3.208 0-4.811.936-4.811 2.807 0 1.425.775 2.138 2.325 2.138h2.994c4.16 0 6.241 1.782 6.241 5.346 0 4.009-2.647 6.014-7.939 6.014-2.414 0-4.58-.312-6.495-.936Zm17.441.802V6.89h3.675l6.481 14.5 6.348-14.5h3.542V26h-3.274V12.69L114.909 26h-3.02l-5.292-13.31V26h-3.274Zm26.02 0h-3.636l8.112-19.11h3.809L145.914 26h-3.809l-2.178-5.212h-6.348l1.216-3.074h3.849l-3.007-7.176L129.343 26Zm22.451-19.11v16.036h7.591V26h-11.132V6.89h3.541Z"
    />
    <path
      fill="#3DB8FC"
      d="m30.137 5.751-12.951 17.9a.57.57 0 0 1-.893.038L.816 5.789a.57.57 0 0 1 .43-.942h8.347a.57.57 0 0 1 .45.219l5.245 6.704c.235.3.693.29.914-.022l4.72-6.66a.57.57 0 0 1 .466-.241h8.287a.57.57 0 0 1 .462.904Z"
    />
    <path
      fill="#8A2BE1"
      d="M12.143 24.706 24.017 8.295a1.14 1.14 0 0 1 1.787-.078l14.19 16.411c.639.739.114 1.887-.863 1.887H32.31a1.14 1.14 0 0 1-.899-.438l-4.602-5.883a1.14 1.14 0 0 0-1.829.044l-4.107 5.796a1.14 1.14 0 0 1-.93.48h-6.876a1.14 1.14 0 0 1-.924-1.808Z"
    />
  </svg>
);
export default Logo;

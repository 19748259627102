import { Row, Col, Tag, Typography } from 'antd';
import LineChart from 'components/Charts/LineChart';
import themeToken from 'infrastructure/theme/tokens';
const { Title, Text } = Typography;

const labels = ['Jan 10', 'Jan 11', 'Jan 12', 'Jan 13', 'Jan 14', 'Jan 15', 'Jan 16'];

const Chart = () => {
  return (
    <>
      <LineChart
        data={{
          labels,
          datasets: [
            {
              data: [7, 6, 5, 8, 3, 2, 9],
              borderColor: '#A155E7',
              backgroundColor: '#A155E7',
            },
          ],
        }}
      />

      <Row justify="space-between">
        <Col span={12}>
          <Title
            style={{
              fontSize: '15px',
              fontWeight: 500,
              color: themeToken.gray,
            }}
          >
            Shares Price
          </Title>
          <Text
            style={{
              fontSize: '15px',
              fontWeight: 700,
              color: themeToken.black,
            }}
          >
            SAR 1580.06
          </Text>
        </Col>
        <Col span={12}>
          <Title
            style={{
              fontSize: '15px',
              fontWeight: 500,
              color: themeToken.gray,
            }}
          >
            Status Today
          </Title>
          <Tag color="success" bordered={false}>
            +5.7%
          </Tag>
        </Col>
      </Row>
    </>
  );
};
export default Chart;

import { Col, Row, Image, Typography } from 'antd';
import leftSideLogo from 'assets/svg/login photo.svg';
import Form from './form';
import Logo from 'assets/svg/logoDark';
import themeToken from 'infrastructure/theme/tokens';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
const { Title, Text } = Typography;

const Register = () => {
  return (
    <div className="register">
      <Row gutter={24}>
        <Col span={12}>
          <Image src={leftSideLogo} preview={false} style={{ height: '100vh', objectFit: 'cover' }} />
        </Col>
        <Col push={1} span={7}>
          <div
            style={{
              marginTop: '70px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Link
              to="/login"
              style={{
                fontSize: '15px',
                color: themeToken.primary_500,
                fontWeight: '500',
                marginBottom: '40px',
              }}
            >
              <ArrowLeftOutlined /> back to Log in
            </Link>
            <Logo />
            <Title
              style={{
                fontSize: '36px',
                fontWeight: '700',
                color: themeToken.black,
                margin: '37px 0px 0px',
              }}
            >
              Hello There!
            </Title>
            <Text
              style={{
                fontSize: '24px',
                fontWeight: '400',
                color: themeToken.gray,
                margin: '0px',
              }}
            >
              Enter the below data to login
            </Text>
            <Form />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Register;

import { TabsProps } from 'antd';
import About from './aboutTab';
import TransactionsHistory from './transactionsHistory';
import ShareholdingInfo from './shareholdingInfo';
import BalanceSheet from './balanceSheet';
import StatementOfIncome from './statemenntOfIncome';
import { StyledCard } from 'infrastructure/theme/components/Card';
import { StyledTabs } from 'infrastructure/theme/components/Tabs';

type Props = {
  name: string;
  symbol: string;
  description: string;
};
const TabsCard = ({ company, symbol }: { company: Props; symbol: string }) => {
  const financialItems: TabsProps['items'] = [
    {
      key: '1',
      label: `Balance Sheet`,
      children: <BalanceSheet symbol={symbol} />,
    },
    {
      key: '2',
      label: `Statement of Income`,
      children: <StatementOfIncome symbol={symbol} />,
    },
    {
      key: '3',
      label: `Cash Flow`,
      children: <StatementOfIncome symbol={symbol} />,
    },
  ];
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `About`,
      children: <About companyName={company.name} description={company.description} symbol={symbol} />,
    },
    {
      key: '2',
      label: `My Transactions History`,
      children: <TransactionsHistory symbol={company.symbol} />,
    },
    {
      key: '3',
      label: `Financial `,
      children: <StyledTabs items={financialItems} type="card" />,
    },
    {
      key: '4',
      label: `Shareholding Information `,
      children: <ShareholdingInfo />,
    },
  ];

  return (
    <StyledCard>
      <StyledTabs items={items} />
    </StyledCard>
  );
};

export default TabsCard;

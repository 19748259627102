import { ReactElement, useEffect } from 'react';
import { Row, Space, Typography } from 'antd';
import themeToken from 'infrastructure/theme/tokens';
import CompanyCard from 'common/CompanyCard';
import { useIsActiveStore } from 'infrastructure/store/isActiveStore';
import { useCompaniesStore } from 'infrastructure/store/companiesStore';
import { useNavigate } from 'react-router-dom';
import { axiosApiInstance } from 'infrastructure/utils/api';
import { List } from 'antd';
import { toFixedFunc } from 'infrastructure/utils/toFixedFunc';
import { StyledCard } from 'infrastructure/theme/components/Card';

const { Title, Text } = Typography;

type Props = {
  title: string;
  description?: string;
  children: ReactElement;
};

type ICompanies = {
  imageUrl: string;
  name: string;
  category: string;
  portfolio: { revenuePrecent: number; sharePrice: number };
  description: string;
  _id: string;
  isFollowed: boolean;
  symbol: string;
};
const CompanyCards = ({ title, description, children }: Props) => {
  const navigate = useNavigate();
  const updateIsActive = useIsActiveStore((state) => state.updateIsActive);

  const [companies, companiesList] = useCompaniesStore((state) => [state.companies, state.companiesList]);

  useEffect(() => {
    companiesList();
  }, []);

  const handleAddCompanyToWishlist = (e: { stopPropagation: () => void }, companyId: string) => {
    e.stopPropagation();
    updateIsActive(companyId);
    axiosApiInstance
      .post(`watchlist/${companyId}`)
      .then(() => {})
      .catch(() => {});
  };

  return (
    <StyledCard>
      <Row
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: '30px',
          alignItems: 'flex-end',
        }}
      >
        <Space direction="vertical" align="start">
          <Title
            style={{
              color: themeToken.secondary_dark_blue_900,
              fontWeight: 700,
              fontSize: '17px',
              margin: '0px',
            }}
          >
            {title}
          </Title>
          <Text style={{ fontSize: '13px', fontWeight: 500, color: '#718096' }}>{description}</Text>
        </Space>
        {children}
      </Row>

      <List
        dataSource={companies}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 4,
          xl: 4,
          xxl: 4,
        }}
        renderItem={(item: ICompanies) => (
          <List.Item>
            <CompanyCard
              icon={item.imageUrl}
              companyName={item.name}
              category={item.category}
              revenuePrecent={item.portfolio.revenuePrecent}
              sharePrice={toFixedFunc(item.portfolio.sharePrice, 2)}
              description={item.description}
              onClick={(e) => handleAddCompanyToWishlist(e, item._id)}
              id={item._id}
              isFollowed={item.isFollowed}
              onCardClick={() => navigate(`/market/${item.symbol}`)}
            />
          </List.Item>
        )}
      />
    </StyledCard>
  );
};

export default CompanyCards;

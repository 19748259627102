import { fetchGetRequests } from 'infrastructure/helpers/fetchServerRequests';
import { create } from 'zustand';

type IProps = {
  company: {
    imageUrl: string;
    symbol: string;
    portfolio: {
      sharePrice: number;
      high: number;
      low: number;
      availableShares: number;
      close: number;
      open: number;
      tradesCount: number;
      valueTraded: number;
      tradesAverage: number;
      volumeTraded: number;
    };
    name: string;
    description: string;
  };
  getCompany: (symbol: string) => Promise<void>;
};
export const useGetCompanyStore = create<IProps>((set) => ({
  company: {
    imageUrl: '',
    symbol: '',
    portfolio: {
      sharePrice: 0,
      high: 0,
      low: 0,
      availableShares: 0,
      close: 0,
      open: 0,
      tradesCount: 0,
      valueTraded: 0,
      tradesAverage: 0,
      volumeTraded: 0,
    },
    name: '',
    description: '',
  },
  getCompany: async (symbol: string) => {
    const response = await fetchGetRequests(`companies/${symbol}`);
    set({
      company: response.response,
    });
  },
}));

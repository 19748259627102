/** @format */

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
      display: false,
    },
  },
};

const BarChart = ({ data }: { data: ChartData<'bar', (number | [number, number] | null)[], unknown> }) => {
  return <Bar data={data} options={options} />;
};

export default BarChart;

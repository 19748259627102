import { Col, Image, Row, Typography, Avatar } from 'antd';
import themeToken from 'infrastructure/theme/tokens';
import { UserOutlined } from '@ant-design/icons';
import phoneIcon from 'assets/svg/phoneIcon.svg';
import smsIcon from 'assets/svg/sms.svg';
import calenderIcon from 'assets/svg/calendar.svg';
import awardIcon from 'assets/svg/award.svg';
import globalIcon from 'assets/svg/global.svg';
import userIcon from 'assets/svg/user-square.svg';
import keyIcon from 'assets/svg/key.svg';
import visaIcon from 'assets/svg/v 1.svg';
import masterCardIcon from 'assets/svg/masterCardIcon.svg';
import { useProfileStore } from 'infrastructure/store/profileStore';
import { useEffect } from 'react';
import moment from 'moment';
import Arrow from 'assets/svg/chevron-down';
import { StyledButton } from 'infrastructure/theme/components/Button';
import { StyledCard } from 'infrastructure/theme/components/Card';

const { Title, Text } = Typography;

const Profile = () => {
  const [profile, getProfile] = useProfileStore((state) => [state.profile, state.getProfile]);

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  return (
    <>
      <StyledCard>
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Title
              style={{
                fontSize: '17px',
                fontWeight: 700,
                color: themeToken.secondary_dark_blue_900,
                margin: '0px',
              }}
            >
              Account Information
            </Title>

            <StyledButton type="text" size="large">
              Edit <Arrow style={{ alignSelf: 'center', marginLeft: '4px' }} />
            </StyledButton>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: '30px',
            }}
          >
            <Avatar shape="circle" size={84} icon={<UserOutlined />} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '20px',
                textAlign: 'start',
              }}
            >
              <Text
                style={{
                  fontSize: '24px',
                  fontWeight: 700,
                  color: themeToken.secondary_600,
                }}
              >
                {profile?.firstName}
              </Text>
              <Text
                style={{
                  fontSize: '13px',
                  fontWeight: 500,
                  color: themeToken.gray,
                }}
              >
                @{profile.email?.split('@')?.[0]}
              </Text>
            </div>
          </div>

          <Row gutter={[48, 48]} wrap style={{ marginTop: '30px' }}>
            <Col span="6" style={{ textAlign: 'left' }}>
              <Image src={phoneIcon} preview={false} style={{ paddingRight: '8px' }} />
              <span style={{ display: 'inline-grid' }}>
                <Text
                  style={{
                    fontSize: '15px',
                    fontWeight: 700,
                    color: themeToken.black,
                    paddingBottom: '8px',
                  }}
                >
                  {profile.phoneNumber}
                </Text>
                <Text
                  style={{
                    fontSize: '13px',
                    fontWeight: 500,
                    color: themeToken.gray,
                  }}
                >
                  Your Phone Number
                </Text>
              </span>
            </Col>
            <Col span="6" style={{ textAlign: 'left' }}>
              <Image src={smsIcon} preview={false} style={{ paddingRight: '8px' }} />
              <span style={{ display: 'inline-grid' }}>
                <Text
                  style={{
                    fontSize: '15px',
                    fontWeight: 700,
                    color: themeToken.black,
                    paddingBottom: '8px',
                  }}
                >
                  {profile.email}
                </Text>
                <Text
                  style={{
                    fontSize: '13px',
                    fontWeight: 500,
                    color: themeToken.gray,
                  }}
                >
                  Your Account Mail
                </Text>
              </span>
            </Col>
            <Col span="6" style={{ textAlign: 'left' }}>
              <Image src={calenderIcon} preview={false} style={{ paddingRight: '8px' }} />
              <span style={{ display: 'inline-grid' }}>
                <Text
                  style={{
                    fontSize: '15px',
                    fontWeight: 700,
                    color: themeToken.black,
                    paddingBottom: '8px',
                  }}
                >
                  {moment(profile.dateOfBirth).format('DD MMM yyyy')}
                </Text>
                <Text
                  style={{
                    fontSize: '13px',
                    fontWeight: 500,
                    color: themeToken.gray,
                  }}
                >
                  Date of Birth
                </Text>
              </span>
            </Col>
            <Col span="6" style={{ textAlign: 'left' }}>
              <Image src={awardIcon} preview={false} style={{ paddingRight: '8px' }} />
              <span style={{ display: 'inline-grid' }}>
                <Text
                  style={{
                    fontSize: '15px',
                    fontWeight: 700,
                    color: themeToken.black,
                    paddingBottom: '8px',
                  }}
                >
                  Account Status
                </Text>
                <Text
                  style={{
                    fontSize: '13px',
                    fontWeight: 500,
                    color: themeToken.gray,
                  }}
                >
                  {!profile.role || profile.role === 'regular' ? 'Investor' : profile.role}
                </Text>
              </span>
            </Col>
            <Col span="6" style={{ textAlign: 'left' }}>
              <Image src={globalIcon} preview={false} style={{ paddingRight: '8px' }} />
              <span style={{ display: 'inline-grid' }}>
                <Text
                  style={{
                    fontSize: '15px',
                    fontWeight: 700,
                    color: themeToken.black,
                    paddingBottom: '8px',
                  }}
                >
                  {profile.nationality}
                </Text>
                <Text
                  style={{
                    fontSize: '13px',
                    fontWeight: 500,
                    color: themeToken.gray,
                  }}
                >
                  Nationality
                </Text>
              </span>
            </Col>
            <Col span="6" style={{ textAlign: 'left' }}>
              <Image src={userIcon} preview={false} style={{ paddingRight: '8px' }} />
              <span style={{ display: 'inline-grid' }}>
                <Text
                  style={{
                    fontSize: '15px',
                    fontWeight: 700,
                    color: themeToken.black,
                    paddingBottom: '8px',
                  }}
                >
                  {profile.nationalId}
                </Text>
                <Text
                  style={{
                    fontSize: '13px',
                    fontWeight: 500,
                    color: themeToken.gray,
                  }}
                >
                  National Identification Number
                </Text>
              </span>
            </Col>
          </Row>

          <hr style={{ border: '1px solid #F2F4F7', marginTop: '24px' }} />

          <div
            style={{
              marginTop: '24px',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <div>
              <Image src={keyIcon} preview={false} style={{ paddingRight: '8px' }} />
              <span style={{ display: 'inline-grid' }}>
                <Text
                  style={{
                    fontSize: '15px',
                    fontWeight: 700,
                    color: themeToken.black,
                    paddingBottom: '8px',
                  }}
                >
                  *************
                </Text>
                <Text
                  style={{
                    fontSize: '13px',
                    fontWeight: 500,
                    color: themeToken.gray,
                  }}
                >
                  Password
                </Text>
              </span>
            </div>

            <StyledButton type="text" size="large">
              Change Your Password <Arrow style={{ alignSelf: 'center', marginLeft: '4px' }} />
            </StyledButton>
          </div>
        </>
      </StyledCard>

      <StyledCard>
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'baseline',
            }}
          >
            <div
              style={{
                width: '80%',
                textAlign: 'left',
              }}
            >
              <Title
                style={{
                  fontSize: '17px',
                  fontWeight: 700,
                  color: themeToken.secondary_dark_blue_900,
                  margin: '0px 0px 8px 0px',
                }}
              >
                Bank Account
              </Title>
              <Text
                style={{
                  fontSize: '13px',
                  fontWeight: 500,
                  color: themeToken.gray,
                }}
              >
                Et inventore amet. Doloremque omnis et totam provident esse commodi rerum in. Saepe et veniam quae quia
                quidem cum ullam consectetur illo. Voluptate id ut. Fugit autem harum facere ut quia.
              </Text>
            </div>
            <StyledButton type="primary" size="small">
              Add New
            </StyledButton>
          </div>

          <div
            style={{
              textAlign: 'left',
              borderRadius: '8px',
              border: '1px solid #F2F4F7',
              marginTop: '16px',
              padding: '16px',
            }}
          >
            <Image src={visaIcon} preview={false} style={{ paddingRight: '16px' }} />
            <span style={{ display: 'inline-grid' }}>
              <Text
                style={{
                  fontSize: '15px',
                  fontWeight: 700,
                  color: themeToken.black,
                  marginBottom: '8px',
                }}
              >
                IBAN ********3456
              </Text>
              <Text
                style={{
                  fontSize: '13px',
                  fontWeight: 500,
                  color: themeToken.gray,
                }}
              >
                Bank name
              </Text>
            </span>
          </div>

          <div
            style={{
              textAlign: 'left',
              borderRadius: '8px',
              border: '1px solid #F2F4F7',
              marginTop: '16px',
              padding: '16px',
            }}
          >
            <Image src={masterCardIcon} preview={false} style={{ paddingRight: '16px' }} />
            <span style={{ display: 'inline-grid' }}>
              <Text
                style={{
                  fontSize: '15px',
                  fontWeight: 700,
                  color: themeToken.black,
                  marginBottom: '8px',
                }}
              >
                IBAN ********3456
              </Text>
              <Text
                style={{
                  fontSize: '13px',
                  fontWeight: 500,
                  color: themeToken.gray,
                }}
              >
                Bank name
              </Text>
            </span>
          </div>
        </>
      </StyledCard>
    </>
  );
};

export default Profile;

import { axiosApiInstance } from "infrastructure/utils/api";

export const fetchGetRequestsWithPagination = async (
  url: string,
  limit?: number,
  page?: number,
 symbol?:string
) => {
  const response = await axiosApiInstance.get(url, {
    params: {limit: limit, page: page, 'company.symbol':symbol},
  });
  return response;
};

export const fetchGetRequests = async (url: string) => {
  let response;
  let error;
  await axiosApiInstance
    .get(url)
    .then((res:{data:[]}) => {
      response = res.data;
    })
    .catch((err:{message:string}) => {      
      error = err.message;
    });
  return { response, error };
};

export const fetchDeleteRequests = async (url: string) => {
  const response = await axiosApiInstance.delete(url);
  return response;
};

import { Image, Typography } from 'antd';
import themeToken from 'infrastructure/theme/tokens';
import { Descriptions } from 'antd';
import managerPhoto from 'assets/svg/manager.svg';
import { useEffect, useState } from 'react';
import { axiosApiInstance } from 'infrastructure/utils/api';
const { Title, Text } = Typography;
type Props = {
  companyName: string;
  description: string;
  symbol: string;
};

type TopManagers = {
  _id: number;
  name: string;
  position: string;
};

type TopInvestors = {
  name: string;
  percent: number;
};

const About = ({ companyName, description, symbol }: Props) => {
  console.log("🚀 ~ About ~ symbol:", symbol)
  const [topManagers, setTopManagers] = useState<TopManagers[]>([]);
  const [topInvestors, setTopInvestors] = useState<TopInvestors[]>([]);

  useEffect(() => {
    axiosApiInstance
      .get(`companies/${symbol}`)
      .then((res: { data: { topManagers: TopManagers[]; topInvestors: TopInvestors[] } }) => {
        setTopManagers(res.data?.topManagers || []);
        setTopInvestors(res.data?.topInvestors || []);
      })
      .catch(() => {});
  }, [symbol]);

  return (
    <>
      <Title style={{ fontSize: '15px', fontWeight: 700 }}>
        {companyName} - {symbol}
      </Title>
      <Text style={{ fontSize: '13px', fontWeight: 500, color: themeToken.gray }}>{description}</Text>
      <Descriptions
        title="Top Managers"
        layout="vertical"
        colon={false}
        style={{
          marginTop: '32px',
          width: '60%',
        }}
      >
        {topManagers?.map((item: { _id: number; name: string; position: string }) => {
          return (
            <div style={{ alignSelf: 'center' }} key={item._id}>
              <Image src={managerPhoto} preview={false} />{' '}
              <div style={{ marginLeft: '8px' }}>
                <Title
                  style={{
                    fontSize: '13px',
                    fontWeight: 700,
                    color: themeToken.secondary_dark_blue_900,
                  }}
                >
                  {item.name}
                </Title>
                <Text
                  style={{
                    fontSize: '13px',
                    fontWeight: 500,
                    color: themeToken.gray,
                  }}
                >
                  {item.position}
                </Text>
              </div>
            </div>
          );
        })}
      </Descriptions>

      <Descriptions
        title="Top Investors"
        layout="vertical"
        colon={false}
        style={{
          marginTop: '32px',
          width: '60%',
        }}
      >
        {topInvestors?.map((item: { name: string; percent: number }, index) => {
          return (
            <div style={{ alignSelf: 'center' }} key={index}>
              <Image src={managerPhoto} preview={false} />{' '}
              <div style={{ marginLeft: '8px' }}>
                <Title
                  style={{
                    fontSize: '13px',
                    fontWeight: 700,
                    color: themeToken.secondary_dark_blue_900,
                  }}
                >
                  {item.name}
                </Title>
                <Text
                  style={{
                    fontSize: '13px',
                    fontWeight: 500,
                    color: themeToken.gray,
                  }}
                >
                  {item.percent}%
                </Text>
              </div>
            </div>
          );
        })}
      </Descriptions>
    </>
  );
};

export default About;

import {create }  from 'zustand';


interface ActiveStore {
    isActive: { [x: string]: boolean; };
    updateIsActive:(key:string)=>void; }
    
export const useIsActiveStore = create<ActiveStore>((set) => ({
 isActive:{id : false},
 updateIsActive: (key: string) =>
    set((state: { isActive: { [x: string]: boolean; }; }) => ({
        isActive: {
            ...state.isActive,
            [key]: !state.isActive[key],
        },
    })),
}));

import Axios, { InternalAxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

export const axiosApiInstance = Axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

function authRequestInterceptor(config: InternalAxiosRequestConfig) {
  const token = Cookies.get('access_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers.Accept = 'application/json';
  return config;
}
axiosApiInstance.interceptors.request.use(authRequestInterceptor);
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    throw error;
  },
);

export default axiosApiInstance;



import { fetchGetRequestsWithPagination } from 'infrastructure/helpers/fetchServerRequests';
import { create } from 'zustand';

type IOrdersProps = {
  orders: [];
  ordersPage: number;
  totalDocs: number;
  getOrders: (page: number, symbol?: string) => void;
};

export const useOrdersStore = create<IOrdersProps>((set) => ({
  orders: [],
  ordersPage: 0,
  totalDocs: 0,
  getOrders: async (page: number, symbol?: string) => {
    const response:{data:{
      docs:[];
      page:number;
      totalDocs:number;
    }} = await fetchGetRequestsWithPagination('orders', 10, page, symbol);            
    set({
      orders: response.data.docs,
      ordersPage: response.data.page,
      totalDocs: response.data.totalDocs,
    });
  },
}));

import { Col, Row, Space, Statistic, Typography, Progress } from 'antd';
import { StyledCard } from 'infrastructure/theme/components/Card';
import themeToken from 'infrastructure/theme/tokens';
import { toFixedFunc } from 'infrastructure/utils/toFixedFunc';

type IData = {
  high: number;
  low: number;
  availableShares: number;
  close: number;
  open: number;
  tradesCount: number;
  valueTraded: number;
  tradesAverage: number;
  volumeTraded: number;
};
const { Title, Text } = Typography;
const SummaryCard = ({ data }: { data: IData }) => {
  return (
    <StyledCard>
      <Title
        style={{
          fontSize: '18px',
          color: themeToken.black,
          fontWeight: '700',
          marginBottom: '16px',
        }}
      >
        Summary
      </Title>
      <Space direction="vertical">
        <Text style={{ fontSize: '13px', color: themeToken.gray }}># of Available Shares</Text>
        <Text
          style={{
            fontSize: '15px',
            color: themeToken.black,
            fontWeight: '700',
          }}
        >
          {data?.availableShares} shares
        </Text>
      </Space>
      <hr style={{ border: '1px solid #EDF2F7', marginTop: '16px' }} />

      <Row gutter={16} style={{ marginBottom: '16px', justifyContent: 'space-between' }}>
        <Col span={6}>
          <Statistic
            title="low\High"
            value={`${data?.low} ${'\\'} ${data?.high} SAR`}
            valueStyle={{
              fontSize: '13px',
              fontWeight: '700',
              color: themeToken.black,
            }}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Prev Close"
            value={toFixedFunc(data?.close, 2)}
            valueStyle={{
              fontSize: '13px',
              fontWeight: '700',
              color: themeToken.black,
            }}
          />
        </Col>

        <Col span={6}>
          <Statistic
            title="open"
            value={toFixedFunc(data?.open, 2)}
            valueStyle={{
              fontSize: '13px',
              fontWeight: '700',
              color: themeToken.black,
            }}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={6}>
          <Statistic
            title="#Count of Trades"
            value={toFixedFunc(data?.tradesCount, 2)}
            valueStyle={{
              fontSize: '13px',
              fontWeight: '700',
              color: themeToken.black,
            }}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Avr. Trade Size"
            value={toFixedFunc(data?.tradesAverage, 2)}
            valueStyle={{
              fontSize: '13px',
              fontWeight: '700',
              color: themeToken.black,
            }}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Volume Traded"
            value={toFixedFunc(data?.volumeTraded, 2)}
            valueStyle={{
              fontSize: '13px',
              fontWeight: '700',
              color: themeToken.black,
            }}
          />
        </Col>

        <Col span={6}>
          <Statistic
            title="Value Traded"
            value={toFixedFunc(data?.valueTraded, 2)}
            valueStyle={{
              fontSize: '13px',
              fontWeight: '700',
              color: themeToken.black,
            }}
          />
        </Col>
      </Row>

      <hr style={{ border: '1px solid #EDF2F7', marginTop: '16px' }} />
      <Text
        style={{
          fontSize: '13px',
          color: themeToken.gray,
          fontWeight: 500,
        }}
      >
        Sentiment index
      </Text>

      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Progress percent={100} strokeColor={themeToken.primary_500} showInfo={false} />
          <Text
            style={{
              fontSize: '13px',
              color: themeToken.primary_500,
              fontWeight: 700,
            }}
          >
            12% Sell
          </Text>
        </Col>
        <Col span={16}>
          <Progress percent={100} strokeColor={themeToken.secondary_500} showInfo={false} />
          <Text
            style={{
              fontSize: '13px',
              color: themeToken.secondary_500,
              fontWeight: 700,
            }}
          >
            88% Buy
          </Text>
        </Col>
      </Row>
    </StyledCard>
  );
};

export default SummaryCard;

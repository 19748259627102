import { SVGProps } from 'react';
const CancelIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      stroke="#D1D5DA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.933 1.333H6.066c-.453 0-1.093.267-1.413.587L1.92 4.653c-.32.32-.587.96-.587 1.414v3.866c0 .454.267 1.094.587 1.414l2.733 2.733c.32.32.96.587 1.413.587h3.867c.453 0 1.093-.267 1.413-.587l2.734-2.733c.32-.32.586-.96.586-1.414V6.067c0-.454-.266-1.094-.586-1.414L11.346 1.92c-.32-.32-.96-.587-1.413-.587ZM5.667 10.333l4.667-4.666M10.334 10.333 5.667 5.667"
    />
  </svg>
);
export default CancelIcon;

import { Typography, Image, Form } from 'antd';
import { showNotification, NotificationStatus } from 'infrastructure/helpers/showNotifications';
import { useStocksStore } from 'infrastructure/store/stocksStore';
import { StyledButton } from 'infrastructure/theme/components/Button';
import { StyledInputNumber } from 'infrastructure/theme/components/Input';
import { StyledModal } from 'infrastructure/theme/components/Modal';
import { StyledSelect } from 'infrastructure/theme/components/Select';
import { axiosApiInstance } from 'infrastructure/utils/api';
import themeToken from 'infrastructure/theme/tokens';
import { useState, useEffect } from 'react';

const { Text } = Typography;

const BuyAndSellModalsAndForms = ({
  symbol,
  sharePrice,
  image,
  isBuyModalVisible,
  isSellModalVisible,
  setIsBuyModalVisible,
  setIsSellModalVisible,
}: {
  symbol: string;
  sharePrice: number;
  image: string;
  isBuyModalVisible: boolean;
  isSellModalVisible: boolean;
  setIsBuyModalVisible: (value: boolean) => void;
  setIsSellModalVisible: (value: boolean) => void;
}) => {
  const [form] = Form.useForm();
  const [price, setPrice] = useState(0);
  const [sharesCount, setSharesCount] = useState(0);
  const [isBuyConfirmationModalVisible, setIsBuyConfirmationModalVisible] = useState(false);
  const [isSellConfirmationModalVisible, setIsSellConfirmationModalVisible] = useState(false);
  const [buyValues, setBuyValues] = useState<{ type: string }>({ type: '' });
  const [sellValues, setSellValues] = useState<{ type: string }>({ type: '' });

  const [stocks, getStocks] = useStocksStore((state) => [state.stocks, state.getStocks]);

  useEffect(() => {
    getStocks();
  }, []);

  const showConfirmationModal = (type: string) => {
    if (type === 'buy') {
      setIsBuyConfirmationModalVisible(true);
      setIsBuyModalVisible(false);
    } else {
      setIsSellConfirmationModalVisible(true);
      setIsSellModalVisible(false);
    }
  };

  const handleCancel = (type: string) => {
    if (type === 'buy') {
      setIsBuyModalVisible(false);
    } else {
      setIsSellModalVisible(false);
    }
  };

  const handleBuyOrder = () => {
    const data = {
      symbol: symbol,
      direction: 'BUY',
      type: buyValues?.type,
      sharesCount: sharesCount,
      sharePrice: price || sharePrice,
    };
    axiosApiInstance
      .post('orders', data)
      .then((res: { data: { message: string } }) => {
        showNotification(NotificationStatus.Success, 'Done', res?.data.message);
        setIsBuyConfirmationModalVisible(false);
      })
      .catch((err: { response: { data: { error: string; message: string } } }) => {
        setIsBuyConfirmationModalVisible(false);
        showNotification(NotificationStatus.Error, err.response?.data.error, err.response?.data.message);
      });
  };

  const handleSellOrder = () => {
    const data = {
      symbol: symbol,
      direction: 'SELL',
      type: sellValues?.type,
      sharesCount: sharesCount,
      sharePrice: price || sharePrice,
    };
    axiosApiInstance
      .post('orders', data)
      .then((res: { data: { message: string } }) => {
        showNotification(NotificationStatus.Success, 'Done', res?.data.message);
        setIsSellConfirmationModalVisible(false);
      })
      .catch((err: { response: { data: { error: string; message: string } } }) => {
        setIsSellConfirmationModalVisible(false);

        showNotification(NotificationStatus.Error, err.response?.data.error, err.response?.data.message);
      });
  };

  useEffect(() => {
    form.setFieldsValue({
      stocks: sharesCount,
    });
  }, [sharesCount]);

  return (
    <>
      <StyledModal
        title="Buy Stocks"
        open={isBuyModalVisible}
        closable={false}
        footer={
          <>
            <StyledButton type="default" onClick={() => handleCancel('buy')} size="large" block>
              Back
            </StyledButton>

            <StyledButton
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then((values: { type: string }) => {
                    setBuyValues(values);
                    form.resetFields();
                    showConfirmationModal('buy');
                  })
                  .catch((info) => {
                    console.log('Validate Failed:', info);
                  });
              }}
              size="large"
              block
            >
              Buy
            </StyledButton>
          </>
        }
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            price: sharePrice,
          }}
        >
          <Text
            style={{
              fontSize: '15px',
              fontWeight: 500,
              color: themeToken.gray,
              lineHeight: 1.5,
            }}
          >
            You are about to trade the ${`company's`} shares; input the necessary details here to complete the
            transaction.
          </Text>

          <div style={{ marginTop: '16px' }}>
            <Text
              style={{
                fontSize: '14px',
                fontWeight: 700,
                color: themeToken.black,
              }}
            >
              Choose the price option
            </Text>

            <Form.Item
              name="type"
              rules={[
                {
                  required: true,
                  message: 'Please select the order type ',
                },
              ]}
              style={{
                marginTop: '8px',
                marginBottom: '8px',
              }}
            >
              <StyledSelect
                defaultValue="Choose Order Type"
                options={[
                  { label: 'Market', value: 'MARKET' },
                  { label: 'Limit', value: 'STOP' },
                ]}
                style={{
                  width: '100%',
                  height: '48px',
                }}
                bordered={true}
              />
            </Form.Item>
          </div>
          <Form.Item name="price" style={{ width: '100%', marginBottom: '16px' }}>
            <StyledInputNumber
              placeholder="Price"
              onChange={(value: number | null) => setPrice(value!)}
              controls={false}
            />
          </Form.Item>

          <Text
            style={{
              fontSize: '14px',
              fontWeight: 700,
              color: themeToken.black,
            }}
          >
            Stocks quantity
          </Text>
          <Form.Item
            name="stocks"
            rules={[
              {
                required: true,
                message: 'Please input the stocks number ',
              },
            ]}
            style={{ marginTop: '8px' }}
          >
            <StyledInputNumber
              placeholder="Stocks"
              min={1}
              addonAfter={
                <StyledButton type="primary" className="secondary" onClick={() => setSharesCount(stocks?.sharesCount)}>
                  Max
                </StyledButton>
              }
              controls={false}
              onChange={(value: number | null) => setSharesCount(value!)}
            />
          </Form.Item>
        </Form>
      </StyledModal>

      <StyledModal
        title="Sell Stocks"
        open={isSellModalVisible}
        closable={false}
        footer={
          <div
            style={{
              display: 'flex',
              gap: '16px',
            }}
          >
            <StyledButton type="default" onClick={() => handleCancel('sell')} size="large" block>
              Back
            </StyledButton>

            <StyledButton
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then((values: { type: string }) => {
                    setSellValues(values);
                    form.resetFields();
                    showConfirmationModal('sell');
                  })
                  .catch((info) => {
                    console.log('Validate Failed:', info);
                  });
              }}
              size="large"
              block
            >
              Sell
            </StyledButton>
          </div>
        }
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            price: sharePrice,
          }}
        >
          <Text
            style={{
              fontSize: '15px',
              fontWeight: 500,
              color: themeToken.gray,
              lineHeight: 1.5,
            }}
          >
            You are about to trade the ${`company's`} shares; input the necessary details here to complete the
            transaction.
          </Text>

          <div style={{ marginTop: '16px' }}>
            <Text
              style={{
                fontSize: '14px',
                fontWeight: 700,
                color: themeToken.black,
              }}
            >
              Choose the price option
            </Text>

            <Form.Item
              name="type"
              rules={[
                {
                  required: true,
                  message: 'Please select the order type ',
                },
              ]}
              style={{ margin: '8px 0px 8px 0px' }}
            >
              <StyledSelect
                defaultValue="Choose Order Type"
                options={[
                  { label: 'Market', value: 'MARKET' },
                  { label: 'Limit', value: 'STOP' },
                ]}
                style={{ width: '100%', marginTop: '9px', height: '48px' }}
                bordered={true}
              />
            </Form.Item>
          </div>
          <Form.Item name="price">
            <StyledInputNumber
              placeholder="Price"
              onChange={(value: number | null) => setPrice(value!)}
              controls={false}
            />
          </Form.Item>

          <Text
            style={{
              fontSize: '14px',
              fontWeight: 700,
              color: themeToken.black,
            }}
          >
            Stocks quantity
          </Text>
          <Form.Item
            name="stocks"
            rules={[
              {
                required: true,
                message: 'Please input the stocks number ',
              },
            ]}
            style={{ marginTop: '8px' }}
          >
            <StyledInputNumber
              placeholder="Stocks"
              min={1}
              addonAfter={
                <StyledButton type="primary" className="secondary" onClick={() => setSharesCount(stocks?.sharesCount)}>
                  Max
                </StyledButton>
              }
              controls={false}
              onChange={(value: number | null) => setSharesCount(value!)}
            />
          </Form.Item>
        </Form>
      </StyledModal>

      <StyledModal
        title="Confirm the Transaction"
        open={isBuyConfirmationModalVisible}
        closable={false}
        footer={
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Text
                style={{
                  fontSize: '13px',
                  fontWeight: 500,
                  color: themeToken.black,
                  textAlign: 'left',
                }}
              >
                Transaction Fees
              </Text>
              <span
                style={{
                  fontSize: '13px',
                  fontWeight: 700,
                  color: themeToken.black,
                }}
              >
                39SAR
              </span>
            </div>

            <StyledButton type="default" onClick={() => setIsBuyConfirmationModalVisible(false)}>
              Cancel
            </StyledButton>

            <StyledButton type="primary" onClick={handleBuyOrder} size="large">
              Confirm
            </StyledButton>
          </>
        }
      >
        <>
          <Text
            style={{
              fontSize: '15px',
              fontWeight: 500,
              color: themeToken.gray,
            }}
          >
            here is a summery about your Transaction. Read before Confirm the process.
          </Text>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              border: '1px solid #F2F4F7',
              borderRadius: '8px',
              padding: '24px',
              marginTop: '16px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Text
                style={{
                  fontSize: '17px',
                  fontWeight: '700',
                  color: themeToken.secondary_dark_blue_900,
                }}
              >
                Buy operation
              </Text>

              <Text
                style={{
                  fontSize: '14px',
                  fontWeight: '400',
                  color: themeToken.secondary_dark_blue_900,
                }}
              >
                Market available price
              </Text>
            </div>
            <hr
              style={{
                width: '100%',
                border: '1px solid #EDF2F7',
                marginTop: '16px',
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: '16px',
              }}
            >
              <div>
                <Image
                  src={image}
                  preview={false}
                  style={{
                    width: '37px',
                    height: '38px',
                    marginRight: '8px',
                    alignSelf: 'center',
                  }}
                />
                <Text
                  style={{
                    fontSize: '15px',
                    fontWeight: '700',
                    color: themeToken.secondary_dark_blue_900,
                  }}
                >
                  {symbol}
                </Text>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Text
                  style={{
                    fontSize: '17px',
                    fontWeight: '700',
                    color: themeToken.secondary_500,
                  }}
                >
                  {sharePrice ? sharePrice * sharesCount : price * sharesCount}
                  SAR
                </Text>
                <span>({sharesCount}stocks)</span>
              </div>
            </div>
          </div>
        </>
      </StyledModal>

      <StyledModal
        title="Confirm the Transaction"
        open={isSellConfirmationModalVisible}
        closable={false}
        footer={
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Text
                style={{
                  fontSize: '13px',
                  fontWeight: 500,
                  color: themeToken.black,
                  textAlign: 'left',
                }}
              >
                Transaction Fees
              </Text>
              <span
                style={{
                  fontSize: '13px',
                  fontWeight: 700,
                  color: themeToken.black,
                }}
              >
                39SAR
              </span>
            </div>

            <StyledButton type="default" onClick={() => setIsSellConfirmationModalVisible(false)}>
              Cancel
            </StyledButton>

            <StyledButton type="primary" onClick={handleSellOrder} size="large">
              Confirm
            </StyledButton>
          </>
        }
      >
        <>
          <Text
            style={{
              fontSize: '15px',
              fontWeight: 500,
              color: themeToken.gray,
            }}
          >
            here is a summery about your Transaction. Read before Confirm the process.
          </Text>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              border: '1px solid #F2F4F7',
              borderRadius: '8px',
              padding: '24px',
              marginTop: '16px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Text
                style={{
                  fontSize: '17px',
                  fontWeight: '700',
                  color: themeToken.secondary_dark_blue_900,
                }}
              >
                Sell operation
              </Text>

              <Text
                style={{
                  fontSize: '14px',
                  fontWeight: '400',
                  color: themeToken.secondary_dark_blue_900,
                }}
              >
                Market available price
              </Text>
            </div>
            <hr
              style={{
                width: '100%',
                border: '1px solid #EDF2F7',
                marginTop: '16px',
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: '16px',
              }}
            >
              <div>
                <Image
                  src={image}
                  preview={false}
                  style={{
                    width: '37px',
                    height: '38px',
                    marginRight: '8px',
                    alignSelf: 'center',
                  }}
                />
                <Text
                  style={{
                    fontSize: '15px',
                    fontWeight: '700',
                    color: themeToken.secondary_dark_blue_900,
                  }}
                >
                  {symbol}
                </Text>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Text
                  style={{
                    fontSize: '17px',
                    fontWeight: '700',
                    color: themeToken.secondary_500,
                  }}
                >
                  {sharePrice ? sharePrice * sharesCount : price * sharesCount}
                  SAR
                </Text>
                <span>({sharesCount}stocks)</span>
              </div>
            </div>
          </div>
        </>
      </StyledModal>
    </>
  );
};

export default BuyAndSellModalsAndForms;

/** @format */

import { fetchGetRequests } from 'infrastructure/helpers/fetchServerRequests';
import { create } from 'zustand';

type IProps = {
  transactions: {
    docs: [];
    totalPages: number;
    page: number;
  };
  getTransactions: () => Promise<void>
};

export const useTransactionstore = create<IProps>((set) => ({
  transactions: {
    docs: [],
    totalPages: 0,
    page: 0,
  },
  getTransactions: async () => {
    const response = await fetchGetRequests('balance/transactions');
    set({
      transactions: response.response,
    });
  },
}));

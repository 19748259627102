import { Tag, Typography, Image } from 'antd';
import CheckIcon from 'assets/svg/checkIcon';
import { StyledButton } from 'infrastructure/theme/components/Button';
import { useIsActiveStore } from 'infrastructure/store/isActiveStore';
import themeToken from 'infrastructure/theme/tokens';
import { StyledCard } from 'infrastructure/theme/components/Card';
const { Title, Text } = Typography;

type Props = {
  icon: string;
  companyName: string;
  sharePrice: number | string;
  description: string;
  onClick: React.MouseEventHandler<HTMLElement> | undefined;
  id: string;
  category: string;
  onCardClick?: () => void;
  isFollowed: boolean;
  revenuePrecent: number;
};
const CompanyCard = ({
  icon,
  companyName,
  sharePrice,
  description,
  onClick,
  id,
  category,
  onCardClick,
  isFollowed,
  revenuePrecent,
}: Props) => {
  const isActive = useIsActiveStore((state) => state.isActive);

  return (
    <StyledCard hoverable={true} onClick={onCardClick} className="companyCards">
      <Image src={icon} preview={false} style={{ width: '37px', height: '38px' }} />
      <Title
        style={{
          fontSize: '17px',
          fontWeight: 700,
          color: '#1A202C',
          textAlign: 'left',
          marginTop: '13px',
        }}
      >
        {companyName}{' '}
        <Tag color="success" bordered={false}>
          {revenuePrecent} %
        </Tag>
      </Title>
      <Text
        style={{
          fontSize: '15px',
          fontWeight: 700,
          color: themeToken.black,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <span style={{ fontSize: '12px', color: '#858A9B' }}>Shares Price</span>
        {sharePrice} SAR
      </Text>
      <span
        style={{
          backgroundColor: '#F3EAFC',
          borderRadius: '17px',
          fontSize: '12px',
          color: '#8A2BE1',
          padding: '10px',
          margin: '15px 0px 15px 0px',
        }}
      >
        {category?.toLowerCase()}
      </span>
      <div style={{ textAlign: 'left', height: '23%', marginBottom: '28px' }}>
        <Text style={{ textAlign: 'left', marginBottom: '28px' }}>
          {description?.length >= 100 && description?.slice(0, 100) + '...'}
        </Text>
      </div>
      {!isFollowed && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <StyledButton
            shape="circle"
            type="primary"
            icon={isActive[id] && <CheckIcon />}
            onClick={onClick}
            isActive={isActive[id]}
          >
            {!isActive[id] ? '+' : ''}
          </StyledButton>
        </div>
      )}
    </StyledCard>
  );
};

export default CompanyCard;

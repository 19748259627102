import Layout from 'components/Layout';
import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

export const ProtectedRoutes = ({
  user,
  children,
}: {
  user: boolean;
  children: ReactElement<string | React.JSXElementConstructor<unknown>>;
}) => {
  if (!user) {
    return <Navigate to="/" replace />;
  }

  return <Layout>{children}</Layout>;
};

/** @format */

import { Typography } from 'antd';
import themeToken from 'infrastructure/theme/tokens';
import { List } from 'antd';
import VirtualList from 'rc-virtual-list';
import { useEffect } from 'react';
import { StyledCard } from 'infrastructure/theme/components/Card';

const { Title, Text } = Typography;

const data = [
  {
    title: '2010',
    description:
      'Saudi Basic Industries Corporation (SABIC) invites its shareholders to attend the Extraordinary General Meeting (First Meeting) through modern technology',
    date: '12 Jan 2023, 10:56 pm',
  },
  {
    title: '2010',
    description:
      'Saudi Basic Industries Corporation (SABIC) invites its shareholders to attend the Extraordinary General Meeting (First Meeting) through modern technology',
    date: '12 Jan 2023, 10:56 pm',
  },
  {
    title: '2010',
    description:
      'Saudi Basic Industries Corporation (SABIC) invites its shareholders to attend the Extraordinary General Meeting (First Meeting) through modern technology',
    date: '12 Jan 2023, 10:56 pm',
  },
  {
    title: '2010',
    description:
      'Saudi Basic Industries Corporation (SABIC) invites its shareholders to attend the Extraordinary General Meeting (First Meeting) through modern technology',
    date: '12 Jan 2023, 10:56 pm',
  },
  {
    title: '2010',
    description:
      'Saudi Basic Industries Corporation (SABIC) invites its shareholders to attend the Extraordinary General Meeting (First Meeting) through modern technology',
    date: '12 Jan 2023, 10:56 pm',
  },
  {
    title: '2010',
    description:
      'Saudi Basic Industries Corporation (SABIC) invites its shareholders to attend the Extraordinary General Meeting (First Meeting) through modern technology',
    date: '12 Jan 2023, 10:56 pm',
  },
];

const News = () => {
  const appendData = () => {
    data.concat(data);
  };

  useEffect(() => {
    appendData();
  }, []);

  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === 400) {
      appendData();
    }
  };

  return (
    <StyledCard>
      <Title
        style={{
          fontSize: '17px',
          color: themeToken.black,
          fontWeight: '700',
          marginBottom: '16px',
        }}
      >
        News
      </Title>

      <List>
        <VirtualList data={data} height={400} itemHeight={47} itemKey=" " onScroll={onScroll}>
          {(item: { email?: string; title: string; date: string; description: string }) => (
            <>
              <List.Item key={item.email}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%',
                      marginBottom: '9px',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: '13px',
                        fontWeight: 500,
                        color: themeToken.secondary_500,
                      }}
                    >
                      {item.title}
                    </Text>
                    <Text
                      style={{
                        fontSize: '12px',
                        fontWeight: 500,
                        color: themeToken.gray,
                      }}
                    >
                      {item.date}
                    </Text>
                  </div>
                  <div>
                    <Text
                      style={{
                        fontSize: '13px',
                        fontWeight: 700,
                        color: themeToken.secondary_dark_blue_900,
                      }}
                    >
                      {item.description}
                    </Text>
                  </div>
                </div>
              </List.Item>
            </>
          )}
        </VirtualList>
      </List>
    </StyledCard>
  );
};

export default News;

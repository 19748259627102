import { useEffect } from 'react';
import { Col, Row, Typography } from 'antd';
import buzzleImage from 'assets/png/haze-puzzle 3.png';
import dartImage from 'assets/png/haze-dart 1.png';
import coinsImage from 'assets/png/haze-coins 1.png';
import themeToken from 'infrastructure/theme/tokens';
import { useStocksStore } from 'infrastructure/store/stocksStore';
import { toFixedFunc } from 'infrastructure/utils/toFixedFunc';
import StocksOverView from 'components/StocksOverviewCard';
import { StyledCard } from 'infrastructure/theme/components/Card';

const { Title, Text } = Typography;

const Stocks = () => {
  const [stocks, getStocks] = useStocksStore((state) => [state.stocks, state.getStocks]);

  useEffect(() => {
    getStocks();
  }, [getStocks]);

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: '25px' }}>
        {[
          {
            title: '#Company You Invest In',
            image: buzzleImage,
            data: stocks ? stocks.companiesCount + 'Company' : '0 Company',
          },
          {
            title: '#Shares Purshased',
            image: dartImage,
            data: stocks ? stocks.sharesCount + '' + 'shares' : '0 shares',
          },
          {
            title: '#Shares Cost',
            image: coinsImage,
            data: stocks ? stocks.sharesCost + '' + 'SAR' : '0  SAR',
          },
          {
            title: 'Shares Market Units',
            image: null,
            data: stocks ? toFixedFunc(stocks.totalMarketPrice, 3) + '' + 'SAR' : '0 SAR',
          },
        ].map((item, index) => (
          <Col span={6} className="gutter-row" key={index}>
            <StyledCard
              className="wallet"
              style={{
                backgroundImage: `url(${item.image})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right bottom',
              }}
            >
              <>
                <Title
                  style={{
                    fontSize: '12px',
                    fontWeight: 500,
                    color: themeToken.gray,
                  }}
                >
                  {item.title}
                </Title>
                <Text
                  style={{
                    fontSize: '19px',
                    fontWeight: 700,
                    color: themeToken.secondary_dark_blue_900,
                  }}
                >
                  {item.data}
                </Text>
              </>
            </StyledCard>
          </Col>
        ))}
      </Row>
      <Row>
        <StocksOverView />
      </Row>
    </>
  );
};

export default Stocks;

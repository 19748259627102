import { Col, Row, Typography } from 'antd';
import BarChart from 'components/Charts/BarChart';
import { StyledCard } from 'infrastructure/theme/components/Card';
import { StyledSelect } from 'infrastructure/theme/components/Select';
import themeToken from 'infrastructure/theme/tokens';
const { Title, Text } = Typography;

const OverallBalances = () => {
  return (
    <StyledCard className="wallet">
      <Row
        justify="space-between"
        style={{
          width: '100%',
        }}
      >
        <Title
          style={{
            fontSize: '17px',
            fontWeight: 700,
            color: themeToken.secondary_dark_blue_900,
            margin: '0px',
          }}
        >
          Overall
        </Title>
        <StyledSelect
          options={[{ label: 'With Months', value: 'month' }]}
          defaultValue="With Months"
          bordered={false}
        />
      </Row>
      <Row gutter={[16, 16]} style={{ width: '100%' }}>
        <Col span={12}>
          <StyledCard.Grid>
            <Row justify="space-between">
              <div
                style={{
                  alignItems: 'start',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Title
                  style={{
                    fontSize: '13px',
                    fontWeight: 500,
                    color: themeToken.gray,
                    margin: '0px',
                  }}
                >
                  Withdraw Transitions
                </Title>
                <Text
                  style={{
                    fontSize: '21px',
                    fontWeight: 700,
                    color: themeToken.secondary_dark_blue_900,
                    marginTop: '16px',
                  }}
                >
                  00.0 SAR
                </Text>
              </div>

              <div style={{ width: '30%', marginTop: '60px' }}>
                <BarChart
                  data={{
                    labels: [0],
                    datasets: [
                      {
                        data: [0],
                        borderColor: themeToken.secondary_100,
                        backgroundColor: themeToken.secondary_100,
                        maxBarThickness: 30,
                      },
                    ],
                  }}
                />
              </div>
            </Row>
          </StyledCard.Grid>

          <StyledCard.Grid>
            <Row justify="space-between">
              <div
                style={{
                  alignItems: 'start',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Title
                  style={{
                    fontSize: '13px',
                    fontWeight: 500,
                    color: themeToken.gray,
                    margin: '0px',
                  }}
                >
                  Top up Transitions
                </Title>
                <Text
                  style={{
                    fontSize: '21px',
                    fontWeight: 700,
                    color: themeToken.secondary_dark_blue_900,
                    marginTop: '16px',
                  }}
                >
                  00.0 SAR
                </Text>
              </div>
              <div style={{ width: '30%', marginTop: '60px' }}>
                <BarChart
                  data={{
                    labels: [0],
                    datasets: [
                      {
                        data: [0],
                        borderColor: themeToken.secondary_100,
                        backgroundColor: themeToken.secondary_100,
                        maxBarThickness: 30,
                      },
                    ],
                  }}
                />
              </div>
            </Row>
          </StyledCard.Grid>
        </Col>

        <Col span={12}>
          <StyledCard.Grid>
            <Row justify="space-between">
              <div
                style={{
                  alignItems: 'start',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Title
                  style={{
                    fontSize: '13px',
                    fontWeight: 500,
                    color: themeToken.gray,
                    margin: '0px',
                  }}
                >
                  Buy Transitions
                </Title>
                <Text
                  style={{
                    fontSize: '21px',
                    fontWeight: 700,
                    color: themeToken.secondary_dark_blue_900,
                    marginTop: '16px',
                  }}
                >
                  00.0 SAR
                </Text>
              </div>
              <div style={{ width: '30%', marginTop: '60px' }}>
                <BarChart
                  data={{
                    labels: [0],
                    datasets: [
                      {
                        data: [0],
                        borderColor: themeToken.secondary_100,
                        backgroundColor: themeToken.secondary_100,
                        maxBarThickness: 30,
                      },
                    ],
                  }}
                />
              </div>
            </Row>
          </StyledCard.Grid>

          <StyledCard.Grid>
            <Row justify="space-between">
              <div
                style={{
                  alignItems: 'start',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Title
                  style={{
                    fontSize: '13px',
                    fontWeight: 500,
                    color: themeToken.gray,
                    margin: '0px',
                  }}
                >
                  Sell Transitions
                </Title>
                <Text
                  style={{
                    fontSize: '21px',
                    fontWeight: 700,
                    color: themeToken.secondary_dark_blue_900,
                    marginTop: '16px',
                  }}
                >
                  00.0 SAR
                </Text>
              </div>
              <div style={{ width: '30%', marginTop: '60px' }}>
                <BarChart
                  data={{
                    labels: [0],
                    datasets: [
                      {
                        data: [0],
                        borderColor: themeToken.secondary_100,
                        backgroundColor: themeToken.secondary_100,
                        maxBarThickness: 30,
                      },
                    ],
                  }}
                />
              </div>
            </Row>
          </StyledCard.Grid>
        </Col>
      </Row>
    </StyledCard>
  );
};

export default OverallBalances;

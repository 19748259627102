import { SVGProps, memo } from 'react';
const NotificationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={38} height={38} fill="none" {...props}>
    <rect width={38} height={38} fill="#8A2BE1" rx={19} />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeMiterlimit={10}
      d="M19.017 11.425c-2.759 0-5 2.242-5 5v2.408c0 .509-.217 1.284-.475 1.717l-.959 1.592c-.591.983-.183 2.075.9 2.441 3.592 1.2 7.467 1.2 11.059 0a1.668 1.668 0 0 0 .9-2.441l-.959-1.592c-.25-.433-.466-1.208-.466-1.717v-2.408c0-2.75-2.25-5-5-5Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M20.558 11.667a5.629 5.629 0 0 0-3.083 0 1.654 1.654 0 0 1 1.542-1.05c.7 0 1.3.433 1.541 1.05Z"
    />
    <path
      stroke="#fff"
      strokeMiterlimit={10}
      d="M21.517 24.883c0 1.375-1.125 2.5-2.5 2.5a2.509 2.509 0 0 1-1.767-.733 2.509 2.509 0 0 1-.733-1.767"
    />
  </svg>
);
const Memo = memo(NotificationIcon);
export default Memo;

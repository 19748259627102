import { useEffect } from 'react';
import { Row, Col, Typography, Image } from 'antd';
import EvaluationsCard from 'components/EvaluationsCard';
import { useGetCompanyStore } from 'infrastructure/store/getCompanyById';
import { useNavigate, useParams } from 'react-router-dom';
import addToFav from 'assets/svg/add to fav.svg';
import { styled } from 'styled-components';
import SummaryCard from 'components/CompanyProfile/summaryCard';
import TabsCard from 'components/CompanyProfile/tabsCard';
import PriceDepthChart from 'components/CompanyProfile/priceDepthChart';
import MyPosition from 'components/CompanyProfile/myPosition';
import TradeUpdates from 'components/CompanyProfile/tradeUpdates';
import News from 'components/CompanyProfile/news';
import Arrow from 'assets/svg/chevron-down';
import RelatedCompanies from 'components/CompanyProfile/relatedCompanies';
import Layout from 'components/Layout';
import { StyledButton } from 'infrastructure/theme/components/Button';

const { Text } = Typography;

const EvaluationCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  justify-content: space-between;
`;

const CompanySymbolWithIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const CompanyProfile = () => {
  const { symbol } = useParams();
  const navigate = useNavigate();
  const [company, getCompany] = useGetCompanyStore((state) => [state.company, state.getCompany]);

  useEffect(() => {
    getCompany(symbol!);
  }, []);

  return (
    <Layout>
      <div style={{ width: '100%', marginTop: '29px' }}>
        <Row justify="space-around" gutter={[24, 24]}>
          <Col span={16}>
            <EvaluationCardHeader>
              <CompanySymbolWithIconWrapper>
                <Image
                  src={company.imageUrl}
                  preview={false}
                  style={{
                    width: '37px',
                    height: '38px',
                  }}
                />
                <Text
                  style={{
                    fontSize: '38px',
                    color: '#080022',
                    marginLeft: '8px',
                  }}
                >
                  {company.symbol}
                </Text>
              </CompanySymbolWithIconWrapper>
              <Image src={addToFav} preview={false} />
            </EvaluationCardHeader>
            <EvaluationsCard />
            <TabsCard company={company} symbol={symbol!} />
            <News />
            <RelatedCompanies symbol={symbol!} title="Related Company">
              <StyledButton type="text" onClick={() => navigate('/market')} banner size="small" icon=" View All">
                <Arrow />
              </StyledButton>
            </RelatedCompanies>
          </Col>
          <Col span={8}>
            <SummaryCard data={company.portfolio} />
            <PriceDepthChart symbol={symbol!} sharePrice={company?.portfolio?.sharePrice} image={company?.imageUrl} />
            <MyPosition symbol={symbol!} />
            <TradeUpdates symbol={symbol!} />
          </Col>
        </Row>
      </div>
    </Layout>
  );
};
export default CompanyProfile;

import { Row, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import RangePicker from 'common/RangePicker';
import { useTransactionstore } from 'infrastructure/store/transactionsStore';
import { StyledCard } from 'infrastructure/theme/components/Card';
import themeToken from 'infrastructure/theme/tokens';
import { toFixedFunc } from 'infrastructure/utils/toFixedFunc';
import { useEffect } from 'react';
const { Title } = Typography;

interface DataType {
  id?: string;
  operation?: string;
  companyName?: string;
  date?: string;
  amount?: string;
}

const Transactions = () => {
  const [transactions, getTransactions] = useTransactionstore((state) => [state.transactions, state.getTransactions]);

  useEffect(() => {
    getTransactions();
  }, []);

  const columns: ColumnsType<DataType> = [
    {
      title: 'ID',
      dataIndex: '_id',
      key: 'id',
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      key: 'operation',
    },
    {
      title: 'Direction',
      dataIndex: 'direction',
      key: 'direction',
    },
    {
      title: 'Company Name',
      key: 'companyName',
      render: (record: { company: { name: string } }) => record?.company?.name,
    },
    {
      title: 'Date/Time stamp',
      dataIndex: 'timestamp',
      key: 'date',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: number) => toFixedFunc(amount, 2),
    },
  ];

  return (
    <StyledCard>
      <Row justify="space-between" style={{ width: '100%' }}>
        <Title
          style={{
            fontSize: '17px',
            fontWeight: 700,
            color: themeToken.secondary_dark_blue_900,
            margin: '0px',
          }}
        >
          Transactions
        </Title>

        <RangePicker />
      </Row>
      <Table
        dataSource={transactions.docs}
        columns={columns}
        style={{ marginTop: '30px', width: '100%' }}
        // pagination={{
        //   total: transactions.totalPages,
        //   defaultCurrent: transactions.page,
        // }}
      />
    </StyledCard>
  );
};

export default Transactions;

import styled from 'styled-components';
import themeToken from '../tokens';
import { Button } from 'antd';

const defaultTheme = {
  defaultBg: themeToken.white,
  paddingInline: themeToken.paddingXL,
  paddingContentHorizontalSM: themeToken.paddingContentHorizontalSM,
  paddingContentVertical: themeToken.paddingContentVertical,
  borderRadius: themeToken.borderRadius,
  paddingLG: themeToken.paddingLG,
  colorTextBase: themeToken.secondary_400,
  colorInfoTextHover: themeToken.secondary_600,
  lineType: themeToken.lineType,
  lineWidth: themeToken.lineWidth,
  colorBorder: themeToken.secondary_100,
  colorInfoBorderHover: themeToken.secondary_300,
  colorTextDisabled: themeToken.colorTextDisabled,
  fontSize: themeToken.fontSizeM,
  fontSizeSM: themeToken.fontSizeSM,
  colorBgBanner: themeToken.primary_800,
};

const primaryVariantTheme = {
  colorPrimaryBg: themeToken.colorPrimary,
  colorPrimaryText: themeToken.white,
};

const textVariantTheme = {
  colorTextTertiary: themeToken.primary_500,
};

const linkVariantTheme = {
  colorLink: themeToken.primary_500,
  colorLinkHover: themeToken.primary_700,
};

export const buttonTheme = {
  ...defaultTheme,
  ...primaryVariantTheme,
  ...textVariantTheme,
  ...linkVariantTheme,
};

export const StyledButton = styled(Button)<{ isActive?: boolean; banner?: boolean }>`
  &.ant-btn-circle {
    background-color: ${(props) => props.isActive && themeToken.success_500};
    &.ant-btn-primary:hover {
      background-color: ${(props) => (props.isActive ? themeToken.success_500 : themeToken.primary_700)}!important;
    }
  }

  &.ant-btn-primary.ant-btn-sm {
    padding: 12px 24px 29px 24px;
    height: ${themeToken.controlHeightLG}px;
    font-size: ${themeToken.fontSizeSM}px;
    font-weight: 500;
    border-radius: ${themeToken.borderRadius}px;
    background-color: ${(props) => props.banner && themeToken.primary_800};
    color: ${(props) => props.banner && themeToken.white};
    border-color: ${(props) => props.banner && themeToken.primary_800};
    position: ${(props) => props.banner && 'absolute'};
    right: ${(props) => props.banner && '25px'};
    top: ${(props) => props.banner && '60px'};
    &:hover {
      background-color: ${(props) => (props.banner ? themeToken.primary_800 : themeToken.primary_400)};
    }
  }

  &.ant-btn-primary.ant-btn-lg {
    height: ${themeToken.controlHeightXL}px;
    font-size: ${themeToken.fontSizeM}px;

    &:hover {
      background-color: ${themeToken.colorPrimary};
    }
  }

  &.ant-btn-default {
    padding: 12px 24px 29px 24px;
    height: ${themeToken.controlHeightLG}px;
    font-size: ${themeToken.fontSizeSM}px;
    font-weight: 500;
    border-radius: ${themeToken.borderRadius}px;
    background-color: ${(props) => props.banner && themeToken.primary_800};
    color: ${(props) => props.banner && themeToken.white};
    border-color: ${(props) => props.banner && themeToken.primary_800};
    position: ${(props) => props.banner && 'absolute'};
    right: ${(props) => props.banner && '25px'};
    top: ${(props) => props.banner && '60px'};
    background-color: ${themeToken.white};
    color: ${themeToken.black};
    border-color: ${themeToken.colorTextDisabled};
    &:hover {
      background-color: unset;
    }
  }

  &.ant-btn-text {
    background-color: ${themeToken.light_gray};
    color: ${themeToken.gray};
    border-color: ${themeToken.light_gray};
    font-size: 12px;
  }

  &.secondary {
    width: 94%;
    background-color: ${themeToken.secondary_500};
    color: ${themeToken.white};

    &:hover {
      background-color: ${themeToken.secondary_500}!important;
    }
  }

  &.dropdown-button {
    width: 32px;
    height: 32px;
    background-color: #f2f4f7;
    margin-left: 7px;
    padding: 8px;
  }

  &.ant-btn-default.ant-btn-dangerous {
    background-color: ${themeToken.error_50};
    color: ${themeToken.error_600};
    border-color: ${themeToken.error_50};
  }

  &.login-form-button {
    width: 100%;
  }
`;

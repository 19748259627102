import { useBalanceSheetStore } from 'infrastructure/store/balanceSheetStore';
import { StyledSelect } from 'infrastructure/theme/components/Select';
import { useEffect, useState } from 'react';
import { styled } from 'styled-components';

const StyledTable = styled.table`
  width: 100%;
  text-align: left;
  overflow-y: scroll;
  overflow-x: scroll;
  overflow: auto;

  height: 600px;
  display: inline-block;
`;

const StyledTh = styled.th`
  font-size: 13px;
  font-weight: 500;
  color: #858a9b;
`;

const StyledFixedTd = styled.td`
  font-size: 13px;
  font-weight: 500;
  color: #31255c;
  border-bottom: 1px solid #f2f4f7;
  padding: 10px 0px 10px 0px;
`;

const StyledTd = styled.td`
  font-size: 13px;
  font-weight: 700;
  color: #080022;
  border-bottom: 1px solid #f2f4f7;
  padding: 10px 0px 10px 0px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const BalanceSheet = ({ symbol }: { symbol: string }) => {
  const [balanceSheets, getBalanceSheet] = useBalanceSheetStore((state) => [
    state.balanceSheets,
    state.getBalanceSheet,
  ]);

  const [value, setValue] = useState('annual');

  useEffect(() => {
    getBalanceSheet(symbol);
  }, []);

  const handleOnChange = (key: string) => {
    setValue(key);
  };

  return (
    <Wrapper>
      <StyledSelect
        defaultValue="Annually"
        options={[
          { value: 'annual', label: 'Anuual' },
          { value: 'quarter', label: 'Quarter' },
        ]}
        onChange={handleOnChange}
        style={{ marginBottom: '30px' }}
        bordered={false}
      />

      {value === 'annual' ? (
        <StyledTable style={{ display: 'table' }}>
          <tr>
            <th>Balance Sheet</th>
            {balanceSheets?.annualReports?.map((item: { fiscalDateEnding: string; _id: string }) => {
              return <StyledTh key={item._id}>{item.fiscalDateEnding.split('T00:00:00.000Z')}</StyledTh>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>accumulatedDepreciationAmortizationPPE</StyledFixedTd>
            {balanceSheets?.annualReports?.map(
              (item: { accumulatedDepreciationAmortizationPPE: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.accumulatedDepreciationAmortizationPPE}</StyledTd>;
              },
            )}
          </tr>

          <tr>
            <StyledFixedTd>capitalLeaseObligations</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { capitalLeaseObligations: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.capitalLeaseObligations}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>cashAndCashEquivalentsAtCarryingValue</StyledFixedTd>
            {balanceSheets?.annualReports?.map(
              (item: { cashAndCashEquivalentsAtCarryingValue: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.cashAndCashEquivalentsAtCarryingValue}</StyledTd>;
              },
            )}
          </tr>

          <tr>
            <StyledFixedTd>cashAndShortTermInvestments</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { cashAndShortTermInvestments: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.cashAndShortTermInvestments}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>commonStock</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { commonStock: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.commonStock}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>commonStockSharesOutstanding</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { commonStockSharesOutstanding: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.commonStockSharesOutstanding}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>currentAccountsPayable</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { currentAccountsPayable: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.currentAccountsPayable}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>currentDebt</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { currentDebt: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.currentDebt}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>currentLongTermDebt</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { currentLongTermDebt: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.currentLongTermDebt}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>currentNetReceivables</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { currentNetReceivables: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.currentNetReceivables}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>deferredRevenue</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { deferredRevenue: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.deferredRevenue}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>goodwill</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { goodwill: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.goodwill}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>intangibleAssets</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { intangibleAssets: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.intangibleAssets}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>intangibleAssetsExcludingGoodwill</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { intangibleAssetsExcludingGoodwill: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.intangibleAssetsExcludingGoodwill}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>inventory</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { inventory: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.inventory}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>investments</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { investments: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.investments}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>longTermDebt</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { longTermDebt: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.longTermDebt}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>longTermDebtNoncurrent</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { longTermDebtNoncurrent: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.longTermDebtNoncurrent}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>longTermInvestments</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { longTermInvestments: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.longTermInvestments}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>otherCurrentAssets</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { otherCurrentAssets: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.otherCurrentAssets}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>otherCurrentLiabilities</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { otherCurrentLiabilities: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.otherCurrentLiabilities}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>otherNonCurrentAssets</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { otherNonCurrentAssets: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.otherNonCurrentAssets}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>otherNonCurrentLiabilities</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { otherNonCurrentLiabilities: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.otherNonCurrentLiabilities}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>propertyPlantEquipment</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { propertyPlantEquipment: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.propertyPlantEquipment}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>retainedEarnings</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { retainedEarnings: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.retainedEarnings}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>shortLongTermDebtTotal</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { shortLongTermDebtTotal: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.shortLongTermDebtTotal}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>shortTermDebt</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { shortTermDebt: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.shortTermDebt}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>shortTermInvestments</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { shortTermInvestments: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.shortTermInvestments}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>totalAssets</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { totalAssets: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.totalAssets}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>totalCurrentAssets</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { totalCurrentAssets: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.totalCurrentAssets}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>totalCurrentLiabilities</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { totalCurrentLiabilities: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.totalCurrentLiabilities}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>totalLiabilities</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { totalLiabilities: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.totalLiabilities}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>totalNonCurrentAssets</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { totalNonCurrentAssets: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.totalNonCurrentAssets}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>totalNonCurrentLiabilities</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { totalNonCurrentLiabilities: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.totalNonCurrentLiabilities}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>totalShareholderEquity</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { totalShareholderEquity: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.totalShareholderEquity}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>treasuryStock</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { treasuryStock: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.treasuryStock}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>reportedCurrency</StyledFixedTd>
            {balanceSheets?.annualReports?.map((item: { reportedCurrency: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.reportedCurrency}</StyledTd>;
            })}
          </tr>
        </StyledTable>
      ) : (
        <StyledTable style={{ display: 'table' }}>
          <tr>
            <th>Balance Sheet</th>
            {balanceSheets?.quarterlyReports?.slice(0, 5).map((item: { fiscalDateEnding: string; _id: string }) => {
              return <StyledTh key={item._id}>{item.fiscalDateEnding.split('T00:00:00.000Z')}</StyledTh>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>accumulatedDepreciationAmortizationPPE</StyledFixedTd>
            {balanceSheets?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { accumulatedDepreciationAmortizationPPE: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.accumulatedDepreciationAmortizationPPE}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>capitalLeaseObligations</StyledFixedTd>
            {balanceSheets?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { capitalLeaseObligations: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.capitalLeaseObligations}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>cashAndCashEquivalentsAtCarryingValue</StyledFixedTd>
            {balanceSheets?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { cashAndCashEquivalentsAtCarryingValue: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.cashAndCashEquivalentsAtCarryingValue}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>cashAndShortTermInvestments</StyledFixedTd>
            {balanceSheets?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { cashAndShortTermInvestments: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.cashAndShortTermInvestments}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>commonStock</StyledFixedTd>
            {balanceSheets?.quarterlyReports?.slice(0, 5).map((item: { commonStock: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.commonStock}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>commonStockSharesOutstanding</StyledFixedTd>
            {balanceSheets?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { commonStockSharesOutstanding: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.commonStockSharesOutstanding}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>currentAccountsPayable</StyledFixedTd>
            {balanceSheets?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { currentAccountsPayable: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.currentAccountsPayable}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>currentDebt</StyledFixedTd>
            {balanceSheets?.quarterlyReports?.slice(0, 5).map((item: { currentDebt: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.currentDebt}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>currentLongTermDebt</StyledFixedTd>
            {balanceSheets?.quarterlyReports?.slice(0, 5).map((item: { currentLongTermDebt: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.currentLongTermDebt}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>currentNetReceivables</StyledFixedTd>
            {balanceSheets?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { currentNetReceivables: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.currentNetReceivables}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>deferredRevenue</StyledFixedTd>
            {balanceSheets?.quarterlyReports?.slice(0, 5).map((item: { deferredRevenue: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.deferredRevenue}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>goodwill</StyledFixedTd>
            {balanceSheets?.quarterlyReports?.slice(0, 5).map((item: { goodwill: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.goodwill}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>intangibleAssets</StyledFixedTd>
            {balanceSheets?.quarterlyReports?.slice(0, 5).map((item: { intangibleAssets: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.intangibleAssets}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>intangibleAssetsExcludingGoodwill</StyledFixedTd>
            {balanceSheets?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { intangibleAssetsExcludingGoodwill: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.intangibleAssetsExcludingGoodwill}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>inventory</StyledFixedTd>
            {balanceSheets?.quarterlyReports?.slice(0, 5).map((item: { inventory: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.inventory}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>investments</StyledFixedTd>
            {balanceSheets?.quarterlyReports?.slice(0, 5).map((item: { investments: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.investments}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>longTermDebt</StyledFixedTd>
            {balanceSheets?.quarterlyReports?.slice(0, 5).map((item: { longTermDebt: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.longTermDebt}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>longTermDebtNoncurrent</StyledFixedTd>
            {balanceSheets?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { longTermDebtNoncurrent: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.longTermDebtNoncurrent}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>longTermInvestments</StyledFixedTd>
            {balanceSheets?.quarterlyReports?.slice(0, 5).map((item: { longTermInvestments: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.longTermInvestments}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>otherCurrentAssets</StyledFixedTd>
            {balanceSheets?.quarterlyReports?.slice(0, 5).map((item: { otherCurrentAssets: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.otherCurrentAssets}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>otherCurrentLiabilities</StyledFixedTd>
            {balanceSheets?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { otherCurrentLiabilities: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.otherCurrentLiabilities}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>otherNonCurrentAssets</StyledFixedTd>
            {balanceSheets?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { otherNonCurrentAssets: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.otherNonCurrentAssets}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>otherNonCurrentLiabilities</StyledFixedTd>
            {balanceSheets?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { otherNonCurrentLiabilities: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.otherNonCurrentLiabilities}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>propertyPlantEquipment</StyledFixedTd>
            {balanceSheets?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { propertyPlantEquipment: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.propertyPlantEquipment}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>retainedEarnings</StyledFixedTd>
            {balanceSheets?.quarterlyReports?.slice(0, 5).map((item: { retainedEarnings: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.retainedEarnings}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>shortLongTermDebtTotal</StyledFixedTd>
            {balanceSheets?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { shortLongTermDebtTotal: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.shortLongTermDebtTotal}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>shortTermDebt</StyledFixedTd>
            {balanceSheets?.quarterlyReports?.slice(0, 5).map((item: { shortTermDebt: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.shortTermDebt}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>shortTermInvestments</StyledFixedTd>
            {balanceSheets?.quarterlyReports?.slice(0, 5).map((item: { shortTermInvestments: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.shortTermInvestments}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>totalAssets</StyledFixedTd>
            {balanceSheets?.quarterlyReports?.slice(0, 5).map((item: { totalAssets: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.totalAssets}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>totalCurrentAssets</StyledFixedTd>
            {balanceSheets?.quarterlyReports?.slice(0, 5).map((item: { totalCurrentAssets: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.totalCurrentAssets}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>totalCurrentLiabilities</StyledFixedTd>
            {balanceSheets?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { totalCurrentLiabilities: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.totalCurrentLiabilities}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>totalLiabilities</StyledFixedTd>
            {balanceSheets?.quarterlyReports?.slice(0, 5).map((item: { totalLiabilities: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.totalLiabilities}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>totalNonCurrentAssets</StyledFixedTd>
            {balanceSheets?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { totalNonCurrentAssets: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.totalNonCurrentAssets}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>totalNonCurrentLiabilities</StyledFixedTd>
            {balanceSheets?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { totalNonCurrentLiabilities: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.totalNonCurrentLiabilities}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>totalShareholderEquity</StyledFixedTd>
            {balanceSheets?.quarterlyReports
              ?.slice(0, 5)
              .map((item: { totalShareholderEquity: number; _id: string }) => {
                return <StyledTd key={item._id}>{item.totalShareholderEquity}</StyledTd>;
              })}
          </tr>

          <tr>
            <StyledFixedTd>treasuryStock</StyledFixedTd>
            {balanceSheets?.quarterlyReports?.slice(0, 5).map((item: { treasuryStock: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.treasuryStock}</StyledTd>;
            })}
          </tr>

          <tr>
            <StyledFixedTd>reportedCurrency</StyledFixedTd>
            {balanceSheets?.quarterlyReports?.slice(0, 5).map((item: { reportedCurrency: number; _id: string }) => {
              return <StyledTd key={item._id}>{item.reportedCurrency}</StyledTd>;
            })}
          </tr>
        </StyledTable>
      )}
    </Wrapper>
  );
};

export default BalanceSheet;
